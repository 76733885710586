import classes from "*.module.css";
import {
  Box,
  Button,
  CircularProgress,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  makeStyles,
  Theme,
} from "@material-ui/core";
import { CloseIcon } from "@material-ui/data-grid";
import * as React from "react";

interface IDialogWithCloseButtonProps {
  wide?: boolean;
  open: boolean;
  title: string;
  onClose: () => void;
  onPrimaryAction: () => Promise<void>;
  primaryActionText?: string;
  cancelActionText?: string;
  primaryActionDisabled: boolean;
}

export type DialogWithCloseButtonProps = IDialogWithCloseButtonProps;

const useDialogStyles = makeStyles((theme: Theme) =>
  createStyles({
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  })
);

export const DialogWithCloseButton: React.FC<DialogWithCloseButtonProps> = (
  props
) => {
  const [saving, setSaving] = React.useState(false);
  const classes = useDialogStyles();

  const onPrimaryAction = async () => {
    setSaving(true);
    await props.onPrimaryAction();
    setSaving(false);
  };

  return (
    <Dialog maxWidth={props.wide ? "md" : undefined } fullWidth open={props.open}>
      <DialogTitle>
        {props.title}
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={props.onClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>{props.children}</DialogContent>
      <DialogActions>
        <Box flexGrow={1}>
          <Button onClick={props.onClose} color="primary">
            {props.cancelActionText ?? "Cancel"}
          </Button>
        </Box>
        <Box>
          <Button
            disabled={props.primaryActionDisabled}
            onClick={() => onPrimaryAction()}
            variant="contained"
            color="primary"
          >
            {saving ? <CircularProgress /> : props.primaryActionText ?? "Save"}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};
