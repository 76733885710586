import { Grid } from "@material-ui/core";
import React from "react";
import ImageCard, { MediaItem } from "../../pages/media-upload/image-card";

interface MediaGridProps {
  items: (MediaItem & { isSelected: boolean })[];
  onItemSelected?(item: MediaItem): void;
  onImageClicked?(item: MediaItem): void;
  onTagClicked?(imageId: string, tagId: string): void;
  renderTags?: boolean;
}

export const MediaGrid: React.FC<MediaGridProps> = ({
  items,
  onImageClicked,
  onItemSelected,
  onTagClicked,
  renderTags,
}) => {
  return (
    <Grid container spacing={4}>
      {items.map((i, ii) => (
        <Grid key={ii} item md={3} xs={6}>
          <div
            role="button"
            aria-label="select image"
            onMouseEnter={() => onItemSelected?.(i)}
            onClick={() => onImageClicked?.(i)}
          >
            <ImageCard
              renderTags={renderTags === undefined ? true : renderTags}
              selected={i.isSelected}
              onTagClicked={(id) => onTagClicked?.(id, i.id)}
              image={i}
            />
          </div>
        </Grid>
      ))}
    </Grid>
  );
};
