import React, {ReactElement} from "react";
import ConnectedList, {ConnectedListRef} from "../../atoms/connected-list";
import {TypedDocumentNode} from "@graphql-typed-document-node/core";
import {copyTextToClipboard} from "../../../utils/clipboard";
import {FileCopyOutlined} from "@material-ui/icons";
import {Box} from "@material-ui/core";
import {Link} from "react-router-dom";
import {useSnackbar} from "notistack";
import {VoiceListEntryFragment, VoiceMultiWhereInput} from "../../../graphql/generated";

type VoiceRow = VoiceListEntryFragment;

interface VoiceListQueryParams<Query, Variables> {
  query: TypedDocumentNode<Query, Variables>;
  results(query: Query): { results: VoiceRow[]; totalResults: number; };
  variables(opts: { pagination: { page: number; perPage: number; }; where: VoiceMultiWhereInput; }): Variables;
}

interface SmartVoiceListProps<Query, Variables> extends VoiceListQueryParams<Query, Variables> {
  onSelect?(account: VoiceRow): void;
  onRemove?(account: VoiceRow): void;
  className?: string;
  style?: React.CSSProperties;
}

const SmartVoiceList = <Q,V>(props: SmartVoiceListProps<Q,V>) => {
  const { enqueueSnackbar } = useSnackbar();

  return (
    <ConnectedList
      {...props}
      query={props.query}
      searchKeys={['title']}
      variables={({ pagination, search }) => props.variables({
        pagination,
        where: {
          title: search && search.key === "title" ? { search: search.query } : null,
        } })
      }
      results={query => {
        const wrappedResults = props.results(query)
        return ({
          results: wrappedResults.results.map(v => ({...v, link: `/voices/${v.id}`})),
          totalResults: wrappedResults.totalResults
        });
      }}
      renderCell={{
        created: (value) => <div>{(new Date(value)).toLocaleDateString()}</div>,
        primaryVersion: (value) => <div>{value?.version ?? ""}</div>,
        versionsCount: (value) => <div>{value}</div>,
        talent: (value) => <>{value && <Box>
            <Box display={"flex"}>
              <span style={{cursor: 'copy'}} onClick={e => {
                copyTextToClipboard("" + value.id)
                enqueueSnackbar(`Talent ID "${("" + value.id).substring(0, 9) + "..."}" copied to clipboard`)
                e.preventDefault()
                e.stopPropagation()
              }}><FileCopyOutlined/></span>
              <Box marginLeft={1}><Link to={`/talent/${value.id}`}>{value.firstName} {value.lastName}</Link></Box>
            </Box>
        </Box>}</>
      }}
    />
  );
};

const SmartVoiceListWithRef = React.forwardRef(SmartVoiceList) as <Q,V>(p: SmartVoiceListProps<Q,V> & { ref?: React.Ref<ConnectedListRef> }) => ReactElement;
export default SmartVoiceListWithRef
