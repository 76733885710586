import * as React from "react";
import {
  Box,
  Button,
  Card,
  CircularProgress,
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Divider,
  Tabs,
  Tab,
  AppBar,
  Fab, Input, TablePagination, TableFooter, IconButton,
} from "@material-ui/core";
import DualListBox from "react-dual-listbox";
import { useParams } from "react-router-dom";
import moment from "moment";
import { TabContext, TabPanel } from "@material-ui/lab";
import EditIcon from "@material-ui/icons/Edit";
import {
  ChevronLeft,
  ChevronRight,
  ArrowDownwardSharp,
  ArrowUpwardSharp, Delete, Check,
} from "@material-ui/icons";
import {
  DialogWithCloseButton,
  DialogWithCloseButtonProps,
} from "../../atoms/dialog-with-close-button";
import { Link } from "react-router-dom";
import {AddApiTokenModal} from "../../molecules/add-api-token-modal";
import {AddIcon} from "@material-ui/data-grid";
import {DeleteApiTokenModal} from "../../molecules/delete-api-token-modal";
import {
  useProjectTypeDetailQuery,
  useUpdateProjectTypeMutation,
  useUpdateUserMutation,
  useUserDetailQuery
} from "../../../graphql/generated";
import {DeleteProjectTypeModal} from "../../molecules/delete-project-type-modal";

export const ProjectTypeDetail: React.FC = (props) => {
  const { id } = useParams<{ id: string }>();

  const [title, setTitle] = React.useState("");
  const [description, setDescription] = React.useState("");

  const { data, loading, refetch } = useProjectTypeDetailQuery({
    variables: {
      id
    },
  });

  React.useEffect(() => {
    if (data && !loading) {
      setTitle(data.projectType.title);
      setDescription(data.projectType.description);
    }
  }, [data, loading]);

  const [updateProjectTypeDetailMutation] = useUpdateProjectTypeMutation();

  const [deleting, setDeleting] = React.useState(false);
  const [saving, setSaving] = React.useState(false);
  const [saved, setSaved] = React.useState(false);

  const fieldsEdited = title !== data?.projectType.title || description !== data?.projectType.description;

  const save = async () => {
    setSaving(true);
    setSaved(false);
    await updateProjectTypeDetailMutation({
      variables: {
        input: {
          id: data!.projectType.id,
          title,
          description
        }
      },
    });
    await refetch();
    setSaving(false);
    setSaved(true);
  };

  if (!data || loading) return <CircularProgress />;

  const infoBox = (
    <div>
      <Card style={{marginBottom: 20}}>
        <Table>
          <TableRow>
            <TableCell>
              <strong>Project Type ID</strong>
            </TableCell>
            <TableCell>{data.projectType.id}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <strong>Created</strong>
            </TableCell>
            <TableCell>{moment(data.projectType.created).format()}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <strong>Is Default</strong>
            </TableCell>
            <TableCell>{JSON.stringify(data.projectType.isDefault)}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <strong>Title</strong>
            </TableCell>
            <TableCell>
              <TextField
                variant="outlined"
                value={title}
                size="small"
                onChange={(e) => {
                  setTitle(e.target.value);
                  setSaved(false);
                }}
              ></TextField>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <strong>Description</strong>
            </TableCell>
            <TableCell>
              <TextField
                variant="outlined"
                value={description}
                size="small"
                multiline
                rows={4}
                onChange={(e) => {
                  setDescription(e.target.value);
                  setSaved(false);
                }}
              ></TextField>
            </TableCell>
          </TableRow>
        </Table>
      </Card>
      <div style={{ margin: 10, display: 'flex' }}>
        <>
          <Button color={"primary"} variant={"contained"} onClick={save} disabled={!fieldsEdited} style={{ marginRight: 10 }}>Save</Button>
          {saving && <CircularProgress />}
          {saved && <Check />}
        </>
        <Button color={"secondary"} variant={"contained"} onClick={() => setDeleting(true)} style={{ marginLeft: 10, marginRight: 10 }}>Delete</Button>
      </div>
    </div>
  );

  return (
    <>
      <DeleteProjectTypeModal projectTypeId={deleting ? data.projectType.id : null} onCancel={() => setDeleting(false)} onDelete={async () => window.location.href = "/project-types"} />
      <Typography variant="h4">{data.projectType.title}</Typography>
      <Box style={{ width: 500, marginTop: 20 }}>{infoBox}</Box>
    </>
  );
};
