import { Card, Chip } from "@material-ui/core";
import React from "react";
import cardStyle from "./styles";

export type MediaItem = {
  id: string;
  url: string;
  tags: MediaTag[];
  mimeType: string;
};

export type MediaTag = {
  id: string;
  label: string;
};

interface ImageCardProps {
  image: MediaItem;
  selected?: boolean;
  renderTags: boolean;
  onTagClicked(id: string): void;
}

const ImageCard: React.FC<ImageCardProps> = ({
  image,
  selected,
  onTagClicked,
  renderTags,
}) => (
  <Card
    style={
      selected
        ? { ...cardStyle.container, ...cardStyle.selected }
        : cardStyle.container
    }
  >
    <div style={cardStyle.imageContainer}>
      <img style={cardStyle.image} src={image.url} />
    </div>
    {renderTags && image.tags.length > 0 && (
      <div style={cardStyle.chipWrapper}>
        {image.tags.map((t) => (
          <Chip
            key={t.id}
            onClick={() => onTagClicked(t.id)}
            style={cardStyle.chip}
            label={t.label}
          />
        ))}
      </div>
    )}
  </Card>
);
export default ImageCard;
