import React, {ReactElement} from "react";
import {AccountListEntryFragment, AccountMultiWhereInput} from "../../../graphql/generated";
import ConnectedList, {
  BaseRow,
  ConnectedListProps, ConnectedListRef,
  EntityList,
  EntityListProps,
  EntityListSearch
} from "../../atoms/connected-list";
import {TypedDocumentNode} from "@graphql-typed-document-node/core";
import {DialogWithCloseButton} from "../../atoms/dialog-with-close-button";
import {Box, ListItem, ListItemText, Typography} from "@material-ui/core";
import { List } from "@material-ui/icons";

type AccountRow = AccountListEntryFragment;

interface AccountListQueryParams<Query, Variables> {
  query: TypedDocumentNode<Query, Variables>;
  results(query: Query): { results: AccountRow[]; totalResults: number; };
  variables(opts: { pagination: { page: number; perPage: number; }; where: AccountMultiWhereInput; }): Variables;
}

interface SmartAccountListProps<Query, Variables> extends AccountListQueryParams<Query, Variables> {
  onSelect?(account: AccountRow): void;
  onRemove?(account: AccountRow): void;
  className?: string;
  style?: React.CSSProperties;
}

const SmartAccountList = <Q,V>(props: SmartAccountListProps<Q,V>) => {
  return (
    <ConnectedList
      {...props}
      query={props.query}
      searchKeys={['name']}
      variables={({ pagination, search }) => props.variables({
        pagination,
        where: {
          name: search && search.key === "name" ? { search: search.query } : null
        } })
      }
      results={query => {
        const wrappedResults = props.results(query)
        return ({
          results: wrappedResults.results.map(a => ({...a, link: `/accounts/${a.id}`})),
          totalResults: wrappedResults.totalResults
        });
      }}
      renderCell={{
        created: (value) => <div>{(new Date(value)).toLocaleDateString()}</div>
      }}
    />
  );
};

const SmartAccountListWithRef = React.forwardRef(SmartAccountList) as <Q,V>(p: SmartAccountListProps<Q,V> & { ref?: React.Ref<ConnectedListRef> }) => ReactElement;
export default SmartAccountListWithRef
/*

interface AccountListProps extends EntityListProps<AccountRow> {
  className?: string;
  style?: React.CSSProperties;
}

export const AccountList: React.FC<AccountListProps> = props => {
  return (
    <EntityList
      {...props}
      searchKeys={['name']}
      renderCell={{
        created: (value) => <div>{(new Date(value)).toLocaleDateString()}</div>
      }}
    />
  );
};

interface SelectAccountsModalProps<Query, Variables> extends AccountListQueryParams<Query, Variables> {
  isOpen: boolean;
  onClose(): void;
  onSelected(accounts: string[]): void;
  className?: string;
}

export const SelectAccountsModal = <Q,V>(props: SelectAccountsModalProps<Q,V>) => {
  const [selectedAccounts, setSelectedAccounts] = React.useState<AccountRow[]>([]);

  React.useEffect(() => {
    setSelectedAccounts([]);
  }, [props.isOpen]);

  return (
    <DialogWithCloseButton
      title={"Select Accounts"}
      open={props.isOpen}
      primaryActionText={"Select"}
      onPrimaryAction={async () => {
        /!*const variables = props.mutationVariables()*!/
        /!*await save();
        setKeepRemovedVoicesOnAccounts(false);*!/
        props.onSelected(selectedAccounts.map(a => a.id));
      }}
      primaryActionDisabled={false}
      onClose={() => {
        props.onClose()
      }}
      wide
    >
      <Box display={"flex"}>
        <SmartAccountList
          query={props.query}
          results={props.results}
          variables={props.variables}
          onSelect={account => {
            setSelectedAccounts(o => [...o, account])
          }}
          style={{ minHeight: 300, width: 400 }}
        />
        <Box style={{ width: 300, marginLeft: 20 }}>
          <Typography>Selected Accounts</Typography>
          <Box>
            {
              selectedAccounts.map(account => (
                <div key={account.id}>
                  <span>{account.name} ({account.id})</span>
                </div>
              ))
            }
          </Box>
        </Box>
      </Box>
    </DialogWithCloseButton>
  );
};
*/
