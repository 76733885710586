import React from 'react';
import BaseSelect, { Props as SelectProps } from "react-select";
import CreatableSelect, { Props as CreatableProps } from "react-select/creatable";
import {OptionTypeBase} from "react-select/src/types";

export interface MultiSelectProps<OptionType extends OptionTypeBase = { label: string; value: string }> {
  creatable?: boolean;
  placeholder?: string;
  onChange: SelectProps<OptionType, true>["onChange"];
  options: SelectProps<OptionType, true>["options"];
  value: SelectProps<OptionType, true>["value"];
  onCreateValue?(newValue: string): void;

  styleOverrides?: Partial<{
    control: React.CSSProperties;
    menu: React.CSSProperties;
    multiValue: React.CSSProperties;
    multiValueLabel: React.CSSProperties;
    multiValueRemove: React.CSSProperties;
    valueContainer: React.CSSProperties;
    singleValue: React.CSSProperties;
  }>;
  className?: string;
}

function MultiSelect<OptionType>(props: MultiSelectProps<OptionType>) {
  const SelectComponent: React.ComponentType<CreatableProps<OptionType, true>> = React.useMemo(
    () => (props.creatable ? CreatableSelect : BaseSelect) as any,
    [props.creatable]
  );

  return (
    <SelectComponent
      placeholder={props.placeholder ?? props.creatable ? "Create/Select..." : "Select..."}
      onChange={props.onChange}
      options={props.options}
      value={props.value}
      isMulti
      onCreateOption={
        props.onCreateValue &&
        ((inputValue) => {
          props.onCreateValue?.(inputValue);
        })
      }
      createOptionPosition={'last'}
      styles={{
        control: (base) => ({
          ...base,
          ...props.styleOverrides?.control
        }),
        valueContainer: (base) => ({
          ...base,
          ...props.styleOverrides?.valueContainer
        }),
        menu: (base) => ({
          ...base,
          ...props.styleOverrides?.menu
        }),
        singleValue: (base, state) => ({
          ...base,
          ...props.styleOverrides?.singleValue
        }),
        multiValue: (base) => ({
          ...base,
          ...props.styleOverrides?.multiValue
        }),
        multiValueLabel: (base) => ({
          ...base,
          ...props.styleOverrides?.multiValueLabel
        }),
        multiValueRemove: (base) => ({
          ...base,
          ...props.styleOverrides?.multiValueLabel
        })
      }}
      className={props.className}
    />
  );
}

export default MultiSelect;
