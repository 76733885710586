import { Styles } from "../../../../types/styles";

export const tagEditStyles: Styles = {
  container: {
    padding: "24px",
    overflow: "visible",
  },
  select: {
    margin: "16px 0 16px 0",
  },
  buttonControl: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  confirm: {
    marginRight: "6px",
  },
};
