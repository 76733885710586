import * as React from "react";
import { gql} from "@apollo/client";
import {
  Box,
  Button,
  Card,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Tabs,
  Tab,
  AppBar,
  Fab, TablePagination, TableFooter, IconButton,
} from "@material-ui/core";
import DualListBox from "react-dual-listbox";
import { useParams } from "react-router-dom";
import moment from "moment";
import {Autocomplete, TabContext, TabPanel} from "@material-ui/lab";
import EditIcon from "@material-ui/icons/Edit";
import {
  ChevronLeft,
  ChevronRight,
  ArrowDownwardSharp,
  ArrowUpwardSharp, Delete,
} from "@material-ui/icons";
import {
  DialogWithCloseButton,
  DialogWithCloseButtonProps,
} from "../../atoms/dialog-with-close-button";
import { Link } from "react-router-dom";
import {AddApiTokenModal} from "../../molecules/add-api-token-modal";
import {DeleteApiTokenModal} from "../../molecules/delete-api-token-modal";
import {
  CreateOrAssociateUserModalQuery,
  useAccountDetailQuery, useCreateOrAssociateUserModalQuery,
  useCreateUserInAccountMutation, UsersListQuery,
  useSetCustomPlanForAccountMutation,
  useUpdateAccountMutation
} from "../../../graphql/generated";

type AccountDetailProps = IAccountDetailProps;

interface IAccountDetailProps {}

enum AccountDetailTab {
  Users = "Users",
  Voices = "Voices",
  Talent = "Talent",
  Roles = "Roles",
  DynamicRoles = "DynamicRoles",
  Tokens = "Tokens",
  Renders = "Renders"
}

export const AccountDetail: React.FC<AccountDetailProps> = (props) => {
  const { id } = useParams<{ id: string }>();

  const [userModalOpen, setUserModalOpen] = React.useState(false);
  const [accountName, setAccountName] = React.useState("");
  const [tab, setTab] = React.useState(AccountDetailTab.Users);

  const rendersPerPage = 50;
  const [rendersPage, setRendersPage] = React.useState(0);

  const { data, loading, refetch } = useAccountDetailQuery({
    variables: {
      id,
      rendersPagination: {
        page: rendersPage,
        perPage: rendersPerPage
      }
    },
  });

  const [editUsersOpen, setEditUsersOpen] = React.useState(false);
  const [editVoicesOpen, setEditVoicesOpen] = React.useState(false);
  const [editTalentOpen, setEditTalentOpen] = React.useState(false);
  const [editRolesOpen, setEditRolesOpen] = React.useState(false);
  const [createTokenOpen, setCreateTokenOpen] = React.useState(false);
  const [deletingApiToken, setDeletingApiToken] = React.useState<string | null>(null);

  const [selectedUsers, setSelectedUsers] = React.useState([] as string[]);
  const [selectedVoices, setSelectedVoices] = React.useState([] as string[]);
  const [selectedTalent, setSelectedTalent] = React.useState([] as string[]);
  const [selectedRoles, setSelectedRoles] = React.useState([] as string[]);

  const [settingsAsCustomPlan, setSettingAsCustomPlan] = React.useState(false);

  React.useEffect(() => {
    if (data && data.account) {
      setSelectedUsers(data.account.users.map((u) => u.user.id));
      setSelectedVoices(
        data.account.voiceEntitlements.results.map((v) => v.id)
      );
      setSelectedTalent(
        data.account.allTalentEntitlements.map(t => t.id)
      );
      setSelectedRoles(
        data.account.addedRoles.map(r => r.id)
      );
    }
  }, [data]);

  const [createUserInAccountMutation] = useCreateUserInAccountMutation()
  const [updateAccountDetailMutation] = useUpdateAccountMutation()
  const [setCustomPlanForAccountMutation] = useSetCustomPlanForAccountMutation();

  React.useEffect(() => {
    if (data && !loading) {
      setAccountName(data.account.name);
    }
  }, [data, loading]);

  const [saving, setSaving] = React.useState(false);

  const save = async () => {
    setSaving(true);
    await updateAccountDetailMutation({
      variables: {
        input: {
          users: selectedUsers,
          voices: selectedVoices,
          talent: selectedTalent,
          roles: {
            set: selectedRoles
          },
          title: data?.account.name ?? "",
          id: data?.account.id ?? "",
        }
      },
    });
    await refetch();
    setSaving(false);
    setEditUsersOpen(false);
    setEditVoicesOpen(false);
    setEditRolesOpen(false);
    setSettingAsCustomPlan(false);
    setCreateTokenOpen(false);
  };

  if (!data || loading) return <CircularProgress />;

  const infoBox = (
    <Card style={{ marginBottom: 20 }}>
      <Table>
        <TableRow>
          <TableCell>
            <strong>Account ID</strong>
          </TableCell>
          <TableCell>{data.account.id}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <strong>Created</strong>
          </TableCell>
          <TableCell>{moment(data.account.created).format()}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <strong>Name</strong>
          </TableCell>
          <TableCell>
            <TextField
              variant="outlined"
              value={accountName}
              size="small"
              onChange={(e) => setAccountName(e.target.value)}
            ></TextField>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <strong>Subscription</strong>
          </TableCell>
          <TableCell>
            <div>{data.account.pricingPlanSubscription ? <div>{data.account.pricingPlanSubscription.pricingPlan.name} {data.account.pricingPlanSubscription.isTrial ? "TRIAL" : ""} {!data.account.pricingPlanSubscription.isActive ? "INACTIVE" : ""}</div> : "None"}</div>
            { !data.account.pricingPlanSubscription?.pricingPlan?.isCustom && <div style={{ marginTop: 10 }}><Button size={"small"} variant={"contained"} color={"secondary"} onClick={() => setSettingAsCustomPlan(true)}>Set as Custom Plan</Button></div> }
          </TableCell>
        </TableRow>
      </Table>
    </Card>
  );

  const users = (
    <TableContainer style={{ margin: 0, padding: 0 }} component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <strong>ID</strong>
            </TableCell>
            <TableCell>
              <strong>Created</strong>
            </TableCell>
            <TableCell>
              <strong>Name</strong>
            </TableCell>
            <TableCell>
              <strong>Email</strong>
            </TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {data.account.users.map((user) => (
            <TableRow key={user.user.id}>
              <TableCell width={150}>
                {user.user.id.substring(0, 12)}...
              </TableCell>
              <TableCell width={300}>
                {moment(user.user.created).format()}
              </TableCell>
              <TableCell>
                {user.user.firstName} {user.user.lastName}
              </TableCell>
              <TableCell width={200}>{user.user.emailAddress}</TableCell>
              <TableCell width={100}>
                <Button variant="outlined">Details</Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

  const talent = (
    <TableContainer style={{margin: 0, padding: 0}} component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell><strong>ID</strong></TableCell>
            <TableCell><strong>Talent</strong></TableCell>
          </TableRow>
          {data.account.allTalentEntitlements.map(tE => (
            <TableRow>
            <TableCell>{tE.id}</TableCell>
            <TableCell>{tE.firstName} {tE.lastName}</TableCell>
          </TableRow>
          ))}
          </TableHead>
        </Table>
    </TableContainer>
  )

  const voices = (
    <TableContainer style={{ margin: 0, padding: 0 }} component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <strong>ID</strong>
            </TableCell>
            <TableCell>
              <strong>Voice</strong>
            </TableCell>
            <TableCell>
              <strong>Talent</strong>
            </TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.account.voiceEntitlements.results
            .map((voice) => (
              <TableRow key={voice.id}>
                <TableCell>{voice.id}</TableCell>
                <TableCell>{voice.title}</TableCell>
                <TableCell>
                  {voice.talent?.firstName} {voice.talent?.lastName}
                </TableCell>
                <TableCell></TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

  const roles = (
    <TableContainer style={{ margin: 0, padding: 0 }} component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <strong>ID</strong>
            </TableCell>
            <TableCell>
              <strong>Role</strong>
            </TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.account.addedRoles
            .map((role) => (
              <>
                {role && (
                  <TableRow key={role.id}>
                    <TableCell>{role.id}</TableCell>
                    <TableCell><Link to={`/roles/${role.id}`}>{role.name}</Link></TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                )}
              </>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

  const dynamicRoles = (
    <TableContainer style={{ margin: 0, padding: 0 }} component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <strong>ID</strong>
            </TableCell>
            <TableCell>
              <strong>Role</strong>
            </TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.account.allRoles
            .map((role) => (
              <>
                {role && (
                  <TableRow key={role.id}>
                    <TableCell>{role.id}</TableCell>
                    <TableCell><Link to={`/roles/${role.id}`}>{role.name}</Link></TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                )}
              </>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

  const synthesisTokens = (
    <TableContainer style={{ margin: 0, padding: 0 }} component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <strong>ID</strong>
            </TableCell>
            <TableCell>
              <strong>Created</strong>
            </TableCell>
            <TableCell>
              <strong>Name</strong>
            </TableCell>
            <TableCell>
              <strong>User</strong>
            </TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.account.apiTokens
            .map(token => (
              <TableRow key={token.id}>
                <TableCell>{token.id}</TableCell>
                <TableCell>{new Date(token.created).toLocaleString()}</TableCell>
                <TableCell>{token.name}</TableCell>
                <TableCell><Link to={`/users/${token.user.id}`}>{token.user.firstName} {token.user.lastName} ({token.user.emailAddress})</Link></TableCell>
                <TableCell>
                  <IconButton onClick={() => setDeletingApiToken(token.id)}>
                    <Delete />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

  const renders = (
    <TableContainer style={{ margin: 0, padding: 0 }} component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <strong>ID</strong>
            </TableCell>
            <TableCell>
              <strong>Type</strong>
            </TableCell>
            <TableCell>
              <strong>Date</strong>
            </TableCell>
            <TableCell>
              <strong>Sentence</strong>
            </TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.account.renderingAttempts.results
            .map(render => (
              <React.Fragment key={render.id}>
                {render.__typename === "TakeRenderingAttempt" ?
                <>
                  <TableRow>
                    <TableCell>{render.id}</TableCell>
                    <TableCell>
                      <div>TAKE</div>
                      <div>{render.take.clip ? `${render.take.clip.project?.title ?? "Deleted Project"}.${render.take.clip.title ?? "Unnamed Clip"}[${[...render.take.clip.takes ?? []].sort((a, b) => a.created - b.created).findIndex(t => t.id === render.take.id) + 1}]` : "Deleted Clip[?]"}</div>
                    </TableCell>
                    <TableCell>{new Date(render.created).toLocaleString()}</TableCell>
                    <TableCell>{render.take.generatedSSML}</TableCell>
                    <TableCell>
                      { render.renderedTake && <audio controls src={render.renderedTake.url} /> }
                      { render.error && <span>Error: {render.error}</span> }
                    </TableCell>
                  </TableRow>
                </> :
                <>
                  <TableRow>
                    <TableCell>{render.id}</TableCell>
                    <TableCell>API</TableCell>
                    <TableCell>{new Date(render.created).toLocaleString()}</TableCell>
                    <TableCell>{render.text}</TableCell>
                    <TableCell>
                      { render.url && <audio controls src={render.url} /> }
                      { render.error && <span>Error: {render.error}</span> }
                    </TableCell>
                  </TableRow>
                </>}
              </React.Fragment>
            ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              count={data.account.renderingAttempts.totalResults}
              rowsPerPage={rendersPerPage}
              rowsPerPageOptions={[rendersPerPage]}
              page={rendersPage}
              SelectProps={{
                inputProps: {
                  'aria-label': 'rows per page',
                },
                native: true,
              }}
              onChangePage={(_, newPage) => setRendersPage(newPage)}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );

  const cancelCreateUser = () => {
    setUserModalOpen(false);
  };

  const editUsersDialogProps: DialogWithCloseButtonProps = {
    title: "Edit users",
    open: editUsersOpen,
    onPrimaryAction: () => save(),
    primaryActionDisabled: false,
    onClose: () => setEditUsersOpen(false),
  };

  const editVoicesDialogProps: DialogWithCloseButtonProps = {
    title: "Edit vocals",
    open: editVoicesOpen,
    onPrimaryAction: () => save(),
    primaryActionDisabled: false,
    onClose: () => setEditVoicesOpen(false),
  };

  const editTalentDialogProps: DialogWithCloseButtonProps = {
    title: "Edit talent",
    open: editTalentOpen,
    onPrimaryAction: () => save(),
    primaryActionDisabled: false,
    onClose: () => setEditTalentOpen(false),
  };

  const editRolesDialogProps: DialogWithCloseButtonProps = {
    title: "Edit roles",
    open: editRolesOpen,
    onPrimaryAction: () => save(),
    primaryActionDisabled: false,
    onClose: () => setEditRolesOpen(false),
  };

  const editTalentModal = (
    <DialogWithCloseButton {...editTalentDialogProps}>
      <DualListBox
        canFilter
        style={{height: "100%"}}
        icons={{
          moveLeft: <ChevronLeft/>,
          moveRight: <ChevronRight/>,
          moveDown: <ArrowDownwardSharp/>,
          moveUp: <ArrowUpwardSharp/>,
        }}
        options={data.talents.results.map((talent) => ({
          value: talent.id,
          label: `${talent.firstName} ${talent.lastName}`,
        }))}
        selected={selectedTalent}
        onChange={(values: string[]) => {
          setSelectedTalent(values);
        }}
      />
    </DialogWithCloseButton>
  );

  const editUsersModal = (
    <DialogWithCloseButton {...editUsersDialogProps}>
      <DualListBox
        canFilter
        style={{ height: "100%" }}
        icons={{
          moveLeft: <ChevronLeft />,
          moveRight: <ChevronRight />,
          moveDown: <ArrowDownwardSharp />,
          moveUp: <ArrowUpwardSharp />,
        }}
        options={data.users.results.map((user) => ({
          value: user.id,
          label: `${user.firstName} ${user.lastName}`,
        }))}
        selected={selectedUsers}
        onChange={(values: string[]) => {
          setSelectedUsers(values);
        }}
      />
    </DialogWithCloseButton>
  );

  const editVoicesModal = (
    <DialogWithCloseButton {...editVoicesDialogProps}>
      <DualListBox
        canFilter
        style={{ height: "100%" }}
        icons={{
          moveLeft: <ChevronLeft />,
          moveRight: <ChevronRight />,
          moveDown: <ArrowDownwardSharp />,
          moveUp: <ArrowUpwardSharp />,
        }}
        options={data.voices.results.map((voice) => ({
          value: voice.id,
          label: voice.title
        }))}
        selected={selectedVoices}
        onChange={(values: string[]) => {
          setSelectedVoices(values);
        }}
      />
    </DialogWithCloseButton>
  );

  const editRolesModal = (
    <DialogWithCloseButton {...editRolesDialogProps}>
      <DualListBox
        canFilter
        style={{height: "100%"}}
        icons={{
          moveLeft: <ChevronLeft/>,
          moveRight: <ChevronRight/>,
          moveDown: <ArrowDownwardSharp/>,
          moveUp: <ArrowUpwardSharp/>,
        }}
        options={data.roles.results.map((role) => ({
          value: role.id,
          label: role.name,
        }))}
        selected={selectedRoles}
        onChange={(values: string[]) => {
          setSelectedRoles(values);
        }}
      />
    </DialogWithCloseButton>
  );

  const settingAsCustomPlanModalProps: DialogWithCloseButtonProps = {
    open: settingsAsCustomPlan,
    onClose: () => setSettingAsCustomPlan(false),
    primaryActionDisabled: false,
    primaryActionText: 'Confirm',
    onPrimaryAction: async () => {
      await setCustomPlanForAccountMutation({
        variables: {
          id: id
        }
      });
      await refetch();
      setSettingAsCustomPlan(false)
    },
    title: "Set Using Custom Plan",
  };

  const settingAsCustomPlanModal = (
    <DialogWithCloseButton {...settingAsCustomPlanModalProps}>
      <div>Are you sure you want to set this account as using a custom plan?</div>
      <div>Existing subscriptions will be immediately cancelled.</div>
    </DialogWithCloseButton>
  );

  return (
    <>
      <CreateOrAssociateUserModal
        open={userModalOpen}
        onClose={() => cancelCreateUser()}
        onAssociateUser={async (userId) => {
          await updateAccountDetailMutation({
            variables: {
              input: {
                id,
                title: data!.account.name,
                users: [...data!.account.users.map((u) => u.user.id), userId],
                voices: data!.account.voiceEntitlements.results
                  .map((v) => v.id),
                talent: data!.account.allTalentEntitlements
                  .map(t => t.id),
                roles: {
                  set: data!.account.addedRoles.map(r => r.id)
                }
              }
            },
          });

          await refetch({
            id,
          });
        }}
        onCreateUser={async (newUser) => {
          const result = await createUserInAccountMutation({
            variables: {
              ...newUser,
              accountId: id,
            },
          });

          await refetch({
            id,
          });

          return {
            emailAddress: result.data!.createUserAsAdmin.user.emailAddress,
            password: result.data!.createUserAsAdmin.password,
          };
        }}
      />
      <AddApiTokenModal isShown={createTokenOpen} onCancel={() => {
        setCreateTokenOpen(false);
      }} onAdded={async () => {
        refetch({
          id
        });
        setCreateTokenOpen(false)
      }} />
      <DeleteApiTokenModal tokenId={deletingApiToken} onCancel={() => setDeletingApiToken(null)} onDelete={async () => {
        setDeletingApiToken(null)
        refetch();
      }} />
      <Typography variant="h4">{data.account.name}</Typography>
      <Box style={{ width: 500, marginTop: 20 }}>{infoBox}</Box>

      <TabContext value={tab}>
        <AppBar position="static">
          <Tabs value={tab} onChange={(e, v) => setTab(v as AccountDetailTab)}>
            <Tab value={AccountDetailTab.Users} label="Users" />
            <Tab value={AccountDetailTab.Voices} label="Voices" />
            <Tab value={AccountDetailTab.Talent} label="Talent" />
            <Tab value={AccountDetailTab.Roles} label="Roles" />
            <Tab value={AccountDetailTab.DynamicRoles} label="Dynamic Roles" />
            <Tab value={AccountDetailTab.Tokens} label="Tokens" />
            <Tab value={AccountDetailTab.Renders} label="Renders" />
          </Tabs>
        </AppBar>
        <TabPanel
          style={{ margin: 0, padding: 0, marginTop: 20 }}
          value={AccountDetailTab.Users}
        >
          <Typography>Enterprise users that can access Projects, Vocals and Talent in the account.</Typography>
          <Fab color="primary" onClick={() => setEditUsersOpen(true)}>
            <EditIcon />
          </Fab>
          {users}
        </TabPanel>
        <TabPanel
          style={{ margin: 0, padding: 0, marginTop: 20 }}
          value={AccountDetailTab.Voices}
        >
          <Typography>The list of Vocals that the users of the Enterprise account can select when synthesizing</Typography>
          <Fab color="primary" onClick={() => setEditVoicesOpen(true)}>
            <EditIcon />
          </Fab>
          {voices}
        </TabPanel>
        <TabPanel
          style={{ margin: 0, padding: 0, marginTop: 20 }}
          value={AccountDetailTab.Talent}
        >
          <Typography>The list of Talent that the users of the Enterprise account can review, which is loosely-coupled to the set of Vocals available in the account.</Typography>
           <Fab color="primary" onClick={() => setEditTalentOpen(true)}>
            <EditIcon />
          </Fab>
          {talent}
        </TabPanel>
        <TabPanel
          style={{ margin: 0, padding: 0, marginTop: 20 }}
          value={AccountDetailTab.Roles}
        >
          <Typography>The list of Roles added to this account.</Typography>
          <Fab color="primary" onClick={() => setEditRolesOpen(true)}>
            <EditIcon />
          </Fab>
          {roles}
        </TabPanel>
        <TabPanel
          style={{ margin: 0, padding: 0, marginTop: 20 }}
          value={AccountDetailTab.DynamicRoles}
        >
          <Typography>The list of Roles that are applied to this account (based on pricing plan, etc.).</Typography>
          {dynamicRoles}
        </TabPanel>
        <TabPanel
          style={{ margin: 0, padding: 0, marginTop: 20 }}
          value={AccountDetailTab.Tokens}
        >
          <Typography>The list of Tokens that have been created for this account.</Typography>
          <Fab color="primary" onClick={() => setCreateTokenOpen(true)}>
            <EditIcon />
          </Fab>
          {synthesisTokens}
        </TabPanel>
        <TabPanel
          style={{ margin: 0, padding: 0, marginTop: 20 }}
          value={AccountDetailTab.Renders}
        >
          {renders}
        </TabPanel>
      </TabContext>
      {editUsersModal}
      {editVoicesModal}
      {editTalentModal}
      {editRolesModal}
      {settingAsCustomPlanModal}
    </>
  );
};

const USERS_QUERY = gql`
  query UsersQuery {
    users(input: { pagination: { perPage: 10000, page: 0 } }) {
      results {
        id
        emailAddress
      }
    }
  }
`;

interface ICreateOrAssociateUserModalProps {
  open: boolean;
  onClose: () => void;
  onCreateUser: (newUser: {
    firstName: string;
    lastName: string;
    emailAddress: string;
  }) => Promise<{
    emailAddress: string;
    password: string;
  }>;
  onAssociateUser(userId: string): void;
}

type CreateOrAssociateUserModalProps = ICreateOrAssociateUserModalProps;

const CreateOrAssociateUserModal: React.FC<CreateOrAssociateUserModalProps> = (
  props
) => {
  const { data, loading } = useCreateOrAssociateUserModalQuery();

  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [emailAddress, setEmailAddress] = React.useState("");

  const [
    selectedExistingUser,
    setSelectedExistingUser,
  ] = React.useState<UsersListQuery["users"]["results"][number] | null>(null);
  const [createdUser, setCreatedUser] = React.useState<{
    emailAddress: string;
    password: string;
  } | null>(null);

  const clearState = () => {
    setFirstName("");
    setLastName("");
    setEmailAddress("");
    setSelectedExistingUser(null);
    setCreatedUser(null);
  };

  const close = () => {
    props.onClose();
    clearState();
  };

  if (!data || loading) return <CircularProgress />;

  const dialogProps = {
    title: "Create or associate user",
    open: props.open,
    onClose: close,
    primaryActionDisabled:   !(
      selectedExistingUser !== null ||
      (firstName !== "" && lastName !== "" && emailAddress !== "")
    ),
    primaryActionText: selectedExistingUser ? "Associate" : "Create",
    onPrimaryAction: async () => {
      if (selectedExistingUser) {
        await props.onAssociateUser(selectedExistingUser.id);
        close();
      } else {
        const newUser = await props.onCreateUser({
          firstName,
          lastName,
          emailAddress,
        });

        setCreatedUser(newUser);
      }
    }
  }

  return (
      <DialogWithCloseButton {...dialogProps}>
        {!createdUser ? (
          <>
              <Autocomplete<CreateOrAssociateUserModalQuery["allUsers"][number]>
                options={data.allUsers}
                getOptionLabel={(option) => option.emailAddress}
                style={{ width: 300 }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Existing user"
                    variant="outlined"
                  />
                )}
                value={selectedExistingUser}
                onChange={(_, newValue) => setSelectedExistingUser(newValue)}
              />
              <hr />
              <Box marginTop={2}>
                <TextField
                  fullWidth
                  style={{ marginTop: 10 }}
                  label="First Name"
                  variant="outlined"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                />
                <TextField
                  fullWidth
                  style={{ marginTop: 10 }}
                  label="Last Name"
                  variant="outlined"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
                <TextField
                  fullWidth
                  style={{ marginTop: 10 }}
                  label="Email Address"
                  variant="outlined"
                  value={emailAddress}
                  onChange={(e) => setEmailAddress(e.target.value)}
                />
              </Box>
              </>
        ) : (
          <Table>
                <TableRow>
                  <TableCell>
                    <TextField value={createdUser.emailAddress} disabled />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <TextField value={createdUser.password} disabled />
                  </TableCell>
                </TableRow>
              </Table>
        )}
      </DialogWithCloseButton>
  );
};
