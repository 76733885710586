import { gql, useMutation, useQuery } from "@apollo/client";
import {
  Box,
  Button,
  Card,
  CircularProgress,
  Dialog,
  Input,
  InputAdornment, List, ListItem, ListItemText,
  MenuItem,
  Paper,
  Select,
  Table,
  TableCell,
  TableRow,
  TextField, Tooltip,
  Typography, withStyles,
} from "@material-ui/core";
import { DragIndicatorTwoTone, SpeakerNotes } from "@material-ui/icons";
import * as React from "react";
import { Draggable, Droppable } from "react-beautiful-dnd";
import { CSVLink } from "react-csv";
import { useParams } from "react-router-dom";
import { MediaGrid } from "../../molecules/media-grid";
import { MediaItem } from "../media-upload/image-card";
import {DialogWithCloseButton} from "../../atoms/dialog-with-close-button";
import {
  ContentDetailQuery,
  useContentDetailQuery,
  useDeleteContentLineTalentReportsMutation,
  useMarkContentLineWithErrorMutation, useMediaItemsQuery,
  useUpdateContentMutation
} from "../../../graphql/generated";
import {copyTextToClipboard} from "../../../utils/clipboard";
import {useSnackbar} from "notistack";

type ContentDetailProps = IContentDetailProps;

interface IContentDetailProps {}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
    minWidth: 400
  },
}))(Tooltip);

export const ContentDetail: React.FC<ContentDetailProps> = (props) => {
  const { id } = useParams<{ id: string }>();

  const [title, setTitle] = React.useState("");
  const [author, setAuthor] = React.useState("");
  const [lineEdits, setLineEdits] = React.useState<{ [id: string]: string }>(
    {}
  );
  const [directorsNotesEdits, setDirectorsNotesEdits] = React.useState<{
    [id: string]: string;
  }>({});

  const [saving, setSaving] = React.useState(false);
  const [selectedTags, setSelectedTags] = React.useState([] as string[]);
  const [selectedMediaItem, setSelectedMedia] = React.useState<
    MediaItem | undefined
  >(undefined);
  const [showImagePicker, setShowImagePicker] = React.useState(false);

  const [mutate] = useUpdateContentMutation();

  const [deleteTalentReports] = useDeleteContentLineTalentReportsMutation()

  const [markLineWithError] = useMarkContentLineWithErrorMutation()
  const [addingErrorToLine, setAddingErrorToLine] = React.useState<{
    line: string;
    error: string;
  } | null>(null);

  const {
    data: mediaData
  } = useMediaItemsQuery();

  const { data, loading, refetch: refetchQuery } = useContentDetailQuery(
    {
      variables: {
        id,
      },
    }
  );

  const refetch = async () => {
    await refetchQuery({
      id,
    })
  }

  const save = async () => {
    if (title && author) {
      setSaving(true);
      await mutate({
        variables: {
          id,
          title,
          author,
          tags: selectedTags,
          lines:
            data?.content.lines.map((line, index) => ({
              id: line.id,
              index: index,
              text: lineEdits[line.id] ?? line.text,
              notes: directorsNotesEdits[line.id] ?? line.directorsNotes,
            })) ?? [],
          imageId: selectedMediaItem?.id,
        },
      });
      setSaving(false);
    }
  };

  React.useEffect(() => {
    if (data) {
      const image = data.content.image;
      data.content.title && setTitle(data.content.title);
      data.content.author && setAuthor(data.content.author);
      setSelectedTags(data.content.tags.map((t) => t.id));
      if (image) {
        setSelectedMedia({
          ...image,
          url: image.mediaUrl,
          tags: data.contentTags.results.map((t) => ({ ...t, label: t.tag })),
          mimeType: image.mimeType,
        });
      }
    }
  }, [data]);

  const setLineEdit = (line: string, newText: string) => {
    setLineEdits({
      ...lineEdits,
      [line]: newText,
    });
  };

  const setDirectorsNotesEdit = (line: string, newText: string) => {
    setDirectorsNotesEdits({
      ...directorsNotesEdits,
      [line]: newText,
    });
  };

  const csvData = () => {
    if (!data) return [];
    const content = { ...data.content };
    const lineRows = content.lines.map((l) => [l.text, l.directorsNotes ?? ""]);

    const headers = [
      ...Object.keys(content).filter(
        (h) => h !== "__typename" && h !== "lines" && h !== "tags"
      ),
      "lines",
      "notes",
    ];

    let outputCSV = [
      headers,
      [
        ...[content.id, content.created, content.title, content.author],
        ...(lineRows.length !== 0 ? lineRows[0] : []),
      ],
    ];

    for (let i = 1; i < lineRows.length; i++) {
      outputCSV.push(["", "", "", "", ...lineRows[i]]);
    }
    return outputCSV;
  };

  const onMediaItemChosen = (media: MediaItem) => {
    setShowImagePicker(false);
    setSelectedMedia(media);
  };

  const { enqueueSnackbar } = useSnackbar()

  if (loading || !data || saving) return <CircularProgress />;

  const infoBox = (
    <Card>
      <Table>
        <TableRow>
          <TableCell>
            <strong>Content ID</strong>
          </TableCell>
          <TableCell style={{ cursor: 'copy' }} onClick={e => {
            copyTextToClipboard("" + data.content.id)
            enqueueSnackbar(`Content ID "${("" + data.content.id).substring(0, 9) + "..."}" copied to clipboard`)
            e.preventDefault()
            e.stopPropagation()
          }}>{data.content.id}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <strong>Created</strong>
          </TableCell>
          <TableCell>{data.content.created}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <strong>Title</strong>
          </TableCell>
          <TableCell>
            { !data.content.isRecordingRequestTargetOfTalentVoice ?
              <TextField
                size="small"
                variant="outlined"
                fullWidth
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              /> :
              <TextField
                size={"small"}
                variant={"outlined"}
                fullWidth
                value={`${data.content.title}${data.content.isRecordingRequestTargetOfTalentVoice ? ` - Vocal "${data.content.isRecordingRequestTargetOfTalentVoice.title}"` : ""}`}
                disabled
              />
            }
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <strong>Author</strong>
          </TableCell>
          <TableCell>
            <TextField
              size="small"
              variant="outlined"
              fullWidth
              value={author}
              onChange={(e) => setAuthor(e.target.value)}
            />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <strong>Tags</strong>
          </TableCell>
          <TableCell>
            <Select
              multiple
              value={selectedTags}
              onChange={(e) => setSelectedTags(e.target.value as string[])}
              input={<Input />}
              MenuProps={MenuProps}
            >
              {data.contentTags.results.map((tag) => (
                <MenuItem key={tag.id} value={tag.id}>
                  {tag.tag}
                </MenuItem>
              ))}
            </Select>
          </TableCell>
        </TableRow>

        <TableRow>
          <TableCell>
            <strong>Content Image</strong>
          </TableCell>
          <TableCell>
            {selectedMediaItem ? (
              <>
                <img
                  style={{
                    height: "300px",
                    width: "300px",
                    objectFit: "cover",
                  }}
                  src={selectedMediaItem.url}
                />
                <Button onClick={() => setSelectedMedia(undefined)}>
                  <Typography>REMOVE IMAGE</Typography>
                </Button>
              </>
            ) : (
              <Button onClick={() => setShowImagePicker(true)}>
                <Typography>ADD IMAGE</Typography>
              </Button>
            )}
          </TableCell>
        </TableRow>

        <TableRow>
          <TableCell>
            <strong>Total Lines</strong>
          </TableCell>
          <TableCell>{ data.content.lines.length }</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <strong>Lines with Errors</strong>
          </TableCell>
          <TableCell>{ data.content.lines.filter(l => l.error).length }</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <strong>Reported Lines</strong>
          </TableCell>
          <TableCell>{ data.content.lines.filter(l => l.talentReports.length !== 0).length }</TableCell>
        </TableRow>
      </Table>
    </Card>
  );

  const rows = data.content.lines.map((line, index) => (
    <Draggable index={index} draggableId={`line-${line.id}`}>
      {(provided, snapshot) => (
        <TableRow
          style={{ width: "95%" }}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <TableCell width="5%" align="left">
            <DragIndicatorTwoTone />
          </TableCell>
          <TableCell width="5%" align="left">
            {index + 1}
          </TableCell>
          <TableCell align="left">
            <TextField
              size="small"
              variant="outlined"
              fullWidth
              value={lineEdits[line.id] ?? line.text}
              onChange={(e) =>
                e.target.value != line.text
                  ? setLineEdit(line.id, e.target.value)
                  : null
              }
            />
          </TableCell>
          <TableCell align="left">
            <TextField
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SpeakerNotes />
                  </InputAdornment>
                ),
              }}
              size="small"
              variant="outlined"
              fullWidth
              value={directorsNotesEdits[line.id] ?? line.directorsNotes}
              onChange={(e) =>
                e.target.value != line.text
                  ? setDirectorsNotesEdit(line.id, e.target.value)
                  : null
              }
            />
          </TableCell>
          <TableCell align="right">
            {line.talentReports.length !== 0 && <>
              <LightTooltip title={
                <List component="ul">
                  {line.talentReports.map(report => (
                    <ListItem key={report.id}>
                      <ListItemText primary={report.reportReason}
                                    secondary={`${report.reportedByTalent.firstName} ${report.reportedByTalent.lastName}`}/>
                    </ListItem>
                  ))}
                </List>
              }>
                <Box display={"inline"}
                     mr={2}>{line.talentReports.length} Report{line.talentReports.length !== 1 ? "s" : ""}</Box>
              </LightTooltip>
              <Box display={"inline"} mr={2}>
                <Button variant={"outlined"} onClick={async () => {
                  await deleteTalentReports({
                    variables: {
                      ids: line.talentReports.map(r => r.id)
                    }
                  });
                  refetch();
                }}>Clear</Button>
              </Box>
            </>
            }
            { line.error == null ? <Button color={line.talentReports.length !== 0 ? "secondary" : undefined} variant={"outlined"} onClick={async () => {
              setAddingErrorToLine({
                line: line.id,
                error: line.talentReports?.[0]?.reportReason ?? ""
              });
            }}>Mark as Error</Button> : <Box display={"inline"}>Error</Box> }
          </TableCell>
        </TableRow>
      )}
    </Draggable>
  ));

  return (
    <>
      {infoBox}
      <DialogWithCloseButton
        open={addingErrorToLine !== null}
        title={"Add Error to Line"}
        onPrimaryAction={async () => {
          await markLineWithError({
            variables: {
              id: addingErrorToLine!.line,
              error: addingErrorToLine!.error
            }
          });
          setAddingErrorToLine(null);
          refetch();
        }}
        onClose={() => setAddingErrorToLine(null)}
        primaryActionText={"Confirm"}
        primaryActionDisabled={false}
      >
        { addingErrorToLine && <>
          <p>
            This will mark any unedited recordings pertaining to this line as containing this error.
          </p>
          <Table>
            <TableRow>
              <TableCell>Error Message</TableCell>
              <TableCell>
                <TextField value={addingErrorToLine.error} onChange={({ target: { value } }) => setAddingErrorToLine(o => ({ ...o!, error: value }))} />
              </TableCell>
            </TableRow>
          </Table>
        </>}
      </DialogWithCloseButton>
      <Box style={{ marginTop: 40 }}>
        <Droppable droppableId={`content-${data.content.id}`}>
          {(provided, snapshot) => (
            <Paper style={{ maxHeight: 800, overflow: "auto" }}>
              <div ref={provided.innerRef} {...provided.droppableProps}>
                <Table size="small">{rows}</Table>
                {provided.placeholder}
              </div>
            </Paper>
          )}
        </Droppable>
      </Box>
      <Box style={{ marginTop: 40 }}>
        <Button
          onClick={() => save()}
          size="large"
          variant="contained"
          color="primary"
        >
          {saving ? <CircularProgress /> : <Typography>Save</Typography>}
        </Button>
        <CSVLink data={csvData()} filename={`${id}.csv`}>
          Download Content
        </CSVLink>
      </Box>

      <Dialog open={showImagePicker}>
        <Card style={{ padding: 20 }}>
          <MediaGrid
            renderTags={false}
            items={
              mediaData?.mediaItems.results.map((mr) => ({
                id: mr.id,
                url: mr.mediaUrl,
                tags: mr.contentTags.map((t) => ({ id: t.id, label: t.tag })),
                mimeType: mr.mimeType,
                isSelected: false,
              })) ?? []
            }
            onImageClicked={onMediaItemChosen}
          />
        </Card>
      </Dialog>
    </>
  );
};

export interface ContentLineRowProps {
  line: ContentDetailQuery["content"]["lines"][number];
  index: number;
  className?: string;
}

export const ContentLineRow: React.FC<ContentLineRowProps> = props => {
  return (
    <div className={props.className}>
      {props.children}
    </div>
  );
};
