import * as React from "react";
import {
  Gender,
  MergeVoicesVersionConflictsQuery, useCreateVoiceVersionMutation, useMergeVoicesMutation, useMergeVoicesVersionConflictsQuery,
  useUpdateVoiceMutation,
  useVoiceDetailQuery, useVoicesListQuery, VoiceDetailFragment,
} from "../../../graphql/generated";
import {Link, useHistory, useParams} from "react-router-dom";
import {
  AppBar,
  Box,
  Button,
  Card, CircularProgress, Fab, IconButton, MenuItem, Paper, Tab, Select as MuiSelect,
  Table, TableBody,
  TableCell, TableContainer, TableHead,
  TableRow, Tabs,
  TextField,
} from "@material-ui/core";
import {TabContext, TabPanel} from "@material-ui/lab";
import {AddIcon} from "@material-ui/data-grid";
import {DialogWithCloseButton, DialogWithCloseButtonProps} from "../../atoms/dialog-with-close-button";
import {Delete, FileCopyOutlined, Undo} from "@material-ui/icons";
import {groupBy} from "lodash";
import Select from "react-select";
import MultiSelect from "../../atoms/multi-select";
import {copyTextToClipboard} from "../../../utils/clipboard";
import {useSnackbar} from "notistack";

type VoiceDetailProps = IVoiceDetailProps;

interface IVoiceDetailProps {
}

export const VoiceDetail: React.FC<VoiceDetailProps> = (props) => {
  const history = useHistory();
  const {id} = useParams<{ id: string }>();
  const {data, loading, refetch} = useVoiceDetailQuery({
    variables: {
      voiceId: id,
    },
  });
  const [mutate] = useUpdateVoiceMutation();

  const [detail, setDetail] = React.useState<Omit<VoiceDetailFragment, "id" | "projectTypeWhitelist" | "primaryVersion"> & { projectTypeWhitelist: string[] | null; primaryVersion: string | null }>({} as any); // this is immediately overwritten by following useEffect hook

  const setDetailParam = <T extends keyof typeof detail>(key: T, value: (typeof detail)[T]) => setDetail(o => ({ ...o, [key]: value }))

  React.useEffect(() => {
    console.log("Updating voice metadata")
    if (data) {
      setDetail({
        ...data.voice,
        projectTypeWhitelist: data.voice.projectTypeWhitelist?.map(t => t.id) ?? null,
        primaryVersion: data.voice.primaryVersion?.id ?? null
      })
    }
  }, [data]);


  const [saving, setSaving] = React.useState(false);

  const save = async () => {
    setSaving(true);
    await mutate({
      variables: {
        input: {
          id,
          title: detail.title,
          talentRecordingRequestsEnabled: detail.talentRecordingRequestsEnabled,
          age: detail.age,
          gender: detail.gender,
          ethnicIdentity: detail.ethnicIdentity,
          pronouns: detail.pronouns,
          projectTypeWhitelist: {
            projectTypeIds: detail.projectTypeWhitelist
          },
          characterVoice: {
            value: detail.characterVoice
          },
          regionalAccent: {
            value: detail.regionalAccent
          },
          nonenglishLanguage: {
            value: detail.nonenglishLanguage
          },
          primaryVoiceVersionInput: {
            version: detail.primaryVersion
          }
        }
      },
    });
    await refetch();
    setSaving(false);
  };

  const [addingVoiceVersion, setAddingVoiceVersion] = React.useState(false);
  const [mergingVoices, setMergingVoices] = React.useState(false);

  const { enqueueSnackbar } = useSnackbar();

  if (!data || loading) return <CircularProgress/>;

  const versions = (
    <TableContainer style={{margin: 0, padding: 0}} component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <strong>ID</strong>
            </TableCell>
            <TableCell>
              <strong>Version</strong>
            </TableCell>
            <TableCell>Synthesis Reference</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.voice.versions
            .map((version) => (
              <TableRow key={version.id}>
                <TableCell>{version.id}</TableCell>
                <TableCell>{version.version}</TableCell>
                <TableCell>{version.synthesisReference}</TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

  const primaryVersion = data.voice.versions.find(v => v.id === detail.primaryVersion)

  return (
    <Box>
      <CreateNewVoiceVersionModal shownVoice={addingVoiceVersion ? data.voice.id : null}
                                  onClose={() => setAddingVoiceVersion(false)} onSave={() => {
        refetch({
          voiceId: id,
        });
        setAddingVoiceVersion(false);
      }}/>
      <MergeVoicesModal shownVoice={mergingVoices ? data.voice.id : null} onClose={() => setMergingVoices(false)}
                        onSave={target => {
                          history.push(`/voices/${target}`)
                        }}/>
      <Box marginBottom={4}>
        <Card>
          <Table>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>
                <code>{data.voice.id}</code>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Title</TableCell>
              <TableCell>
                <TextField
                  value={detail.title}
                  onChange={(e) => setDetailParam("title", e.target.value)}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Talent</TableCell>
              <TableCell>
                {data.voice.talent && <Box display={"flex"}>
                    <span style={{cursor: 'copy'}} onClick={e => {
                      copyTextToClipboard("" + data.voice.talent!.id)
                      enqueueSnackbar(`Talent ID "${("" + data.voice.talent!.id).substring(0, 9) + "..."}" copied to clipboard`)
                      e.preventDefault()
                      e.stopPropagation()
                    }}><FileCopyOutlined/></span>
                    <Box marginLeft={1}><Link to={`/talent/${data.voice.talent!.id}`}>{data.voice.talent!.firstName} {data.voice.talent!.lastName} ({data.voice.talent!.id})</Link></Box>
                </Box>}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Primary Voice Version</TableCell>
              <TableCell>
                <Select
                  menuPortalTarget={document.getElementById("menu-portal")!}
                  onChange={(v) => {
                    setDetailParam("primaryVersion", v?.value ?? null);
                  }}
                  options={data.voice.versions.map(version =>
                    ({ value: version.id, label: `${version.version} (${version.synthesisReference})` }))}
                  value={primaryVersion ? { value: primaryVersion.id, label: `${primaryVersion.version} (${primaryVersion.synthesisReference})` } : null}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Age</TableCell>
              <TableCell>
                <TextField
                  type={"number"}
                  variant="outlined"
                  value={detail.age}
                  size="small"
                  onChange={(e) => setDetailParam("age", Number(e.target.value))}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Gender</TableCell>
              <TableCell>
                <Select
                  onChange={(v) => {
                    setDetailParam("gender", v?.value ?? null);
                  }}
                  options={Object.keys(Gender).map(key =>
                    // @ts-ignore
                    ({ value: Gender[key], label: Gender[key] }))}
                  value={detail.gender ? { value: detail.gender, label: detail.gender } : null}
                  styles={{
                    control: base => ({
                      ...base,
                      maxWidth: 400
                    }),
                    menu: base => ({
                      ...base,
                      maxWidth: 400
                    })
                  }}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Ethnic Identity</TableCell>
              <TableCell>
                <TextField
                  variant="outlined"
                  value={detail.ethnicIdentity}
                  size="small"
                  onChange={(e) => setDetailParam("ethnicIdentity", e.target.value)}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Pronouns</TableCell>
              <TableCell>
                <TextField
                  variant="outlined"
                  value={detail.pronouns}
                  size="small"
                  onChange={(e) => setDetailParam("pronouns", e.target.value)}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Project Type Whitelist</TableCell>
              <TableCell>
                <MultiSelect
                  onChange={(v) => {
                    setDetailParam("projectTypeWhitelist", v.length !== 0 ? v.map(o => o.value) : null)
                  }}
                  options={data.projectTypes.map(t => ({
                    value: t.id,
                    label: t.title
                  }))}
                  value={(detail.projectTypeWhitelist ?? data.voice.projectTypeWhitelist.map(t => t.id)).map(id =>
                    ({ value: id, label: data.projectTypes.find(t => t.id === id)?.title ?? `Project Type(${id})` }))}
                  styleOverrides={{
                    control:{
                      maxWidth: 400
                    },
                    menu: {
                      maxWidth: 400
                    }
                  }}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Talent Recording Requests Enabled</TableCell>
              <TableCell>
                <input type="checkbox"
                       checked={detail.talentRecordingRequestsEnabled ?? false}
                       onChange={(e) => setDetailParam("talentRecordingRequestsEnabled", e.target.checked)}/>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Character Voice</TableCell>
              <TableCell>
                <TextField
                  value={detail.characterVoice}
                  onChange={(e) => setDetailParam("characterVoice", e.target.value)}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Regional Accent</TableCell>
              <TableCell>
                <TextField
                  value={detail.regionalAccent}
                  onChange={(e) => setDetailParam("regionalAccent", e.target.value)}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Non-English Language</TableCell>
              <TableCell>
                <TextField
                  value={detail.nonenglishLanguage}
                  onChange={(e) => setDetailParam("nonenglishLanguage", e.target.value)}
                />
              </TableCell>
            </TableRow>
          </Table>
        </Card>
        <Button
          disabled={saving}
          onClick={() => save()}
          variant="contained"
          color="primary"
        >
          {saving ? <CircularProgress/> : <span>Save</span>}
        </Button>
        <Button
          onClick={() => setMergingVoices(true)}
          variant="contained"
          color="secondary"
        >
          Merge Voices
        </Button>
      </Box>
      <TabContext value={"versions"}>
        <AppBar position="static">
          <Tabs value={"versions"}>
            <Tab value={"versions"} label="Versions"/>
          </Tabs>
        </AppBar>
        <TabPanel
          style={{margin: 0, padding: 0, marginTop: 20}}
          value={"versions"}
        >
          <Fab color="primary" onClick={() => setAddingVoiceVersion(true)}>
            <AddIcon/>
          </Fab>
          {versions}
        </TabPanel>
      </TabContext>
    </Box>
  );
};

interface ICreateNewVoiceVersionModalProps {
  shownVoice: string | null;
  onClose: () => void;
  onSave: (version: string) => void;
}

type CreateNewVoiceVersionModalProps = ICreateNewVoiceVersionModalProps;

const CreateNewVoiceVersionModal: React.FC<CreateNewVoiceVersionModalProps> = (props) => {
  const [version, setVersion] = React.useState("");
  const [synthesisReference, setSynthesisReference] = React.useState("");
  const [saving, setSaving] = React.useState(false);
  const [createVoiceVersionMutation] = useCreateVoiceVersionMutation();
  // const [createRoleMutation] = useCreateRoleMutation();

  const close = () => {
    props.onClose();
    setVersion("");
    setSynthesisReference("");
  };

  const createVersion = async () => {
    setSaving(true);
    const newVersion = await createVoiceVersionMutation({
      variables: {
        input: {
          voice: props.shownVoice!,
          version,
          synthesisReference
        }
      }
    })

    if (newVersion.data?.createVoiceVersion.id) {
      props.onSave(newVersion.data.createVoiceVersion.id);
    }

    setSaving(false);
    close();
  };

  const disabled = !version || !synthesisReference || saving;

  const dialogProps: DialogWithCloseButtonProps = {
    open: Boolean(props.shownVoice),
    primaryActionDisabled: disabled,
    onClose: close,
    onPrimaryAction: createVersion,
    title: "Create new voice version",
  };

  return (
    <DialogWithCloseButton {...dialogProps}>
      <TextField
        value={version}
        onChange={(e) => setVersion(e.target.value)}
        label={"Version"}
        required
        style={{marginRight: 10}}
      />
      <TextField
        value={synthesisReference}
        onChange={(e) => setSynthesisReference(e.target.value)}
        label={"Synthesis Reference"}
        required
      />
    </DialogWithCloseButton>
  );
};

type VersionConflict = {
  title: string;
  source: MergeVoicesVersionConflictsQuery["source"]["versions"][number];
  target: MergeVoicesVersionConflictsQuery["target"]["versions"][number];
}


interface IMergeVoicesModalProps {
  shownVoice: string | null;
  onClose: () => void;
  onSave: (targetVoice: string) => void;
}

type MergeVoicesModalProps = IMergeVoicesModalProps;

const MergeVoicesModal: React.FC<MergeVoicesModalProps> = (props) => {
  const [target, setTarget] = React.useState<string | null>(null);
  const [saving, setSaving] = React.useState(false);
  const [mergeVoicesMutation] = useMergeVoicesMutation();

  const voicesListQuery = useVoicesListQuery({fetchPolicy: 'network-only'})

  const voiceVersionConflictsQuery = useMergeVoicesVersionConflictsQuery({
    variables: {
      source: props.shownVoice!,
      target: target!
    }, skip: !props.shownVoice || !target, fetchPolicy: 'network-only'
  });

  const versionsGroupedByTitleDict = voiceVersionConflictsQuery.data && groupBy([...voiceVersionConflictsQuery.data.source.versions.map(v => ({
    ...v,
    from: "source"
  })), ...voiceVersionConflictsQuery.data.target.versions.map(v => ({...v, from: "target"}))], v => v.version)
  const versionsGroupedByTitle = versionsGroupedByTitleDict && Object.keys(versionsGroupedByTitleDict).map(title => ({
    title,
    source: versionsGroupedByTitleDict[title].find(v => v.from === "source"),
    target: versionsGroupedByTitleDict[title].find(v => v.from === "target")
  }));

  const conflicts = versionsGroupedByTitle && (versionsGroupedByTitle.filter((c) => c.source && c.target) as VersionConflict[]);

  const [conflictResolution, setConflictResolution] = React.useState<{ [id: string]: string | undefined }>({});
  const resolveConflict = (id: string, version: string) => setConflictResolution(o => ({
    ...o,
    [id]: version === "" ? undefined : version
  }));

  const [deletedVersions, setDeletedVersions] = React.useState<string[]>([]);

  const stillHasConflicts = (conflicts ?? []).filter(conflict => !(conflictResolution[conflict.source.id] || conflictResolution[conflict.target.id!] || deletedVersions.includes(conflict.source.id) || deletedVersions.includes(conflict.target.id!))).length > 0;

  const close = () => {
    props.onClose();
    setTarget(null);
    setConflictResolution({});
    setDeletedVersions([]);
  };

  const merge = async () => {
    setSaving(true);
    await mergeVoicesMutation({
      variables: {
        input: {
          voices: [props.shownVoice!],
          target: target!,
          renameVersions: conflicts ? Object.keys(conflictResolution).filter(id => conflictResolution[id]).map(id => ({
            version: id,
            renameTo: conflictResolution[id]!
          })) : null,
          deleteVersions: deletedVersions
        }
      }
    })

    props.onSave(target!);
    setSaving(false);
    close();
  };

  const disabled = !target || stillHasConflicts || saving;

  const dialogProps: DialogWithCloseButtonProps = {
    open: Boolean(props.shownVoice),
    primaryActionDisabled: disabled,
    onClose: close,
    onPrimaryAction: merge,
    title: "Merge Voices",
  };

  return (
    <DialogWithCloseButton {...dialogProps}>
      {!voicesListQuery.data || voicesListQuery.loading ? <CircularProgress/> : <>
        <MuiSelect label={"Target"} placeholder={"Target"} value={target ?? ""}
                   onChange={({target: {value}}) => setTarget(value as string)} style={{width: '100%'}}>
          {voicesListQuery.data.voices.results.filter(v => v.id !== props.shownVoice).map(voice => (
            <MenuItem key={voice.id} value={voice.id}>{voice.title}</MenuItem>
          ))}
        </MuiSelect>
        {target && (conflicts ?? []).length > 0 && <div style={{marginTop: 20}}>
            <h4>Voice Version Conflicts</h4>
            <table style={{width: '100%', borderCollapse: 'collapse'}}>
                <thead>
                <tr>
                    <td>Source: {voiceVersionConflictsQuery.data!.source.title}</td>
                    <td>Target: {voiceVersionConflictsQuery.data!.target.title}</td>
                </tr>
                </thead>
                <tbody>
                {versionsGroupedByTitle?.map(versionGroupedByTitle => (
                  <tr key={versionGroupedByTitle.title}
                      style={{backgroundColor: (versionGroupedByTitle.source && versionGroupedByTitle.target) ? (!((conflictResolution[versionGroupedByTitle.source.id] || deletedVersions.includes(versionGroupedByTitle.source.id)) || (conflictResolution[versionGroupedByTitle.target.id] || deletedVersions.includes(versionGroupedByTitle.target.id))) ? "rgba(255, 0, 0, 0.2)" : "rgba(0, 255, 0, 0.2)") : "transparent"}}>
                    <td style={{paddingRight: 5, width: '50%'}}>
                      {versionGroupedByTitle.source &&
                          <span style={{display: 'flex', justifyContent: 'space-between', width: '100%'}}>
                   <TextField
                       value={conflictResolution[versionGroupedByTitle.source.id] ?? ""}
                       disabled={deletedVersions.includes(versionGroupedByTitle.source.id)}
                       onChange={(e) => resolveConflict(versionGroupedByTitle.source!.id, e.target.value)}
                       label={versionGroupedByTitle.source.version}
                       helperText={versionGroupedByTitle.source.synthesisReference ?? "No Synthesis Ref."}
                       style={{flexGrow: 1}}
                   />
                   <div style={{
                     display: 'flex',
                     justifyContent: 'center',
                     alignItems: 'center',
                     padding: 10
                   }}>{!versionGroupedByTitle.source.synthesisReference && (!deletedVersions.includes(versionGroupedByTitle.source.id) ?
                     <IconButton
                       onClick={() => setDeletedVersions(o => [...o, versionGroupedByTitle.source!.id])}><Delete/></IconButton> :
                     <IconButton
                       onClick={() => setDeletedVersions(o => o.filter(id => id !== versionGroupedByTitle.source!.id))}><Undo/></IconButton>)}</div>
                 </span>}
                    </td>
                    <td style={{paddingLeft: 5, width: '50%'}}>
                      {versionGroupedByTitle.target &&
                          <span style={{display: 'flex', justifyContent: 'space-between', width: '100%'}}>
                   <TextField
                       value={conflictResolution[versionGroupedByTitle.target.id] ?? ""}
                       disabled={deletedVersions.includes(versionGroupedByTitle.target.id)}
                       onChange={(e) => resolveConflict(versionGroupedByTitle.target!.id, e.target.value)}
                       label={versionGroupedByTitle.target.version}
                       helperText={versionGroupedByTitle.target.synthesisReference ?? "No Synthesis Ref."}
                       style={{flexGrow: 1}}
                   />
                   <div style={{
                     display: 'flex',
                     justifyContent: 'center',
                     alignItems: 'center',
                     padding: 10
                   }}>{!versionGroupedByTitle.target.synthesisReference && (!deletedVersions.includes(versionGroupedByTitle.target.id) ?
                     <IconButton
                       onClick={() => setDeletedVersions(o => [...o, versionGroupedByTitle.target!.id])}><Delete/></IconButton> :
                     <IconButton
                       onClick={() => setDeletedVersions(o => o.filter(id => id !== versionGroupedByTitle.target!.id))}><Undo/></IconButton>)}</div>
                 </span>}
                    </td>
                  </tr>))}
                </tbody>
            </table>
          {/*<ul>
            {conflicts?.map(conflict => <li key={conflict.version}>{conflict.version} - {conflict.id}, {conflict.duplicateInTarget!}</li>)}
          </ul>*/}
        </div>}
      </>}
    </DialogWithCloseButton>
  );
};
