import { gql, useMutation, useQuery } from "@apollo/client";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  TextField,
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { ColDef, DataGrid } from "@material-ui/data-grid";
import * as React from "react";
import { useHistory } from "react-router-dom";
import { MediaGrid } from "../../molecules/media-grid";
import { MediaItem } from "../media-upload/image-card";
import {useContentListQuery, useCreateContentMutation, useMediaItemsQuery} from "../../../graphql/generated";
import {copyTextToClipboard} from "../../../utils/clipboard";
import {useSnackbar} from "notistack";

type ContentListProps = IContentListProps;

interface IContentListProps {}

export const ContentList: React.FC<ContentListProps> = (props) => {
  return (
    <Box style={{ height: 900 }}>
      <_ContentListTable />
    </Box>
  );
};

const _ContentListTable: React.FC = () => {
  const { data, loading, refetch } = useContentListQuery();
  const history = useHistory();

  const [createOpen, setCreateOpen] = React.useState(false);

  const [mutate, { data: createData, loading: createLoading }] = useCreateContentMutation();

  const { enqueueSnackbar } = useSnackbar();

  if (loading || !data) return <CircularProgress />;

  const createContent = async (
    title: string,
    author: string | null,
    csv: string,
    mediaItem: MediaItem | undefined
  ) => {
    await mutate({
      variables: {
        title,
        author,
        csv,
        mediaItemId: mediaItem?.id,
      },
    });
    refetch();
    setCreateOpen(false);
  };

  const rows = data.contents.results.map((content) => ({
    id: content.id,
    created: content.created,
    title: `${content.title}${content.isRecordingRequestTargetOfTalentVoice ? ` - Vocal "${content.isRecordingRequestTargetOfTalentVoice.title}"` : ""}`,
    author: content.author,
    countOfLines: content.countOfLines,
    reportedLineCount: content.reportedLineCount,
    errorLineCount: content.errorLineCount,
    image: content.image,
  }));

  const columns: ColDef[] = [
    {
      field: "id",
      headerName: "ID",
      renderCell: params => (
          <span style={{ cursor: 'copy' }} onClick={e => {
            copyTextToClipboard("" + params.value)
            enqueueSnackbar(`Content ID "${("" + params.value).substring(0, 9) + "..."}" copied to clipboard`)
            e.preventDefault()
            e.stopPropagation()
          }}>{params.value}</span>
      )
    },
    {
      field: "created",
      headerName: "Created",
    },
    {
      field: "title",
      headerName: "Title",
      flex: 5,
    },
    {
      field: "author",
      headerName: "Author",
      flex: 4,
    },
    {
      field: "countOfLines",
      headerName: "# Lines",
    },
    {
      field: "errorLineCount",
      headerName: "# Error Lines",
      renderCell: params => (
        <span style={{
          fontWeight: params.value !== 0 ? 'bold' : undefined
        }}>{params.value !== 0 ? params.value : ""}</span>
      )
    },
    {
      field: "reportedLineCount",
      headerName: "# Reported Lines",
      renderCell: params => (
        <span style={{
          fontWeight: params.value !== 0 ? 'bold' : undefined
        }}>{params.value !== 0 ? params.value : ""}</span>
      )
    },
    {
      field: "actions",
      headerName: "Actions",
      renderCell: (params) => (
        <Button
          variant="outlined"
          onClick={() => history.push(`/content/${params.row.id}`)}
        >
          View
        </Button>
      ),
    },
    {
      field: "image",
      headerName: "Image",
      renderCell: (params) => {
        console.log(params);
        return params.row.image?.mediaUrl && (
          <img
            style={{ width: 50, height: 50, objectFit: "cover" }}
            src={params.row.image.mediaUrl}
          />
        );
      },
    },
  ];

  const createContentModalProps: ICreateContentModalProps = {
    onClose: () => setCreateOpen(false),
    onCreate: (
      title: string,
      author: string | null,
      csv: string,
      mediaItem: MediaItem | undefined
    ) => {
      createContent(title, author, csv, mediaItem);
    },
    open: createOpen,
  };

  return (
    <>
      <Grid
        container
        alignItems="center"
        justify={"space-between"}
        wrap="nowrap"
        direction="row"
        style={{ paddingTop: 12, paddingBottom: 12 }}
      >
        <Grid item justify="flex-end" xs={12}>
          <Button
            onClick={() => setCreateOpen(true)}
            color="primary"
            aria-label="add"
          >
            Add content
          </Button>
        </Grid>
      </Grid>

      <Box style={{ height: 900 }}>
        <DataGrid rows={rows} columns={columns} />
      </Box>

      <CreateContentModal {...createContentModalProps} />
    </>
  );
};

interface ICreateContentModalProps {
  open: boolean;
  onClose: () => void;
  onCreate: (
    title: string,
    author: string | null,
    csv: string,
    mediaItem: MediaItem | undefined
  ) => any;
}

const CreateContentModal: React.FC<ICreateContentModalProps> = (props) => {
  const [title, setTitle] = React.useState("");
  const [author, setAuthor] = React.useState("");
  const [csv, setCsv] = React.useState("");
  const [mediaItem, setMediaItem] = React.useState<MediaItem | undefined>();

  const clear = () => {
    setCsv("");
    setTitle("");
    setAuthor("");
    setMediaItem(undefined);
  };

  const cancel = () => {
    clear();
    props.onClose();
  };

  const { data: mediaData } = useMediaItemsQuery();

  const create = () => {
    if (!!title && !!csv) {
      props.onCreate(title, author, csv, mediaItem);
    }
  };

  const createDisabled = !(!!title && !!csv);

  return (
    <Dialog
      onClose={props.onClose}
      aria-labelledby="customized-dialog-title"
      open={props.open}
    >
      <DialogTitle id="customized-dialog-title">Create Content</DialogTitle>
      <DialogContent style={{ width: 600 }} dividers>
        <Box>
          <TextField
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            fullWidth
            label="Title"
          ></TextField>
        </Box>
        <Box>
          <TextField
            value={author}
            onChange={(e) => setAuthor(e.target.value)}
            fullWidth
            label="Author"
          ></TextField>
        </Box>
        <Box style={{ marginTop: 20 }}>
          <TextField
            fullWidth
            id="outlined-multiline-static"
            label="Content Lines"
            multiline
            rows={4}
            defaultValue=""
            variant="outlined"
            value={csv}
            onChange={(e) => setCsv(e.target.value)}
          />
        </Box>
        <Box style={{ marginTop: 20 }}>
          <MediaGrid
            renderTags={false}
            items={
              mediaData?.mediaItems.results.map((mr) => ({
                id: mr.id,
                url: mr.mediaUrl,
                tags: mr.contentTags.map((t) => ({ id: t.id, label: t.tag })),
                mimeType: mr.mimeType,
                isSelected: mediaItem?.id === mr.id,
              })) ?? []
            }
            onImageClicked={setMediaItem}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={() => cancel()} color="primary">
          Cancel
        </Button>
        <Button
          disabled={createDisabled}
          autoFocus
          onClick={() => create()}
          variant="contained"
          color="primary"
        >
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
};
