import * as React from "react";
import { Box, CircularProgress } from "@material-ui/core";
import { ColDef, DataGrid } from "@material-ui/data-grid";
import { PlayCircleOutline } from "@material-ui/icons";
import ReactAudioPlayer from "react-audio-player";
import {useRecordingsListQuery} from "../../../graphql/generated";

type RecordingsListProps = IRecordingsListProps;

interface IRecordingsListProps {

}

export const RecordingsList: React.FC<RecordingsListProps> = (props) => {
  const {data, loading} = useRecordingsListQuery()

  if (!data || loading) return <CircularProgress/>

  const rows = data.recordingSessionContentLineItems.results.map(recording => ({
    id: recording.id,
    created: recording.created,
    talent: `${recording.recordingSession.assignment.voice?.talent?.firstName} ${recording.recordingSession.assignment.voice?.talent?.lastName}`,
    text: recording.recordedContentLineText,
    audio: recording.downloadUrl
  }))

  const columns: ColDef[] = [
    {
      field: 'id',
      headerName: 'ID'
    },
    {
      field: 'created',
      headerName: 'Created'
    },
    {
      field: 'talent',
      headerName: 'Talent'
    },
    {
      field: 'text',
      headerName: 'Text',
      flex: 1
    },
    {
      field: 'audio',
      headerName: 'Audio',
      flex: 1,
      renderCell: (params) => {
        console.log(params)

        if (!params.value) {
          return <Box/>
        }

        return (
          <ReactAudioPlayer src={params.value as string} controls/>
        )
      }
    }
  ]

  return (
    <Box>
      <DataGrid columns={columns} rows={rows} autoHeight/>
    </Box>
  )
}
