import * as React from "react";
import { gql, useMutation, useQuery } from "@apollo/client";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Input,
  MenuItem,
  OutlinedTextFieldProps,
  Table,
  TableCell,
  TableRow,
  TextField,
  Typography,
  Select,
  Box,
  FormLabel,
} from "@material-ui/core";
import DualListBox from "react-dual-listbox";
import {
  ArrowDownwardSharp,
  ArrowUpwardSharp,
  ChevronLeft,
  ChevronRight,
  Label,
} from "@material-ui/icons";
import {
  DialogWithCloseButton,
  DialogWithCloseButtonProps,
} from "../../atoms/dialog-with-close-button";
import {useCreateUserModalQuery, useCreateUserMutation} from "../../../graphql/generated";

type UserCreateModalProps = IUserCreateModalProps;

interface IUserCreateModalProps {
  open: boolean;
  onCancel: () => any;
  onCreate: () => Promise<any>;
}

enum UserCreateModalMode {
  BasicInfo = "BasicInfo",
  TalentAccount = "TalentAccount",
  Credentials = "Credentials",
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export const UserCreateModal: React.FC<UserCreateModalProps> = (props) => {
  const [mode, setMode] = React.useState(UserCreateModalMode.BasicInfo);
  const [creating, setCreating] = React.useState(false);

  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [emailAddress, setEmailAddress] = React.useState("");
  const [createTalent, setCreateTalent] = React.useState(false);
  const [createEnterprise, setCreateEnterprise] = React.useState(false);
  const [associateWithEnterpriseAccounts, setAssociateWithEnterpriseAccounts] =
    React.useState([] as string[]);
  const [createdPassword, setCreatedPassword] = React.useState("");
  const [reviewingAccount, setReviewingAccount] = React.useState("")

  const {
    data: createUserModalData,
    loading,
    refetch,
  } = useCreateUserModalQuery();

  const clearForm = () => {
    setFirstName("");
    setLastName("");
    setEmailAddress("");
    setCreateTalent(false);
    setAssociateWithEnterpriseAccounts([]);
  };

  const [createUserMutation] = useCreateUserMutation();

  const create = async () => {
    setCreating(true);
    const user = await createUserMutation({
      variables: {
        firstName,
        lastName,
        emailAddress,
        isTalent: createTalent,
        accounts: [],
        accountsWithTalentAccess: [reviewingAccount].filter(r => !!r)
      },
    });

    if (user.data && !user.errors?.length) {
      setCreatedPassword(user.data.createUserAsAdmin.password);
      setMode(UserCreateModalMode.Credentials);
    }
    setCreating(false);
    await props.onCreate();
  };

  const close = () => {
    clearForm();
    props.onCancel();
  };

  const field = (
    value: string,
    setValue: (v: string) => any,
    title: string
  ): OutlinedTextFieldProps => ({
    value,
    onChange: (e: any) => setValue(e.target.value),
    label: title,
    variant: "outlined",
    fullWidth: true,
  });

  if (!createUserModalData || loading) return <CircularProgress />;

  const dialogProps: DialogWithCloseButtonProps = {
    title: "Create user",
    onPrimaryAction: {
      [UserCreateModalMode.BasicInfo]: async () =>
        setMode(UserCreateModalMode.TalentAccount),
      [UserCreateModalMode.TalentAccount]: create,
      [UserCreateModalMode.Credentials]: () => props.onCancel(),
    }[mode],
    primaryActionText: {
      [UserCreateModalMode.BasicInfo]: "Next",
      [UserCreateModalMode.TalentAccount]: "Create",
      [UserCreateModalMode.Credentials]: "Close",
    }[mode],
    onClose: close,
    primaryActionDisabled: {
      [UserCreateModalMode.BasicInfo]: !firstName || !lastName || !emailAddress,
      [UserCreateModalMode.TalentAccount]: false,
      [UserCreateModalMode.Credentials]: false,
    }[mode],
    open: props.open,
  };

  return (
    <DialogWithCloseButton wide {...dialogProps}>
      {mode === UserCreateModalMode.BasicInfo && (
        <>
          <Table size="small">
            <TableRow>
              <TableCell>First name</TableCell>
              <TableCell>
                <TextField {...field(firstName, setFirstName, "First Name")} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Last name</TableCell>
              <TableCell>
                <TextField {...field(lastName, setLastName, "Last name")} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Email address</TableCell>
              <TableCell>
                <TextField
                  {...field(emailAddress, setEmailAddress, "Email address")}
                />
              </TableCell>
            </TableRow>
          </Table>
        </>
      )}


      {mode === UserCreateModalMode.TalentAccount && (
        <>
          <Checkbox
            value={createTalent}
            onChange={(e) => {
              setCreateTalent(e.target.checked)
              if (!e.target.checked) {
                setReviewingAccount("")
              }
            }}
          />
          <FormLabel>Create talent account for user</FormLabel>
          <Select label="Account with review access" value={reviewingAccount} onChange={(e) => setReviewingAccount(e.target.value as string)} disabled={!createTalent}>
            { createUserModalData.accounts.results.map((account) => (
              <MenuItem value={account.id}>{ account.name }</MenuItem>
            ))}
          </Select>
        </>
      )}

      {mode === UserCreateModalMode.Credentials && (
        <Table>
          <TableRow>
            <TableCell>
              <TextField value={emailAddress} disabled />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <TextField value={createdPassword} disabled />
            </TableCell>
          </TableRow>
        </Table>
      )}
    </DialogWithCloseButton>
  );
};
