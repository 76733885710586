import * as React from "react";
import { gql, useMutation, useQuery } from "@apollo/client";
import {
  Box,
  Button,
  CircularProgress,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
  IconButton,
  makeStyles,
  Table,
  TableCell,
  TableRow,
  TextField,
  Theme,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { ColDef, DataGrid } from "@material-ui/data-grid";
import EditIcon from "@material-ui/icons/Edit";
import CloseIcon from "@material-ui/icons/Close";
import { useStyles } from "@material-ui/pickers/views/Calendar/Day";
import {
  DialogWithCloseButton,
  DialogWithCloseButtonProps,
} from "../../atoms/dialog-with-close-button";
import {useCreatePricingPlanMutation, usePricingPlanListQuery} from "../../../graphql/generated";

type PricingPlanListProps = IPricingPlanListProps;

interface IPricingPlanListProps {}

export const PricingPlanList: React.FC<PricingPlanListProps> = (props) => {
  const { data, loading } = usePricingPlanListQuery();
  const [createPricingPlanModalOpen, setCreatePricingPlanModalOpen] = React.useState(
    false
  );
  const history = useHistory();

  if (!data || loading) return <CircularProgress />;

  const rows = data.pricingPlans.results;

  const columns: ColDef[] = [
    {
      field: "id",
      headerName: "ID",
      width: 100,
    },
    {
      field: "name",
      headerName: "Name",
      width: 200,
    },
    {
      field: "hidden",
      headerName: "Hidden",
      width: 100,
    },
    {
      field: "actions",
      headerName: "Actions",
      renderCell: (params) => (
        <Button
          variant="outlined"
          onClick={() => history.push(`/pricing-plans/${params.row.id}`)}
        >
          View
        </Button>
      ),
    },
  ];

  const onNewPricingPlanCreated = (id: string) => {
    setCreatePricingPlanModalOpen(false);
    history.push(`/pricing-plans/${id}`);
  };

  return (
    <Box>
      <Fab color="primary" onClick={() => setCreatePricingPlanModalOpen(true)}>
        <EditIcon />
      </Fab>
      <CreateNewPricingPlanModal
        shown={createPricingPlanModalOpen}
        onSave={(id: string) => onNewPricingPlanCreated(id)}
        onClose={() => setCreatePricingPlanModalOpen(false)}
      />
      <Box style={{ height: 900 }}>
        <DataGrid rows={rows} columns={columns} />
      </Box>
    </Box>
  );
};

const usePricingPlanCreateStyles = makeStyles((theme: Theme) =>
  createStyles({
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  })
);

interface ICreateNewPricingPlanModalProps {
  shown: boolean;
  onClose: () => void;
  onSave: (account: string) => void;
}

type CreateNewPricingPlanModalProps = ICreateNewPricingPlanModalProps;

const CreateNewPricingPlanModal: React.FC<CreateNewPricingPlanModalProps> = (props) => {
  const [newPricingPlanName, setNewPricingPlanName] = React.useState("");
  const [saving, setSaving] = React.useState(false);
  const classes = usePricingPlanCreateStyles();
  const [createPricingPlanMutation] = useCreatePricingPlanMutation();

  const createPricingPlan = async () => {
    setSaving(true);
    const newPricingPlan = await createPricingPlanMutation({
      variables: {
        name: newPricingPlanName,
        hidden: true,
        stripePriceId: null
      },
    });

    if (newPricingPlan.data?.createPricingPlan.pricingPlan.id) {
      props.onSave(newPricingPlan.data.createPricingPlan.pricingPlan.id);
    }

    setSaving(false);
  };

  const close = () => {
    setNewPricingPlanName("");
    props.onClose();
  };

  const createPricingPlanDisabled = !newPricingPlanName || saving;

  const dialogProps: DialogWithCloseButtonProps = {
    open: props.shown,
    primaryActionDisabled: createPricingPlanDisabled,
    onClose: close,
    onPrimaryAction: createPricingPlan,
    title: "Create new pricing plan",
  };

  return (
    <DialogWithCloseButton {...dialogProps}>
      <TextField
        value={newPricingPlanName}
        onChange={(e) => setNewPricingPlanName(e.target.value)}
        label={"New plan name"}
        required
      />
    </DialogWithCloseButton>
  );
};
