import {
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import {
  CreditCardTwoTone,
  HeadsetMicOutlined,
  Image,
  LocalOffer,
  MenuBook,
  PersonOutlineTwoTone,
  RecentActors,
  Lock, Payment, AccountTree, FeaturedPlayList
} from "@material-ui/icons";
import * as React from "react";
import { useHistory } from "react-router-dom";

type NavigationProps = INavigationProps;

interface INavigationProps {}

export const Navigation: React.FC<NavigationProps> = (props) => {
  const history = useHistory();

  const items = [
    {
      label: "Content",
      link: "/content",
      icon: <MenuBook />,
    },
    {
      label: "Talent",
      link: "/talent",
      icon: <RecentActors />,
    },
    {
      label: "Recordings",
      link: "/recordings",
      icon: <HeadsetMicOutlined />,
    },
    {
      label: "Accounts",
      link: "/accounts",
      icon: <AccountTree />,
    },
    {
      label: "Users",
      link: "/users",
      icon: <PersonOutlineTwoTone />,
    },
    {
      label: "Tags",
      link: "/tags",
      icon: <LocalOffer />,
    },
    { label: "Media", link: "/media", icon: <Image /> },
    { label: "Voices", link: "/voices", icon: <HeadsetMicOutlined /> },
    { label: "Roles", link: "/roles", icon: <Lock /> },
    { label: "Project Types", link: "/project-types", icon: <FeaturedPlayList /> },
    { label: "Pricing Plans", link: "/pricing-plans", icon: <Payment /> }
  ];

  return (
    <>
      <List>
        {items.map((item, index) => (
          <ListItem
            onClick={() => history.push(item.link)}
            button
            key={item.label}
          >
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText primary={item.label} />
          </ListItem>
        ))}
      </List>
      <Divider />
    </>
  );
};
