import * as React from "react";
import { gql, useMutation, useQuery } from "@apollo/client";
import {
  CircularProgress, OutlinedTextFieldProps, Table, TableCell, TableRow, TextField
} from "@material-ui/core";
import {
  DialogWithCloseButton,
} from "../../atoms/dialog-with-close-button";
import {useAddTalentToUserModalQuery, useAddTalentToUserMutation} from "../../../graphql/generated";

interface AddTalentToUserModalProps {
  addingToUserId: string | false;
  onCancel: () => any;
  onAdded: () => Promise<any>;
}

export const AddTalentToUserModal: React.FC<AddTalentToUserModalProps> = (props) => {
  const [adding, setAdding] = React.useState(false);

  const {
    data: addTalentToUserData,
    loading
  } = useAddTalentToUserModalQuery({ variables: { user: props.addingToUserId as string }, skip: !props.addingToUserId });

  const [firstName, setFirstName] = React.useState(addTalentToUserData?.user.firstName ?? "");
  const [lastName, setLastName] = React.useState(addTalentToUserData?.user.lastName ?? "");

  React.useEffect(() => {
    if (addTalentToUserData?.user) {
      setFirstName(addTalentToUserData.user.firstName)
      setLastName(addTalentToUserData.user.lastName)
    }
  }, [addTalentToUserData?.user]);

  const [addTalentToUserMutation] = useAddTalentToUserMutation()

  const add = async () => {
    setAdding(true);
    await addTalentToUserMutation({
      variables: {
        firstName: firstName,
        lastName: lastName,
        user: props.addingToUserId as string
      }
    });
    setAdding(false);
    await props.onAdded();
    close();
  };

  const close = () => {
    props.onCancel();
  };

  const field = (
    value: string,
    setValue: (v: string) => any,
    title: string
  ): OutlinedTextFieldProps => ({
    value,
    onChange: (e: any) => setValue(e.target.value),
    label: title,
    variant: "outlined",
    fullWidth: true,
  });

  return (
    <DialogWithCloseButton
      open={props.addingToUserId !== false}
      title={"Add Talent to User"}
      onPrimaryAction={add}
      onClose={close}
      primaryActionText={"Confirm"}
      primaryActionDisabled={false}
    >
      {!addTalentToUserData || loading ? <CircularProgress /> : <>
        <div>Add to {addTalentToUserData.user.firstName} {addTalentToUserData.user.lastName} ({addTalentToUserData.user.emailAddress})?</div>
        <Table>
          <TableRow>
            <TableCell>First name</TableCell>
            <TableCell>
              <TextField {...field(firstName, setFirstName, "First Name")} />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Last name</TableCell>
            <TableCell>
              <TextField {...field(lastName, setLastName, "Last Name")} />
            </TableCell>
          </TableRow>
        </Table>
      </>}
    </DialogWithCloseButton>
  );
}
