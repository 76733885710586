import * as React from "react";
import {CircularProgress, OutlinedTextFieldProps, Table, TableCell, TableRow, TextField} from "@material-ui/core";
import {DialogWithCloseButton,} from "../../atoms/dialog-with-close-button";
import {useCreateVoiceModalQuery, useCreateVoiceMutation} from "../../../graphql/generated";
import Select from "react-select";

interface CreateVoiceModalProps {
  isOpen: boolean;
  onCancel: () => void;
  onCreated: (id: string) => void;
}

export const CreateVoiceModal: React.FC<CreateVoiceModalProps> = (props) => {
  const [adding, setAdding] = React.useState(false);

  const [title, setTitle] = React.useState("");
  const [talent, setTalent] = React.useState<string | null>(null);

  const query = useCreateVoiceModalQuery();
  const [createVoiceMutation] = useCreateVoiceMutation();

  const add = async () => {
    setAdding(true);
    const res = await createVoiceMutation({
      variables: {
        input: {
          title,
          talent
        }
      }
    });
    setAdding(false);
    await props.onCreated(res.data!!.createVoice.id);
    close();
  };

  const close = () => {
    setTitle("")
    setTalent(null)
    props.onCancel();
  };

  const selectedTalent = query.data?.allTalents.find(t => t.id === talent)

  return (
    <DialogWithCloseButton
      open={props.isOpen}
      title={"Create Voice"}
      onPrimaryAction={add}
      onClose={close}
      primaryActionText={"Confirm"}
      primaryActionDisabled={false}
    >
      {!query.data || query.loading ? <CircularProgress /> : <>
        <Table>
          <TableRow>
            <TableCell>Title</TableCell>
            <TableCell>
              <TextField
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  label={"Title"}
                  variant={"outlined"}
                  fullWidth={true}
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Talent</TableCell>
            <TableCell>
              <Select
                menuPosition={"fixed"}
                value={selectedTalent ? { value: selectedTalent.id, label: `${selectedTalent.firstName} ${selectedTalent.lastName} (${selectedTalent.id})` } : null}
                options={query.data.allTalents.map(talent => (
                  { value: talent.id, label: `${talent.firstName} ${talent.lastName} (${talent.id})` }
                ))}
                onChange={(value) => setTalent(value?.value ?? null)}
              />
            </TableCell>
          </TableRow>
        </Table>
      </>}
    </DialogWithCloseButton>
  );
}
