import * as React from "react";
import {Box, Button} from "@material-ui/core";
import {VoicesListPageQueryDocument} from "../../../graphql/generated";
import SmartVoiceList from "../../molecules/voice-list";
import {CreateVoiceModal} from "../../molecules/create-voice-modal";
import {useHistory} from "react-router-dom";

type VoicesListProps = IVoicesListProps;

interface IVoicesListProps {

}

export const VoicesList: React.FC<VoicesListProps> = (props) => {
  const [createVoiceOpen, setCreateVoiceOpen] = React.useState(false);
  const history = useHistory();

  return (
    <Box>
      <CreateVoiceModal isOpen={createVoiceOpen} onCancel={() => setCreateVoiceOpen(false)} onCreated={id => history.push(`/voices/${id}`)} />
      <Button variant="contained" color="primary" onClick={() => setCreateVoiceOpen(true)}>
        Create voice
      </Button>
      <Box style={{ height: 900 }}>
        <SmartVoiceList
          query={VoicesListPageQueryDocument}
          variables={({ pagination, where }) => ({
            input: {
              pagination,
              where
            }
          })}
          results={({ voices }) => voices}
          style={{ height: '100%' }}
        />
      </Box>
    </Box>
  )
}
