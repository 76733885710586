import * as React from "react";
import {
  Box,
  Button,
  Card,
  CircularProgress,
  Table,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { useHistory, Link } from "react-router-dom";
import { CSSProperties } from "@material-ui/core/styles/withStyles";
import { UserCreateModal } from "../../molecules/user-create-modal";
import {AddTalentToUserModal} from "../../molecules/add-talent-to-user-modal";
import {useProjectTypesListQuery, useUsersListQuery} from "../../../graphql/generated";
import {ProjectTypeCreateModal} from "../../molecules/project-type-create-modal";


interface ProjectTypesListProps {}

const bolded = {
  style: { fontWeight: "bold" } as CSSProperties,
};

export const ProjectTypesList: React.FC<ProjectTypesListProps> = (props) => {
  const { data, loading, refetch } = useProjectTypesListQuery();
  const [createProjectTypeOpen, setCreateProjectTypeOpen] = React.useState(false);

  const history = useHistory();

  if (!data || loading) return <CircularProgress />;

  const createProjectTypeModal = (
    <ProjectTypeCreateModal
      {...{
        open: createProjectTypeOpen,
        onCancel: () => setCreateProjectTypeOpen(false),
        onCreate: async () => {
          setCreateProjectTypeOpen(false)
          await refetch();
        }
      }}
    />
  );

  const table = (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell {...bolded}>Project Type ID</TableCell>
          <TableCell {...bolded}>Title</TableCell>
          <TableCell {...bolded}></TableCell>
        </TableRow>
      </TableHead>
      {data.projectTypes.map((projectType) => (
        <TableRow key={projectType.id}>
          <TableCell><Link to={`/project-types/${projectType.id}`}><code>{projectType.id.substring(0, 8)}</code>...</Link></TableCell>
          <TableCell>{projectType.title}{projectType.isDefault && " (Default)"}</TableCell>
          <TableCell>
            {/* <Button
              variant="outlined"
              onClick={() => history.push(`/user/${user.id}`)}
            >
              Detail
            </Button> */}
          </TableCell>
        </TableRow>
      ))}
    </Table>
  );

  return (
    <Box>
      {createProjectTypeModal}
      <Button variant="contained" color="primary" onClick={() => setCreateProjectTypeOpen(true)}>
        Create project type
      </Button>
      <Card>{table}</Card>
    </Box>
  );
};
