import React, {ReactElement} from "react";
import {CellParams, ColDef, DataGrid, RowModel} from "@material-ui/data-grid";
import {Box, Button, makeStyles, MenuItem, Select, TextField} from "@material-ui/core";
import {useQuery} from "@apollo/client";
import { TypedDocumentNode } from '@graphql-typed-document-node/core';
import {copyTextToClipboard} from "../../../utils/clipboard";
import {useSnackbar} from "notistack";
import {Close, FileCopyOutlined} from "@material-ui/icons";
import {Link} from "react-router-dom";

export type BaseRow = {
  id: string;
  [key: string]: any; // Strongly type somehow?
}

interface EntityListCommonProps<Row extends BaseRow> {
  perPage?: number;
  searchKeys?: string[];
  renderCell?: MappedRenderCellFn<Row>;
  rowActions?(row: Row): React.ReactNode | null;
  onSelect?(row: Row): void;
  onRemove?(row: Row): void;
}

export interface EntityListProps<Row extends BaseRow> extends EntityListCommonProps<Row>{
  results: { results: Row[]; totalResults: number; } | undefined;

  onNavigateToPage(page: number): void;
  onSearch(search: EntityListSearch): void;

  className?: string;
  style?: React.CSSProperties;
}

export const EntityList = <R extends BaseRow,>(props: EntityListProps<R>) => {
  const [search, setSearch] = React.useState<{ query: string | null; key: string; } | null>(props.searchKeys && props.searchKeys.length !== 0 ? { query: null, key: props.searchKeys[0] } : null);

  const perPage = props.perPage ?? DEFAULT_PAGE_SIZE;
  const [page, setPage] = React.useState(0);
  const onNavigateToPage = (newPage: number) => {
    setPage(newPage);
    props.onNavigateToPage(newPage);
  }

  const onSearch = (s: { query: string | null; key: string; } | null) => {
    setSearch(s);
    setPage(0);
    props.onSearch(!s || s.query === null ? null : {
      query: s.query!,
      key: s.key
    });
  }

  const { enqueueSnackbar } = useSnackbar();

  const results = props.results;

  const columns: ColDef[] = [
    {
      field: "id",
      headerName: "ID",
      width: 150,
      sortable: false,
      renderCell: params => {
        const link: string | undefined = params.row["link"]

        return (
          <Box display={"flex"}>
            <span style={{cursor: 'copy'}} onClick={e => {
              copyTextToClipboard("" + params.value)
              enqueueSnackbar(`ID "${("" + params.value).substring(0, 9) + "..."}" copied to clipboard`)
              e.preventDefault()
              e.stopPropagation()
            }}><FileCopyOutlined/></span>
            <Box marginLeft={1}>{ !link ? <span>{params.value}</span> : <Link to={link}>{params.value}</Link>}</Box>
          </Box>
        );
      }
    },
    ...(results && results.totalResults !== 0 && results.results.length !== 0 ? Object.keys(results.results[0]).filter(key => key !== "__typename" && key !== "id" && key !== "link").map(key => ({
      field: key,
      headerName: toTitleCase(key),
      width: 200,
      renderCell: props.renderCell && props.renderCell[key] ? ({ value, row }: CellParams) => props.renderCell![key]!(value as any, row as any) : undefined,
      sortable: false
    })) : []),
    ...(props.rowActions ? [{
      field: "___actions",
      headerName: "Actions",
      width: 300,
      renderCell: (params: CellParams) => (
        <div>{props.rowActions!(params.row as R)}</div>
      ),
    }] : []),
    ...(props.onSelect ? [{
      field: "___selectAction",
      headerName: " ",
      width: 100,
      renderCell: (params: CellParams) => (
        <div><Button variant={"outlined"} onClick={() => props.onSelect!(params.row as R)} /></div>
      ),
    }] : []),
    ...(props.onRemove ? [{
      field: "___removeAction",
      headerName: " ",
      width: 50,
      renderCell: (params: CellParams) => (
        <div><Close onClick={() => props.onRemove!(params.row as R)} /></div>
      ),
    }] : [])
  ];

  const classes = useStyles();

  return (
    <Box className={`${classes.container} ${props.className ?? ""}`} style={props.style}>
      {props.searchKeys && <Box display={'flex'} alignItems={'center'}>
          <TextField variant="outlined" placeholder={"Search"} value={search?.query ?? ""} onChange={({ target: { value } }) => onSearch({ ...search!, query: value !== "" ? value : null })} />
          <Box marginLeft={2}>
              <Select value={search?.key} onChange={({ target: { value } }) => onSearch({ query: null, key: value as string })}>
                {props.searchKeys.map(key =>
                  <MenuItem key={key} value={key}>{ toTitleCase(key) }</MenuItem>
                )}
              </Select>
          </Box>
      </Box>}
      <DataGrid
        columns={columns}
        rows={results?.results ?? []}
        page={page + 1}
        onPageChange={({ page: newPage }) => onNavigateToPage(newPage - 1)}
        pageSize={perPage}
        rowCount={results?.totalResults}
        paginationMode={'server'}

        disableColumnReorder={true}
        disableColumnMenu={true}
        disableColumnFilter={true}
        disableColumnSelector={true}
        sortingMode={'server'}
        className={classes.grid}
        // onSortModelChange={({ sortModel }) => {
        //
        // }}
      />
    </Box>
  );
};

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1
  },
  grid: {
    flex: 1,
    minHeight: 300
  }
})

export interface ConnectedListRef {
  refetch(): Promise<void>;
}

export type EntityListSearch = { query: string; key: string; } | null;

export interface ConnectedListProps<Query, Variables, Row extends BaseRow> extends EntityListCommonProps<Row> {
  query: TypedDocumentNode<Query, Variables>;
  variables(opts: { pagination: { page: number; perPage: number; }; search: { query: string; key: string; } | null; }): Variables;
  results(query: Query): { results: Row[]; totalResults: number; };

  className?: string;
  style?: React.CSSProperties;
}

type MappedRenderCellFn<Type> = Partial<{
  [Key in keyof Type as Exclude<Key, "__typename">]: (value: Type[Key], row: Type) => ReactElement;
}>

const DEFAULT_PAGE_SIZE = 20;

const ConnectedList = <Q,V,R extends BaseRow>(props: ConnectedListProps<Q, V, R>, ref: React.Ref<ConnectedListRef>) => {
  const perPage = props.perPage ?? DEFAULT_PAGE_SIZE;
  const [page, setPage] = React.useState(0);
  const [search, setSearch] = React.useState<EntityListSearch>(null);

  const variables = props.variables({ pagination: { page, perPage }, search });
  const query = useQuery<Q, V>(props.query, { variables })

  React.useImperativeHandle(ref, () => ({
    async refetch(): Promise<void> {
      await query.refetch(variables)
    }
  }))

  const results = React.useMemo(() => query.data !== undefined ? props.results(query.data) : undefined, [props.results, query])

  return (
    <EntityList
      {...props}
      results={results}
      onNavigateToPage={newPage => setPage(newPage)}
      onSearch={s => setSearch(s)}
    />
  )
};

const ConnectedListWithRef = React.forwardRef(ConnectedList) as <Q,V,R extends BaseRow>(p: ConnectedListProps<Q,V,R> & { ref?: React.Ref<ConnectedListRef> }) => ReactElement;
export default ConnectedListWithRef

function toTitleCase(string: string) {
  return string
    // insert a space before all caps
    .replace(/([A-Z])/g, ' $1')
    // uppercase the first character
    .replace(/^./, function(str){ return str.toUpperCase(); })
}
