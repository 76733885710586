import React from "react";
import { Auth0Provider, useAuth0 } from "@auth0/auth0-react";
import { GetTokenSilentlyOptions } from "@auth0/auth0-spa-js/src/global";
import { Redirect, useHistory } from "react-router-dom";
import { AppState } from "@auth0/auth0-react/dist/auth0-provider";
import { RedirectLoginOptions } from "@auth0/auth0-react/dist/auth0-context";
import {CircularProgress} from "@material-ui/core";

export const Auth0Config = {
  domain: "vocalid.auth0.com",
  clientId: "w1OE60at8YV4kRSJzyVanxnVayVke5MY",
  audience: "https://parrot-api.vocalid.ai",
}

export const AuthProvider: React.FC = ({ children }) => {
  const history = useHistory();
  const defaultRedirectUri = `${window.location.origin}/cb`

  return (
    <Auth0Provider
      domain={Auth0Config.domain}
      clientId={Auth0Config.clientId}
      audience={Auth0Config.audience}
      redirectUri={defaultRedirectUri}
      onRedirectCallback={appState => {
        history.push(appState.returnTo ?? defaultRedirectUri);
      }}
    >
      <AuthContextProvider>
        {children}
      </AuthContextProvider>
    </Auth0Provider>
  );
}



interface IAuthContext {
  user: any | null;
  accessToken: string | null;
  isAuthenticated: boolean;
  isLoading: boolean;
  loginWithRedirect(redirectUri?: string, opts?: Partial<RedirectLoginOptions>): Promise<void>;
  getAccessTokenSilently(options?: Omit<GetTokenSilentlyOptions, "redirect_uri">): Promise<string>;
  logout(): void;
}

const AuthContext = React.createContext<IAuthContext>({
  user: null,
  accessToken: null,
  isAuthenticated: false,
  isLoading: false,
  loginWithRedirect: async () => {},
  getAccessTokenSilently: async () => "",
  logout: async () => {}
})

const AuthContextProvider: React.FC = ({ children }) => {
  const { isAuthenticated, isLoading, loginWithRedirect, getAccessTokenSilently, logout, user } = useAuth0();
  const [accessToken, setAccessToken] = React.useState<string | null>(null);

  return <AuthContext.Provider value={{
    user,
    accessToken,
    isAuthenticated,
    isLoading,
    loginWithRedirect: async (redirectUri?: string, opts: Partial<RedirectLoginOptions> = {}) => {
      const appState: AppState = {
        returnTo: redirectUri
      }

      await loginWithRedirect({
        appState,
        ...opts
      });
    },
    getAccessTokenSilently: async (options?: Omit<GetTokenSilentlyOptions, "redirect_uri">): Promise<string> => {
      const token = await getAccessTokenSilently(options);
      setAccessToken(token);
      return token;
    },
    logout
  }}>
    { children }
  </AuthContext.Provider>
}

export const useAuth = () => React.useContext(AuthContext);

export const AuthCallback: React.FC = () => {
  const [shouldRedirect, setShouldRedirect] = React.useState(false);
  React.useEffect(() => {
    const timeout = setTimeout(() => {
      setShouldRedirect(true);
    }, 3000);

    return () => {
      clearTimeout(timeout);
    }
  }, []);

  return !shouldRedirect ? (
    <div className={"flex h-75 justify-center items-center"}>
      <span className={"vocalid-primary-text"}>
        <CircularProgress color={"inherit"}/>
      </span>
    </div>
  ) : <Redirect to={'/'} />
}
