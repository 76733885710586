
      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "MixContent": [
      "AdMixContent",
      "PlaylistMixContent"
    ],
    "PlaylistItem": [
      "PlaylistPauseItem",
      "PlaylistTakeItem"
    ],
    "RenderingAttempt": [
      "TakeRenderingAttempt",
      "TextRenderingAttempt"
    ],
    "Take": [
      "GeneratedTake",
      "TalentRecordedTake"
    ],
    "Viewer": [
      "MachineClientViewer",
      "WebClientViewer"
    ]
  }
};
      export default result;
    