import * as React from "react";
import {gql, useApolloClient, useMutation, useQuery} from "@apollo/client";
import { useParams } from "react-router-dom";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Tab,
  Card,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableCell,
  TableRow,
  Tabs,
  TextField,
  Typography,
  AppBar,
  Fab, Checkbox
} from "@material-ui/core";
import { AddIcon } from "@material-ui/data-grid";
import { ArrowDownwardSharp, ArrowUpwardSharp, ChevronLeft, ChevronRight, ExpandMoreOutlined } from "@material-ui/icons";
import 'react-dual-listbox/lib/react-dual-listbox.css';
import DualListBox from 'react-dual-listbox';
import {
  KeyboardDatePicker,
} from '@material-ui/pickers';
import {
  Gender,
  TalentJobCategory,
  TalentDetailContentQuery,
  TalentDetailContentQueryDocument, TalentDetailContentQueryVariables, TalentDetailFragment, TalentDetailQuery,
  useCreateAssignmentMutation,
  useTalentDetailQuery, useUpdateAssignmentMutation,
  useUpdateTalentMutation, useCreateAssignmentModalQuery
} from "../../../graphql/generated";
import MultiSelect from "../../atoms/multi-select";
import Select from "react-select";
import {copyTextToClipboard} from "../../../utils/clipboard";
import {useSnackbar} from "notistack";
type TalentDetailProps = ITalentDetailProps;

interface ITalentDetailProps {

}

export const TalentDetail: React.FC<TalentDetailProps> = (props) => {
  const { id } = useParams<{ id: string }>()
  const [saving, setSaving] = React.useState(false)

  const { data, loading, refetch } = useTalentDetailQuery({
    variables: {
      talentId: id
    },
    fetchPolicy: "no-cache"
  })

  const [detail, setDetail] = React.useState<Omit<TalentDetailFragment, "id">>({} as any); // this is immediately overwritten by following useEffect hook

  const setDetailParam = <T extends keyof typeof detail>(key: T, value: (typeof detail)[T]) => setDetail(o => ({ ...o, [key]: value }))

  React.useEffect(() => {
    console.log("Updating talent metadata")
    if (data) {
      setDetail({
        ...data.talent
      })
    }
  }, [data]);

  const [updateTalentMutation] = useUpdateTalentMutation();

  const save = async () => {
    setSaving(true);
    await updateTalentMutation({
      variables: {
        input: {
          id: data!.talent.id,
          firstName: detail.firstName,
          lastName: detail.lastName,
          age: detail.age,
          gender: detail.gender,
          ethnicIdentity: detail.ethnicIdentity,
          pronouns: detail.pronouns,
          allowableUseCases: detail.allowableUseCases,
          limitations: detail.limitations,
          memberOfUnion: detail.memberOfUnion,
          desiredProjectCategories: {
            list: detail.desiredProjectCategories
          },
          hiredProjectCategories: {
            list: detail.hiredProjectCategories
          },
          brandConflicts: detail.brandConflicts,
          otherJobInfo: detail.otherJobInfo,
          interestedInCharacterVoiceRecording: {
            list: detail.interestedInCharacterVoiceRecording
          },
          showcaseVoicesOnVoice123: detail.showcaseVoicesOnVoice123,
          interestedInRegionalAccentRecording: {
            list: detail.interestedInRegionalAccentRecording
          },
          interestedInNonenglishLanguageRecording: {
            list: detail.interestedInNonenglishLanguageRecording
          },
        }
      },
    });
    await refetch();
    setSaving(false);
  };

  const [selectedContentForAssignments, setSelectedContentForAssignments] = React.useState<{[key: string]: string[]}>({})

  // assignment recording counts
  const apolloClient = useApolloClient()
  const [assignmentContentRecordingCounts, setAssignmentContentRecordingCounts] = React.useState<{ [assignmentId: string]: { [contentId: string]: number } }>({});
  const [assignmentContentRecordingCountsLoading, setAssignmentContentRecordingCountsLoading] = React.useState(false);

  React.useEffect(() => {
    console.log("Querying assignment content")
    Object.keys(selectedContentForAssignments).forEach(assignmentId => {
      if (selectedContentForAssignments[assignmentId]!.some(contentId => assignmentContentRecordingCounts[assignmentId]?.[contentId] === undefined)) {
        setAssignmentContentRecordingCountsLoading(true);
        apolloClient.query<TalentDetailContentQuery, TalentDetailContentQueryVariables>({
          query: TalentDetailContentQueryDocument,
          variables: {
            contentIds: selectedContentForAssignments[assignmentId]!,
            assignmentId: assignmentId
          }
        }).then(contentData => {
          setAssignmentContentRecordingCountsLoading(false);
          setAssignmentContentRecordingCounts(o => ({
            ...o,
            [assignmentId]: {
              ...o[assignmentId],
              ...(contentData.data.contents.results.reduce((acc, content) => ({
                ...acc,
                [content.id]: content.totalRecordingsCount
              }), {}))
            }
          }))
        });
      }
    })
  }, [selectedContentForAssignments])

  const [updateAssignmentMutation] = useUpdateAssignmentMutation()

  const updateAssignment = async (id: string, content: string[]) => {
    setSaving(true)
    await updateAssignmentMutation({
      variables: {
        assignmentId: id,
        content: content ?? [],
        visibleToTalent: newAssignmentVisibleToTalent[id]
      }
    })
    await refetch()
    setSaving(false)
  }

  const [selectedTab, setSelectedTab] = React.useState(TalentDetailTab.Assignments)
  const [createAssignmentOpen, setCreateAssignmentOpen] = React.useState(false)
  const [newAssignmentVisibleToTalent, setNewAssignmentVisibleToTalent] = React.useState<{[key: string]: boolean | undefined}>({})

  React.useEffect(() => {
    if (data?.talent) {
      console.log("Updating selected content & visibility")

      let selectedContent = {}
      let visibility = {}

      data?.talent.assignments.forEach(assignment => {
        selectedContent = {
          ...selectedContent,
          [assignment.id]: assignment.content.map(c => c.id)
        }

        visibility = {
          ...visibility,
          [assignment.id]: assignment.visibleToTalent ?? true
        }
      })

      setSelectedContentForAssignments(selectedContent)
      setNewAssignmentVisibleToTalent(visibility)
    }
  }, [data?.talent.assignments])

  const { enqueueSnackbar } = useSnackbar();

  if (!data || loading) return <CircularProgress/>

  const infoBox = (
    <Card>
      <Table>
        <TableRow>
          <TableCell>ID</TableCell>
          <TableCell style={{ cursor: 'copy' }} onClick={e => {
            copyTextToClipboard("" + data.talent.id)
            enqueueSnackbar(`Talent ID "${("" + data.talent.id).substring(0, 9) + "..."}" copied to clipboard`)
            e.preventDefault()
            e.stopPropagation()
          }}>{ data.talent.id }</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>First Name</TableCell>
          <TableCell>
            <TextField
              variant="outlined"
              value={detail.firstName}
              size="small"
              onChange={(e) => setDetailParam("firstName", e.target.value)}
            />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Last Name</TableCell>
          <TableCell>
            <TextField
              variant="outlined"
              value={detail.lastName}
              size="small"
              onChange={(e) => setDetailParam("lastName", e.target.value)}
            />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Age</TableCell>
          <TableCell>
            <TextField
              type={"number"}
              variant="outlined"
              value={detail.age}
              size="small"
              onChange={(e) => setDetailParam("age", Number(e.target.value))}
            />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Gender</TableCell>
          <TableCell>
            <Select
              menuPortalTarget={document.getElementById("menu-portal")!}
              onChange={(v) => {
                setDetailParam("gender", v?.value ?? null);
              }}
              options={Object.keys(Gender).map(key =>
                // @ts-ignore
                ({ value: Gender[key], label: Gender[key] }))}
              value={detail.gender ? { value: detail.gender, label: detail.gender } : null}
              styles={{
                control: base => ({
                  ...base,
                  maxWidth: 400
                }),
                menu: base => ({
                  ...base,
                  maxWidth: 400
                })
              }}
            />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Ethnic Identity</TableCell>
          <TableCell>
            <TextField
              variant="outlined"
              value={detail.ethnicIdentity}
              size="small"
              onChange={(e) => setDetailParam("ethnicIdentity", e.target.value)}
            />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Pronouns</TableCell>
          <TableCell>
            <TextField
              variant="outlined"
              value={detail.pronouns}
              size="small"
              onChange={(e) => setDetailParam("pronouns", e.target.value)}
            />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Member of Union</TableCell>
          <TableCell>
            <Checkbox checked={detail.memberOfUnion ?? false} onChange={(_, checked) => setDetailParam("memberOfUnion", checked)} />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Desired Project Categories</TableCell>
          <TableCell>
            <MultiSelect
              onChange={(v) => {
                setDetailParam("desiredProjectCategories", v.map(val => val.value));
              }}
              options={Object.keys(TalentJobCategory).map(key =>
                // @ts-ignore
                ({ value: TalentJobCategory[key], label: TalentJobCategory[key] }))}
              value={(detail.desiredProjectCategories ?? []).map(key =>
                ({ value: key, label: key }))}
              styleOverrides={{
                control:{
                  maxWidth: 400
                },
                menu: {
                  maxWidth: 400
                }
              }}
            />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Hired Project Categories</TableCell>
          <TableCell>
            <MultiSelect
              onChange={(v) => {
                setDetailParam("hiredProjectCategories", v.map(val => val.value));
              }}
              options={Object.keys(TalentJobCategory).map(key =>
                // @ts-ignore
                ({ value: TalentJobCategory[key], label: TalentJobCategory[key] }))}
              value={(detail.hiredProjectCategories ?? []).map(key =>
                ({ value: key, label: key }))}
              styleOverrides={{
                control:{
                  maxWidth: 400
                },
                menu: {
                  maxWidth: 400
                }
              }}
            />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Brand Conflicts</TableCell>
          <TableCell>
            <MultiSelect
              onChange={(v) => {
                setDetailParam("brandConflicts", v.map(val => val.value));
              }}
              options={(detail.brandConflicts ?? []).map(key =>
                ({ value: key, label: key }))}
              value={(detail.brandConflicts ?? []).map(key =>
                ({ value: key, label: key }))}
              creatable
              onCreateValue={newValue => setDetailParam("brandConflicts", [...(detail.brandConflicts ?? []), newValue])}
              styleOverrides={{
                control:{
                  maxWidth: 400
                },
                menu: {
                  maxWidth: 400
                }
              }}
            />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Job Limitations</TableCell>
          <TableCell>
            <TextField
              multiline
              rows={8}
              variant="outlined"
              value={detail.limitations}
              size="small"
              style={{
                width: 400
              }}
              onChange={(e) => setDetailParam("limitations", e.target.value)}
            />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Other Job Info</TableCell>
          <TableCell>
            <TextField
              multiline
              rows={8}
              variant="outlined"
              value={detail.otherJobInfo}
              size="small"
              style={{
                width: 400
              }}
              onChange={(e) => setDetailParam("otherJobInfo", e.target.value)}
            />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Showcase Voices on Voice123</TableCell>
          <TableCell>
            <Checkbox checked={detail.showcaseVoicesOnVoice123 ?? false} onChange={(_, checked) => setDetailParam("showcaseVoicesOnVoice123", checked)} />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Interest in Character Voice Recording</TableCell>
          <TableCell>
            <MultiSelect
              onChange={(v) => {
                setDetailParam("interestedInCharacterVoiceRecording", v.map(val => val.value));
              }}
              options={(detail.interestedInCharacterVoiceRecording ?? []).map(key =>
                ({ value: key, label: key }))}
              value={(detail.interestedInCharacterVoiceRecording ?? []).map(key =>
                ({ value: key, label: key }))}
              creatable
              onCreateValue={newValue => setDetailParam("interestedInCharacterVoiceRecording", [...(detail.interestedInCharacterVoiceRecording ?? []), newValue])}
              styleOverrides={{
                control:{
                  maxWidth: 400
                },
                menu: {
                  maxWidth: 400
                }
              }}
            />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Interest in Regional Accent Recording</TableCell>
          <TableCell>
            <MultiSelect
              onChange={(v) => {
                setDetailParam("interestedInRegionalAccentRecording", v.map(val => val.value));
              }}
              options={(detail.interestedInRegionalAccentRecording ?? []).map(key =>
                ({ value: key, label: key }))}
              value={(detail.interestedInRegionalAccentRecording ?? []).map(key =>
                ({ value: key, label: key }))}
              creatable
              onCreateValue={newValue => setDetailParam("interestedInRegionalAccentRecording", [...(detail.interestedInRegionalAccentRecording ?? []), newValue])}
              styleOverrides={{
                control:{
                  maxWidth: 400
                },
                menu: {
                  maxWidth: 400
                }
              }}
            />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Interest in Non-English Language Recording</TableCell>
          <TableCell>
            <MultiSelect
              onChange={(v) => {
                setDetailParam("interestedInNonenglishLanguageRecording", v.map(val => val.value));
              }}
              options={(detail.interestedInNonenglishLanguageRecording ?? []).map(key =>
                ({ value: key, label: key }))}
              value={(detail.interestedInNonenglishLanguageRecording ?? []).map(key =>
                ({ value: key, label: key }))}
              creatable
              onCreateValue={newValue => setDetailParam("interestedInNonenglishLanguageRecording", [...(detail.interestedInNonenglishLanguageRecording ?? []), newValue])}
              styleOverrides={{
                control:{
                  maxWidth: 400
                },
                menu: {
                  maxWidth: 400
                }
              }}
            />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell />
          <TableCell />
        </TableRow>
        <TableRow style={{ opacity: 0.5 }}>
          <TableCell>DEPRECATED FIELDS</TableCell>
          <TableCell />
        </TableRow>
        <TableRow style={{ opacity: 0.5 }}>
          <TableCell>Recommended Use Cases</TableCell>
          <TableCell>
            <TextField
              multiline
              rows={8}
              variant="outlined"
              value={detail.allowableUseCases}
              size="small"
              style={{
                width: 400
              }}
              onChange={(e) => setDetailParam("allowableUseCases", e.target.value)}
            />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell />
          <TableCell />
        </TableRow>
        <TableRow>
          <TableCell>Recorded Lines</TableCell>
          <TableCell>{ !assignmentContentRecordingCountsLoading ? Object.keys(selectedContentForAssignments).reduce((accA, assignmentId) =>
            accA + selectedContentForAssignments[assignmentId].reduce((accC, c) =>
              accC + (assignmentContentRecordingCounts[assignmentId]?.[c] ?? 0), 0), 0) : <CircularProgress size={12} /> }</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Total Lines</TableCell>
          <TableCell>{ Object.keys(selectedContentForAssignments).reduce((accA, assignmentId) =>
            accA + selectedContentForAssignments[assignmentId].reduce((accC, c) =>
              accC + (data.contentTags.results.flatMap(ct => ct.content).find(oC => oC.id === c)?.countOfLines ?? 0), 0), 0) }</TableCell>
        </TableRow>
      </Table>
      <Button variant={"contained"} onClick={save}>Save</Button>
    </Card>
  )

  const assignmentAccordions = data.talent.assignments.map(assignment => (
    <Accordion key={assignment.id}>
      <AccordionSummary
        expandIcon={<ExpandMoreOutlined/>}
      >
      <Typography style={{fontSize: 20}}>{assignment.voice ? `Vocal: "${assignment.voice.title}"` : "Unnamed Assignment"}{assignment.isTalentRecordingRequestTarget && " (Pick up)"}</Typography>
    </AccordionSummary>
    <AccordionDetails>
      <Box flex flexDirection="row">
        <Box style={{ cursor: 'copy' }} onClick={e => {
          copyTextToClipboard("" + assignment.id)
          enqueueSnackbar(`Assignment ID "${("" + assignment.id).substring(0, 9) + "..."}" copied to clipboard`)
          e.preventDefault()
          e.stopPropagation()
        }}>
          <TextField style={{width: 500}} value={assignment.id} label="Assignment ID" disabled/>
        </Box>
        <Box>
          Visible to talent?
          <input type="checkbox" checked={newAssignmentVisibleToTalent[assignment.id]} disabled={assignment.isTalentRecordingRequestTarget} onChange={(e) => setNewAssignmentVisibleToTalent({
            ...newAssignmentVisibleToTalent,
            [assignment.id]: e.target.checked
          })}/>
        </Box>
      <Box style={{width: 1000}}>
        <DualListBox
          disabled={assignment.isTalentRecordingRequestTarget}
          preserveSelectOrder
          canFilter
          showOrderButtons
          style={{height: "100%"}}
          icons={{
            moveLeft: <ChevronLeft/>,
            moveRight: <ChevronRight/>,
            moveDown: <ArrowDownwardSharp/>,
            moveUp: <ArrowUpwardSharp/>
          }}
          options={
            data.contentTags.results.map(ct => ({
              label: ct.tag,
              options: ct.content.map(c => {
                const isSelected = selectedContentForAssignments[assignment.id]?.includes(c.id) ?? false;
                const recordedLines = assignmentContentRecordingCounts[assignment.id]?.[c.id]

                return ({
                  label: `${c.title}${c.isRecordingRequestTargetOfTalentVoice ? ` - Vocal "${c.isRecordingRequestTargetOfTalentVoice.title}"` : ""} (${isSelected ? `${!assignmentContentRecordingCountsLoading ? `${recordedLines === c.countOfLines ? "COMPLETE, " : ""}${recordedLines ?? 0}` : "..."}/${c.countOfLines} recorded` : `${c.countOfLines} lines`})`,
                  value: c.id
                });
              })
            }))
          }
          selected={selectedContentForAssignments[assignment.id] || []}
          onChange={(values: string[]) => {
            setSelectedContentForAssignments({
              ...selectedContentForAssignments,
              [assignment.id]: values
            })
          }}
        />
        <Box>
          Next up: <strong>{ assignment?.currentContent?.content?.title }</strong>
        </Box>
      </Box>

      <Box>
        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={() => updateAssignment(assignment.id, selectedContentForAssignments[assignment.id])}
        >
          Save
        </Button>
      </Box>
      </Box>

    </AccordionDetails>
  </Accordion>))

  return (
    <>
      <Typography style={{fontSize: 24}}>Talent</Typography>
      { infoBox }
      <Box style={{marginTop: 20}}>
        <AppBar position="relative">
          <Tabs value={selectedTab} onChange={(e, tab) => setSelectedTab(tab)} aria-label="simple tabs example">
            <Tab value={TalentDetailTab.Assignments} label="Assignments"/>
            <Tab value={TalentDetailTab.Recordings} label="Recordings"/>
          </Tabs>
        </AppBar>
        <Box style={{marginTop: 20}}>
          { (selectedTab === TalentDetailTab.Assignments) && (
            <Box>
              {assignmentAccordions}
              <Fab color="primary" onClick={() => setCreateAssignmentOpen(true)}>
                <AddIcon/>
              </Fab>
            </Box>
          )}
          { (selectedTab === TalentDetailTab.Recordings) && (
            <Box/>
          )}
        </Box>
      </Box>
      <CreateAssignmentModal
        isOpen={createAssignmentOpen}
        talentId={id}
        onClose={() => setCreateAssignmentOpen(false)}
        onCreated={assignmentId => {
          refetch()
        }}
      />
    </>
  )
}

enum TalentDetailTab {
  Assignments = "assignments",
  Recordings = "recordings"
}

interface CreateAssignmentModalProps {
  isOpen: boolean;
  talentId: string;
  onClose(): void;
  onCreated(id: string): void;
  className?: string;
}

const CreateAssignmentModal: React.FC<CreateAssignmentModalProps> = props => {
  const query = useCreateAssignmentModalQuery({ variables: { talent: props.talentId } });
  const [createAssignmentMutation] = useCreateAssignmentMutation()

  const [existingVoiceId, setExistingVoiceId] = React.useState<string | null>(null);
  const [title, setTitle] = React.useState("");
  const [dueDate, setDueDate] = React.useState<Date | null>(null)

  const create = async () => {
    const res = await createAssignmentMutation({
      variables: {
        input: {
          newVoice: title ? {
            voiceTitle: title,
          } : null,
          existingVoice: existingVoiceId ? {
            voiceId: existingVoiceId
          } : null,
          due: dueDate?.getUTCMilliseconds(),
          talent: props.talentId
        }
      }
    })

    props.onCreated(res.data!.createAssignment.id)
    close()
  }

  const close = () => {
    setTitle("")
    setDueDate(null)
    props.onClose()
  }

  const selectedVoice = query.data?.allVoices?.find(v => v.id === existingVoiceId)

  return (
    <Dialog open={props.isOpen}>
      <DialogTitle>Create Assignment</DialogTitle>
      <DialogContent>
        <Box>
          { query.data && <Select
            menuPosition={"fixed"}
            value={selectedVoice ? { value: selectedVoice.id, label: `${selectedVoice.title} (${selectedVoice.id})` } : null}
            options={query.data.allVoices.map(voice => (
              { value: voice.id, label: `${voice.title} (${voice.id})` }
            ))}
            onChange={(value) => setExistingVoiceId(value?.value ?? null)}
          /> }
        </Box>
        {!existingVoiceId && <Box>
          <TextField
            label="Voice title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          ></TextField>
        </Box> }
        <Box>
          <KeyboardDatePicker
            disableToolbar
            variant="inline"
            format="MM/dd/yyyy"
            margin="normal"
            id="date-picker-inline"
            label="Due date"
            value={dueDate}
            onChange={(e) => setDueDate(e)}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button disabled={!(title || existingVoiceId)} onClick={create} variant="contained" color="primary">Create</Button>
        <Button variant="outlined" color="primary" onClick={close}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
};
