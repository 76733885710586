import * as React from "react";
import {gql, useMutation, useQuery} from "@apollo/client";
import {
  Checkbox,
  CircularProgress, FormControlLabel, MenuItem, OutlinedTextFieldProps, Select, Table, TableCell, TableRow, TextField
} from "@material-ui/core";
import {
  DialogWithCloseButton,

} from "../../atoms/dialog-with-close-button";
import {ArrowDownwardSharp, ArrowUpwardSharp, ChevronLeft, ChevronRight} from "@material-ui/icons";
import DualListBox from "react-dual-listbox";
import {useAddApiTokenModalQuery, useAddApiTokenMutation} from "../../../graphql/generated";

interface AddApiTokenModalProps {
  isShown: boolean;
  user?: string;
  onCancel: () => any;
  onAdded: () => Promise<any>;
}

export const AddApiTokenModal: React.FC<AddApiTokenModalProps> = (props) => {
  const [adding, setAdding] = React.useState(false);

  const [user, setUser] = React.useState<string | null>(props.user ?? null);
  const [name, setName] = React.useState("");

  const { data } = useAddApiTokenModalQuery();

  const [accounts, setAccounts] = React.useState<string[] | null>([]);

  const [addTokenMutation, addTokenResult] = useAddApiTokenMutation();

  const add = async () => {
    setAdding(true);
    if (!user) return;

    await addTokenMutation({
      variables: {
        input: {
          name,
          accounts: accounts ?? [],
          user: user
        }
      }
    });
    setAdding(false);
  };

  const close = () => {
    props.onCancel();
    setName("");
  };

  const added = () => {
    props.onAdded();
    close()
  }

  const field = (
    value: string,
    setValue: (v: string) => any,
    title: string
  ): OutlinedTextFieldProps => ({
    value,
    onChange: (e: any) => setValue(e.target.value),
    label: title,
    variant: "outlined",
    fullWidth: true,
  });

  if (!data) return null;

  return (
    <DialogWithCloseButton
      open={props.isShown}
      title={"Add API Token"}
      onPrimaryAction={add}
      {...(addTokenResult.data ? {
        primaryActionDisabled: true,
        onClose: added
      } : {
        primaryActionDisabled: name.trim() === "" || user === null,
        onClose: close
      })}
      primaryActionText={"Confirm"}
    >
      {addTokenResult.data ?
        <>
          <Table>
            <TableRow>
              <TableCell>Generated Token</TableCell>
              <TableCell>
                <TextField value={addTokenResult.data.createApiToken.token} />
              </TableCell>
            </TableRow>
          </Table>
        </> :
        <>
          <Table>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>
                <TextField {...field(name, setName, "Name")} />
              </TableCell>
            </TableRow>
            {!props.user && (
              <TableRow>
                <TableCell>User</TableCell>
                <TableCell>
                  <Select value={user} onChange={({ target: { value } }) => setUser(value as string)}>
                    {data.users.results.map(user => (
                      <MenuItem value={user.id}>{user.firstName} {user.lastName} ({user.emailAddress})</MenuItem>
                    ))}
                  </Select>
                </TableCell>
              </TableRow>
            )}
          </Table>
          <div>
            <FormControlLabel control={<Checkbox checked={accounts === null} onChange={({ target: { checked } }) => setAccounts(checked ? null : [])} />} label="Unrestricted Account Access" />
            { accounts !== null && user !== null && <DualListBox
              canFilter
              style={{height: "100%"}}
              icons={{
                moveLeft: <ChevronLeft/>,
                moveRight: <ChevronRight/>,
                moveDown: <ArrowDownwardSharp/>,
                moveUp: <ArrowUpwardSharp/>,
              }}
              options={data.users.results.find(u => u.id === user)!.accounts.filter(({ account }) => account.allRoleRestrictions.synthesisApiAccess).map(({ account }) => ({
                value: account.id,
                label: account.name,
              }))}
              selected={accounts}
              onChange={(values: string[]) => {
                setAccounts(values);
              }}
            /> }
          </div>
        </>
      }
    </DialogWithCloseButton>
  );
}
