import * as React from "react";
import { gql, useMutation, useQuery } from "@apollo/client";
import {
  Box,
  Button,
  Card,
  CircularProgress,
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Divider,
  Tabs,
  Tab,
  AppBar,
  Fab, Checkbox, Switch,
} from "@material-ui/core";
import DualListBox from "react-dual-listbox";
import { useParams } from "react-router-dom";
import moment from "moment";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { TabContext, TabPanel } from "@material-ui/lab";
import { AddIcon } from "@material-ui/data-grid";
import EditIcon from "@material-ui/icons/Edit";
import {
  ChevronLeft,
  ChevronRight,
  ArrowDownwardSharp,
  ArrowUpwardSharp, Check,
} from "@material-ui/icons";
import {
  DialogWithCloseButton,
  DialogWithCloseButtonProps,
} from "../../atoms/dialog-with-close-button";
import {usePricingPlanDetailQuery, useUpdatePricingPlanMutation} from "../../../graphql/generated";

type PricingPlanDetailProps = IPricingPlanDetailProps;

interface IPricingPlanDetailProps {}

enum PricingPlanDetailTab {
  Roles = "Roles",
  TrialRoles = "TrialRoles",
  Accounts = "Accounts"
}

export const PricingPlanDetail: React.FC<PricingPlanDetailProps> = (props) => {
  const { id } = useParams<{ id: string }>();

  const [tab, setTab] = React.useState(PricingPlanDetailTab.Roles);

  const { data, loading, refetch } = usePricingPlanDetailQuery({
    variables: { id },
  });

  const [editRolesOpen, setEditRolesOpen] = React.useState(false);
  const [editTrialRolesOpen, setEditTrialRolesOpen] = React.useState(false);

  const [pricingPlanName, setPricingPlanName] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [featuresCopy, setFeaturesCopy] = React.useState("");
  const [isHidden, setIsHidden] = React.useState(false);
  const [selectedRoles, setSelectedRoles] = React.useState<string[]>([]);

  const [trialPeriodDays, setTrialPeriodDays] = React.useState<string | null>();
  const [trialDescription, setTrialDescription] = React.useState<string | null>(null);
  const [trialFeaturesCopy, setTrialFeaturesCopy] = React.useState<string | null>(null);
  const [trialSelectedRoles, setTrialSelectedRoles] = React.useState<string[]>([]);

  const [updateStripePriceModalOpen, setUpdateStripePriceModalOpen] = React.useState(false);
  const [stripePriceId, setStripePriceId] = React.useState<string | null>();
  const [updateAllRelatedSubscriptions, setUpdateAllRelatedSubscriptions] = React.useState(true);

  React.useEffect(() => {
    if (data && data.pricingPlan) {
      setPricingPlanName(data.pricingPlan.name);
      setDescription(data.pricingPlan.description);
      setFeaturesCopy(data.pricingPlan.featuresCopy);
      setIsHidden(data.pricingPlan.hidden);
      setSelectedRoles(
        data.pricingPlan.roles.map((v) => v.id)
      );
      setStripePriceId(data.pricingPlan.stripePriceId ?? null)

      setTrialPeriodDays(data.pricingPlan.trial?.periodDays?.toString() ?? null)
      setTrialDescription(data.pricingPlan.trial?.description ?? null)
      setTrialFeaturesCopy(data.pricingPlan.trial?.featuresCopy ?? null)
      setTrialSelectedRoles(
        data.pricingPlan.trial?.roles.map((v) => v.id) ?? []
      );
    }
  }, [data]);

  const [updatePricingPlanDetailMutation] = useUpdatePricingPlanMutation();

  const [saving, setSaving] = React.useState(false);
  const [saved, setSaved] = React.useState(false);

  const save = async () => {
    if (!data?.pricingPlan) return;

    setSaving(true);
    setSaved(false);
    await updatePricingPlanDetailMutation({
      variables: {
        id: data.pricingPlan.id,
        roles: selectedRoles,
        name: pricingPlanName,
        description: description,
        featuresCopy: featuresCopy,
        hidden: isHidden,
        stripePriceId: stripePriceId ? {
          priceId: stripePriceId,
          updateAllRelatedSubscriptions: updateAllRelatedSubscriptions
        } : null,
        trial: {
          periodDays: trialPeriodDays ? parseInt(trialPeriodDays) : null,
          description: trialDescription ?? "",
          featuresCopy: trialFeaturesCopy ?? "",
          roles: trialSelectedRoles
        }
      },
    });
    await refetch();
    setSaving(false);
    setSaved(true);
    setEditRolesOpen(false);
    setUpdateStripePriceModalOpen(false);
  };

  if (!data || loading) return <CircularProgress />;

  const hasTrial = trialPeriodDays != null && parseInt(trialPeriodDays) != 0;

  const infoBoxes = (
    <div style={{ marginBottom: 20, }}>
      <Box display={"flex"} alignItems={"flex-start"}>
        <Card style={{ width: 500, marginRight: 20 }}>
          <Table>
            <TableRow>
              <TableCell>
                <strong>Pricing Plan ID</strong>
              </TableCell>
              <TableCell>{data.pricingPlan.id}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <strong>Created</strong>
              </TableCell>
              <TableCell>{moment(data.pricingPlan.created).format()}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <strong>Name</strong>
              </TableCell>
              <TableCell>
                <TextField
                  variant="outlined"
                  value={pricingPlanName}
                  size="small"
                  onChange={(e) => setPricingPlanName(e.target.value)}
                  style={{
                    width: '100%'
                  }}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <strong>Description</strong>
              </TableCell>
              <TableCell>
                <TextField
                  variant="outlined"
                  value={description}
                  size="small"
                  onChange={(e) => setDescription(e.target.value)}
                  style={{
                    width: '100%'
                  }}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <strong>Features</strong>
              </TableCell>
              <TableCell>
                <TextField
                  variant="outlined"
                  value={featuresCopy}
                  size="small"
                  multiline
                  rows={6}
                  rowsMax={6}
                  onChange={(e) => setFeaturesCopy(e.target.value)}
                  style={{
                    width: '100%'
                  }}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <strong>Hidden</strong>
              </TableCell>
              <TableCell>
                <Switch checked={isHidden} onChange={(_, checked) => setIsHidden(checked)} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell><strong>Stripe Price ID</strong></TableCell>
              <TableCell>
                <TextField
                  value={stripePriceId ?? ""}
                  onClick={e => {
                    setUpdateStripePriceModalOpen(true);
                    (e.target as HTMLInputElement).blur();
                  }}
                  variant="outlined"
                  size={"small"}
                  style={{
                    width: '100%'
                  }}
                />
              </TableCell>
            </TableRow>
          </Table>
        </Card>
        <Card style={{ width: 500, }}>
          <Table>
            <TableRow>
              <TableCell><strong>Trial Period Days</strong></TableCell>
              <TableCell>
                <TextField
                  type={"number"}
                  value={trialPeriodDays ?? ""}
                  onChange={(e) => setTrialPeriodDays(e.target.value !== "" ? e.target.value : null)}
                  label={"Trial Period Days"}
                  style={{
                    width: '100%'
                  }}
                />
              </TableCell>
            </TableRow>
            {hasTrial &&
              <>
                <TableRow>
                  <TableCell>
                    <strong>Trial Description</strong>
                  </TableCell>
                  <TableCell>
                    <TextField
                      variant="outlined"
                      value={trialDescription}
                      size="small"
                      onChange={(e) => setTrialDescription(e.target.value)}
                      style={{
                        width: '100%'
                      }}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <strong>Trial Features</strong>
                  </TableCell>
                  <TableCell>
                    <TextField
                      variant="outlined"
                      value={trialFeaturesCopy}
                      size="small"
                      multiline
                      rows={6}
                      rowsMax={6}
                      onChange={(e) => setTrialFeaturesCopy(e.target.value)}
                      style={{
                        width: '100%'
                      }}
                    />
                  </TableCell>
                </TableRow>
              </>
            }
          </Table>
        </Card>
      </Box>
      <div style={{ margin: 10 }}>
        <Button color={"primary"} variant={"contained"} onClick={save} style={{ marginRight: 10 }}>Save</Button>
        {saving && <CircularProgress />}
        {saved && <Check />}
      </div>
    </div>
  );

  const roles = (
    <TableContainer style={{ margin: 0, padding: 0 }} component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <strong>ID</strong>
            </TableCell>
            <TableCell>
              <strong>Role</strong>
            </TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.pricingPlan.roles
            .map(role => (
            <TableRow key={role.id}>
              <TableCell>{role.id}</TableCell>
              <TableCell>{role.name}</TableCell>
              <TableCell></TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

  const editRolesDialogProps: DialogWithCloseButtonProps = {
    title: "Edit roles",
    open: editRolesOpen,
    onPrimaryAction: () => save(),
    primaryActionDisabled: false,
    onClose: () => setEditRolesOpen(false),
  };

  const editRolesModal = (
    <DialogWithCloseButton {...editRolesDialogProps}>
      <DualListBox
        canFilter
        style={{ height: "100%" }}
        icons={{
          moveLeft: <ChevronLeft />,
          moveRight: <ChevronRight />,
          moveDown: <ArrowDownwardSharp />,
          moveUp: <ArrowUpwardSharp />,
        }}
        options={data.roles.results.map(role => ({
          value: role.id,
          label: role.name,
        }))}
        selected={selectedRoles}
        onChange={(values: string[]) => {
          setSelectedRoles(values);
        }}
      />
    </DialogWithCloseButton>
  );

  const trialRoles = (
    <TableContainer style={{ margin: 0, padding: 0 }} component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <strong>ID</strong>
            </TableCell>
            <TableCell>
              <strong>Role</strong>
            </TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.pricingPlan.trial?.roles
            .map(role => (
              <TableRow key={role.id}>
                <TableCell>{role.id}</TableCell>
                <TableCell>{role.name}</TableCell>
                <TableCell></TableCell>
              </TableRow>
            )) ?? []}
        </TableBody>
      </Table>
    </TableContainer>
  );

  const editTrialRolesDialogProps: DialogWithCloseButtonProps = {
    title: "Edit trial roles",
    open: editTrialRolesOpen,
    onPrimaryAction: () => save(),
    primaryActionDisabled: false,
    onClose: () => setEditTrialRolesOpen(false),
  };

  const editTrialRolesModal = (
    <DialogWithCloseButton {...editTrialRolesDialogProps}>
      <DualListBox
        canFilter
        style={{ height: "100%" }}
        icons={{
          moveLeft: <ChevronLeft />,
          moveRight: <ChevronRight />,
          moveDown: <ArrowDownwardSharp />,
          moveUp: <ArrowUpwardSharp />,
        }}
        options={data.roles.results.map(role => ({
          value: role.id,
          label: role.name,
        }))}
        selected={trialSelectedRoles}
        onChange={(values: string[]) => {
          setTrialSelectedRoles(values);
        }}
      />
    </DialogWithCloseButton>
  );

  const accounts = (
    <TableContainer style={{ margin: 0, padding: 0 }} component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <strong>ID</strong>
            </TableCell>
            <TableCell>
              <strong>Account</strong>
            </TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.pricingPlan.pricingPlanSubscriptions
            .map(subscription => (
              <TableRow key={subscription.account.id}>
                <TableCell>{subscription.account.id}</TableCell>
                <TableCell>{subscription.account.name}</TableCell>
                <TableCell></TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

  const updateStripePriceDialogProps: DialogWithCloseButtonProps = {
    title: "Edit stripe price",
    open: updateStripePriceModalOpen,
    onPrimaryAction: () => save(),
    primaryActionDisabled: stripePriceId === null,
    onClose: () => setUpdateStripePriceModalOpen(false),
  };

  const updateStripePriceModal = (
    <DialogWithCloseButton {...updateStripePriceDialogProps}>
      <Table>
        <TableRow>
          <TableCell><strong>Stripe Price ID</strong></TableCell>
          <TableCell>
            <TextField
              value={stripePriceId ?? ""}
              onChange={(e) => setStripePriceId(e.target.value !== "" ? e.target.value : null)}
              label={"Stripe Price Id"}
              style={{
                width: '100%'
              }}
            />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <strong>Update all Related Subscriptions</strong>
          </TableCell>
          <TableCell>
            <Switch checked={updateAllRelatedSubscriptions} onChange={(_, checked) => setUpdateAllRelatedSubscriptions(checked)} />
          </TableCell>
        </TableRow>
      </Table>
    </DialogWithCloseButton>
  );

  return (
    <>
      <Typography variant="h4">{data.pricingPlan.name}</Typography>
      <Box style={{ marginTop: 20 }}>{infoBoxes}</Box>

      <TabContext value={tab}>
        <AppBar position="static">
          <Tabs value={tab} onChange={(e, v) => setTab(v as PricingPlanDetailTab)}>
            <Tab value={PricingPlanDetailTab.Roles} label="Roles" />
            { hasTrial && <Tab value={PricingPlanDetailTab.TrialRoles} label="Trial Roles" /> }
            <Tab value={PricingPlanDetailTab.Accounts} label="Accounts" />
          </Tabs>
        </AppBar>
        <TabPanel
          style={{ margin: 0, padding: 0, marginTop: 20 }}
          value={PricingPlanDetailTab.Roles}
        >
          <Typography>The list of roles that are applied to an account when this pricing plan is purchased</Typography>
          <Fab color="primary" onClick={() => setEditRolesOpen(true)}>
            <EditIcon />
          </Fab>
          {roles}
        </TabPanel>
        <TabPanel
          style={{ margin: 0, padding: 0, marginTop: 20 }}
          value={PricingPlanDetailTab.TrialRoles}
        >
          <Typography>The list of roles that are applied to an account when this pricing plan is purchased</Typography>
          <Fab color="primary" onClick={() => setEditTrialRolesOpen(true)}>
            <EditIcon />
          </Fab>
          {trialRoles}
        </TabPanel>
        <TabPanel
          style={{ margin: 0, padding: 0, marginTop: 20 }}
          value={PricingPlanDetailTab.Accounts}
        >
          <Typography>The list of accounts that have subscriptions to this pricing plan</Typography>
          {accounts}
        </TabPanel>
      </TabContext>
      {editRolesModal}
      {editTrialRolesModal}
      {updateStripePriceModal}
    </>
  );
};
