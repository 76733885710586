import * as React from "react";
import {
  Box,
  Button,
  Card,
  CircularProgress,
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Divider,
  Tabs,
  Tab,
  AppBar,
  Fab, Input, TablePagination, TableFooter, IconButton,
} from "@material-ui/core";
import DualListBox from "react-dual-listbox";
import { useParams } from "react-router-dom";
import moment from "moment";
import { TabContext, TabPanel } from "@material-ui/lab";
import EditIcon from "@material-ui/icons/Edit";
import {
  ChevronLeft,
  ChevronRight,
  ArrowDownwardSharp,
  ArrowUpwardSharp, Delete,
} from "@material-ui/icons";
import {
  DialogWithCloseButton,
  DialogWithCloseButtonProps,
} from "../../atoms/dialog-with-close-button";
import { Link } from "react-router-dom";
import {AddApiTokenModal} from "../../molecules/add-api-token-modal";
import {AddIcon} from "@material-ui/data-grid";
import {DeleteApiTokenModal} from "../../molecules/delete-api-token-modal";
import {useUpdateUserMutation, useUserDetailQuery} from "../../../graphql/generated";

enum UserDetailTab {
  Accounts = "Accounts",
  Tokens = "Tokens"
}

export const UserDetail: React.FC = (props) => {
  const { id } = useParams<{ id: string }>();

  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [tab, setTab] = React.useState(UserDetailTab.Accounts);

  const { data, loading, refetch } = useUserDetailQuery({
    variables: {
      id
    },
  });

  const [editAccountsOpen, setEditAccountsOpen] = React.useState(false);
  const [createTokenOpen, setCreateTokenOpen] = React.useState(false);
  const [deletingApiToken, setDeletingApiToken] = React.useState<string | null>(null);

  const [selectedAccounts, setSelectedAccounts] = React.useState([] as string[]);

  React.useEffect(() => {
    if (data && data.user) {
      setSelectedAccounts(
        data.user.accounts.map(a => a.account.id)
      );
    }
  }, [data]);

  React.useEffect(() => {
    if (data && !loading) {
      setFirstName(data.user.firstName);
      setLastName(data.user.lastName);
    }
  }, [data, loading]);

  const [updateUserDetailMutation] = useUpdateUserMutation();

  const [saving, setSaving] = React.useState(false);

  const save = async () => {
    setSaving(true);
    await updateUserDetailMutation({
      variables: {
        input: {
          accounts: selectedAccounts,
          firstName,
          lastName,
          id: data?.user.id ?? ""
        }
      },
    });
    await refetch();
    setSaving(false);
    setEditAccountsOpen(false);
    setCreateTokenOpen(false);
  };

  if (!data || loading) return <CircularProgress />;

  const infoBox = (
    <Card style={{ marginBottom: 20 }}>
      <Table>
        <TableRow>
          <TableCell>
            <strong>User ID</strong>
          </TableCell>
          <TableCell>{data.user.id}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <strong>Created</strong>
          </TableCell>
          <TableCell>{moment(data.user.created).format()}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <strong>Email Address</strong>
          </TableCell>
          <TableCell>{data.user.emailAddress}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <strong>First Name</strong>
          </TableCell>
          <TableCell>
            <TextField
              variant="outlined"
              value={firstName}
              size="small"
              onChange={(e) => setFirstName(e.target.value)}
            ></TextField>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <strong>Last Name</strong>
          </TableCell>
          <TableCell>
            <TextField
              variant="outlined"
              value={lastName}
              size="small"
              onChange={(e) => setLastName(e.target.value)}
            ></TextField>
          </TableCell>
        </TableRow>
      </Table>
    </Card>
  );

  const synthesisTokens = (
    <TableContainer style={{ margin: 0, padding: 0 }} component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <strong>ID</strong>
            </TableCell>
            <TableCell>
              <strong>Created</strong>
            </TableCell>
            <TableCell>
              <strong>Name</strong>
            </TableCell>
            <TableCell>
              <strong>User</strong>
            </TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.user.apiTokens
            .map(token => (
              <TableRow key={token.id}>
                <TableCell>{token.id}</TableCell>
                <TableCell>{new Date(token.created).toLocaleString()}</TableCell>
                <TableCell>{token.name}</TableCell>
                <TableCell>{token.user.id}</TableCell>
                <TableCell>
                  <IconButton onClick={() => setDeletingApiToken(token.id)}>
                    <Delete />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

  const editAccountsDialogProps: DialogWithCloseButtonProps = {
    title: "Edit accounts",
    open: editAccountsOpen,
    onPrimaryAction: () => save(),
    primaryActionDisabled: false,
    onClose: () => setEditAccountsOpen(false),
  };

  const editAccountsModal = (
    <DialogWithCloseButton {...editAccountsDialogProps}>
      <DualListBox
        canFilter
        style={{height: "100%"}}
        icons={{
          moveLeft: <ChevronLeft/>,
          moveRight: <ChevronRight/>,
          moveDown: <ArrowDownwardSharp/>,
          moveUp: <ArrowUpwardSharp/>,
        }}
        options={data.accounts.results.map((account) => ({
          value: account.id,
          label: account.name,
        }))}
        selected={selectedAccounts}
        onChange={(values: string[]) => {
          setSelectedAccounts(values);
        }}
      />
    </DialogWithCloseButton>
  );

  const accounts = (
    <TableContainer style={{ margin: 0, padding: 0 }} component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <strong>ID</strong>
            </TableCell>
            <TableCell>
              <strong>Account</strong>
            </TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.user.accounts.map(a => a.account)
            .map((account) => (
              <TableRow key={account.id}>
                <TableCell>{account.id}</TableCell>
                <TableCell><Link to={`/accounts/${account.id}`}>{account.name}</Link></TableCell>
                <TableCell></TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

  return (
    <>
      <AddApiTokenModal isShown={createTokenOpen} user={id} onCancel={() => {
        setCreateTokenOpen(false);
      }} onAdded={async () => {
        refetch({
          id
        });
        setCreateTokenOpen(false)
      }} />
      <DeleteApiTokenModal tokenId={deletingApiToken} onCancel={() => setDeletingApiToken(null)} onDelete={async () => {
        setDeletingApiToken(null)
        refetch();
      }} />
      <Typography variant="h4">{data.user.firstName} {data.user.lastName}</Typography>
      <Box style={{ width: 500, marginTop: 20 }}>{infoBox}</Box>

      <TabContext value={tab}>
        <AppBar position="static">
          <Tabs value={tab} onChange={(e, v) => setTab(v as UserDetailTab)}>
            <Tab value={UserDetailTab.Accounts} label="Accounts" />
            <Tab value={UserDetailTab.Tokens} label="Tokens" />
          </Tabs>
        </AppBar>
        <TabPanel
          style={{ margin: 0, padding: 0, marginTop: 20 }}
          value={UserDetailTab.Accounts}
        >
          <Typography>The list of Accounts this user has access to.</Typography>
          <Fab color="primary" onClick={() => setEditAccountsOpen(true)}>
            <EditIcon />
          </Fab>
          {accounts}
        </TabPanel>
        <TabPanel
          style={{ margin: 0, padding: 0, marginTop: 20 }}
          value={UserDetailTab.Tokens}
        >
          <Typography>The list of Tokens that have been created for this user.</Typography>
          <Fab color="primary" onClick={() => setCreateTokenOpen(true)}>
            <AddIcon />
          </Fab>
          {synthesisTokens}
        </TabPanel>
      </TabContext>
      {editAccountsModal}
    </>
  );
};
