import * as React from "react";
import { Box} from "@material-ui/core";
import {TalentListQueryDocument} from "../../../graphql/generated";
import SmartTalentList from "../../molecules/talent-list";

type TalentListProps = ITalentListProps;

interface ITalentListProps {

}

export const TalentList: React.FC<TalentListProps> = (props) => {
  return (
    <Box style={{height: 800}}>
      <SmartTalentList
        query={TalentListQueryDocument}
        variables={({ pagination, where }) => ({
          input: {
            pagination,
            where
          }
        })}
        results={({ talents }) => talents}
        style={{ height: '100%' }}
      />
    </Box>
  )
}
