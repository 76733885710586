import { Button, Card, Dialog, Typography } from "@material-ui/core";
import React, { useState } from "react";
import Select from "react-select";
import { MediaTag } from "../image-card";
import { tagEditStyles } from "./styles";

interface ITagEditModalProps {
  title: string;
  helpText: string;
  show: boolean;
  allTags: MediaTag[];
  onConfirm(selectedTags: MediaTag[]): void;
  onCancel(): void;
}

export const TagEditModal: React.FC<ITagEditModalProps> = (p) => {
  const [selectedTags, setSelected] = useState<MediaTag[]>([]);

  // Hey! In App.css there's a selector that allows overflow: visible on dialogs, which is different behavior. If you have a bug, check there.
  return (
    <Dialog open={p.show} onClose={() => setSelected([])}>
      <Card style={tagEditStyles.container}>
        <Typography variant="h6">{p.title}</Typography>
        <Typography variant="caption">{p.helpText}</Typography>
        <div style={tagEditStyles.select}>
          <Select
            onChange={(v) => {
              setSelected(
                v.map((val) => ({ id: val.value, label: val.label }))
              );
            }}
            options={p.allTags.map((t) => ({ value: t.id, label: t.label }))}
            isMulti
          />
        </div>
        <div style={tagEditStyles.buttonControl}>
          <Button
            style={tagEditStyles.confirm}
            color="primary"
            variant="contained"
            onClick={() => p.onConfirm(selectedTags)}
          >
            Save Changes
          </Button>
          <Button variant="outlined" onClick={p.onCancel}>
            Nevermind
          </Button>
        </div>
      </Card>
    </Dialog>
  );
};
