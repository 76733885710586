import * as React from "react";
import {
  Box,
  Button,
  CircularProgress,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
  IconButton,
  makeStyles,
  Table,
  TableCell,
  TableRow,
  TextField,
  Theme,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { ColDef, DataGrid } from "@material-ui/data-grid";
import EditIcon from "@material-ui/icons/Edit";
import CloseIcon from "@material-ui/icons/Close";
import { useStyles } from "@material-ui/pickers/views/Calendar/Day";
import {
  DialogWithCloseButton,
  DialogWithCloseButtonProps,
} from "../../atoms/dialog-with-close-button";
import {useCreateRoleMutation, useRoleListQuery} from "../../../graphql/generated";

type RoleListProps = IRoleListProps;

interface IRoleListProps {}

export const RoleList: React.FC<RoleListProps> = (props) => {
  const { data, loading } = useRoleListQuery();
  const [createRoleModalOpen, setCreateRoleModalOpen] = React.useState(
    false
  );
  const history = useHistory();

  if (!data || loading) return <CircularProgress />;

  const rows = data.roles.results;

  const columns: ColDef[] = [
    {
      field: "id",
      headerName: "ID",
      width: 100,
    },
    {
      field: "name",
      headerName: "Name",
      width: 200,
    },
    {
      field: "actions",
      headerName: "Actions",
      renderCell: (params) => (
        <Button
          variant="outlined"
          onClick={() => history.push(`/roles/${params.row.id}`)}
        >
          View
        </Button>
      ),
    },
  ];

  const onNewRoleCreated = (id: string) => {
    setCreateRoleModalOpen(false);
    history.push(`/roles/${id}`);
  };

  return (
    <Box>
      <Fab color="primary" onClick={() => setCreateRoleModalOpen(true)}>
        <EditIcon />
      </Fab>
      <CreateNewRoleModal
        shown={createRoleModalOpen}
        onSave={(id: string) => onNewRoleCreated(id)}
        onClose={() => setCreateRoleModalOpen(false)}
      />
      <Box style={{ height: 900 }}>
        <DataGrid rows={rows} columns={columns} />
      </Box>
    </Box>
  );
};

const useRoleCreateStyles = makeStyles((theme: Theme) =>
  createStyles({
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  })
);

interface ICreateNewRoleModalProps {
  shown: boolean;
  onClose: () => void;
  onSave: (account: string) => void;
}

type CreateNewRoleModalProps = ICreateNewRoleModalProps;

const CreateNewRoleModal: React.FC<CreateNewRoleModalProps> = (props) => {
  const [newRoleName, setNewRoleName] = React.useState("");
  const [saving, setSaving] = React.useState(false);
  const classes = useRoleCreateStyles();
  const [createRoleMutation] = useCreateRoleMutation();

  const createRole = async () => {
    setSaving(true);
    const newRole = await createRoleMutation({
      variables: {
        name: newRoleName,
      },
    });

    if (newRole.data?.createRole.id) {
      props.onSave(newRole.data.createRole.id);
    }

    setSaving(false);
  };

  const close = () => {
    setNewRoleName("");
    props.onClose();
  };

  const createRoleDisabled = !newRoleName || saving;

  const dialogProps: DialogWithCloseButtonProps = {
    open: props.shown,
    primaryActionDisabled: createRoleDisabled,
    onClose: close,
    onPrimaryAction: createRole,
    title: "Create new role",
  };

  return (
    <DialogWithCloseButton {...dialogProps}>
      <TextField
        value={newRoleName}
        onChange={(e) => setNewRoleName(e.target.value)}
        label={"New role name"}
        required
      />
    </DialogWithCloseButton>
  );
};
