import { Styles } from "../../../../types/styles";

const cardStyles: Styles = {
  container: {
    padding: "12px",
    width: "100%",
  },
  selected: {
    border: "2px solid blue",
    transition: "all .05s ease-in-out",
  },
  imageContainer: {
    display: "flex",
    justifyItems: "center",
    alignItems: "center",
  },
  image: {
    width: "100%",
    objectFit: "cover",
    aspectRatio: "1/1",
    borderRadius: "4px",
    userSelect: "none",
  },
  chipWrapper: {
    overflow: "scroll",
    height: "60px",
    padding: "12px 0 0 0",
  },
  chip: {
    marginRight: "4px",
    marginBottom: "4px",
  },
};
export default cardStyles;
