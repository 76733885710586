import React, {ReactElement} from "react";
import ConnectedList, {ConnectedListRef} from "../../atoms/connected-list";
import {TypedDocumentNode} from "@graphql-typed-document-node/core";
import {TalentListEntryFragment, TalentMultiWhereInput} from "../../../graphql/generated";

type TalentRow = TalentListEntryFragment;

interface TalentListQueryParams<Query, Variables> {
  query: TypedDocumentNode<Query, Variables>;
  results(query: Query): { results: TalentRow[]; totalResults: number; };
  variables(opts: { pagination: { page: number; perPage: number; }; where: TalentMultiWhereInput; }): Variables;
}

interface SmartTalentListProps<Query, Variables> extends TalentListQueryParams<Query, Variables> {
  onSelect?(account: TalentRow): void;
  onRemove?(account: TalentRow): void;
  className?: string;
  style?: React.CSSProperties;
}

const SmartTalentList = <Q,V>(props: SmartTalentListProps<Q,V>) => {
  return (
    <ConnectedList
      {...props}
      query={props.query}
      searchKeys={['name', 'firstName', 'lastName']}
      variables={({ pagination, search }) => props.variables({
        pagination,
        where: {
          name: search && search.key === "name" ? { search: search.query } : null,
          firstName: search && search.key === "firstName" ? { search: search.query } : null,
          lastName: search && search.key === "lastName" ? { search: search.query } : null
        } })
      }
      results={query => {
        const wrappedResults = props.results(query)
        return ({
          results: wrappedResults.results.map(u => ({...u, link: `/talent/${u.id}`})),
          totalResults: wrappedResults.totalResults
        });
      }}
      renderCell={{
        created: (value) => <div>{(new Date(value)).toLocaleDateString()}</div>,
      }}
    />
  );
};

const SmartTalentListWithRef = React.forwardRef(SmartTalentList) as <Q,V>(p: SmartTalentListProps<Q,V> & { ref?: React.Ref<ConnectedListRef> }) => ReactElement;
export default SmartTalentListWithRef
