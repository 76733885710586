import * as React from "react";
import {
  Box,
  Button,
  Card,
  CircularProgress,
  Table,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { useHistory, Link } from "react-router-dom";
import { CSSProperties } from "@material-ui/core/styles/withStyles";
import { UserCreateModal } from "../../molecules/user-create-modal";
import {AddTalentToUserModal} from "../../molecules/add-talent-to-user-modal";
import {UsersListQueryDocument, useUsersListQuery} from "../../../graphql/generated";
import SmartUserList from "../../molecules/user-list";
import {ConnectedListRef} from "../../atoms/connected-list";

type UsersListProps = IUsersListProps;

interface IUsersListProps {}

export const UsersList: React.FC<UsersListProps> = (props) => {
  const userListRef = React.useRef<ConnectedListRef>(null)

  const [createUserOpen, setCreateUserOpen] = React.useState(false);

  const createUserModal = (
    <UserCreateModal
      {...{
        open: createUserOpen,
        onCancel: () => setCreateUserOpen(false),
        onCreate: async () => {
          await userListRef.current?.refetch();
        }
      }}
    />
  );

  return (
    <Box>
      {createUserModal}
      <Button variant="contained" color="primary" onClick={() => setCreateUserOpen(true)}>
        Create user
      </Button>
      <Card>
        <SmartUserList
          ref={userListRef}
          query={UsersListQueryDocument}
          variables={({ pagination, where }) => ({ input: { pagination, where } })}
          results={({ users }) => users}
          style={{ minHeight: 900 }}
        />
      </Card>
    </Box>
  );
};
