import * as React from "react";
import {
  Box,
  createStyles,
  Fab,
  InputLabel,
  makeStyles,
  TextField,
  Theme,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import {
  DialogWithCloseButton,
  DialogWithCloseButtonProps,
} from "../../atoms/dialog-with-close-button";
import {Add, ArrowDownwardSharp, ArrowUpwardSharp, ChevronLeft, ChevronRight} from "@material-ui/icons";
import DualListBox from "react-dual-listbox";
import {
  AccountListQueryDocument, useCreateAccountModalRolesQuery,
  useCreateAccountMutation
} from "../../../graphql/generated";
import SmartAccountList from "../../molecules/account-list";

type AccountListProps = IAccountListProps;

interface IAccountListProps {}

export const AccountListPage: React.FC<AccountListProps> = (props) => {
  const [createAccountModalOpen, setCreateAccountModalOpen] = React.useState(
    false
  );
  const history = useHistory();

  const onNewAccountCreated = (id: string) => {
    setCreateAccountModalOpen(false);
    history.push(`/accounts/${id}`);
  };

  return (
    <Box>
      <Fab color="primary" onClick={() => setCreateAccountModalOpen(true)}>
        <Add />
      </Fab>
      <CreateNewAccountModal
        shown={createAccountModalOpen}
        onSave={(id: string) => onNewAccountCreated(id)}
        onClose={() => setCreateAccountModalOpen(false)}
      />
      <Box style={{ height: 900 }}>
        <SmartAccountList
          query={AccountListQueryDocument}
          variables={({ pagination, where }) => ({
            input: {
              pagination,
              where
            }
          })}
          results={({ accounts }) => accounts}
          style={{ height: '100%' }}
        />
      </Box>
    </Box>
  );
};

const useAccountCreateStyles = makeStyles((theme: Theme) =>
  createStyles({
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  })
);

interface ICreateNewAccountModalProps {
  shown: boolean;
  onClose: () => void;
  onSave: (account: string) => void;
}

type CreateNewAccountModalProps = ICreateNewAccountModalProps;

const CreateNewAccountModal: React.FC<CreateNewAccountModalProps> = (props) => {
  const [newAccountName, setNewAccountName] = React.useState("");
  const [saving, setSaving] = React.useState(false);
  const classes = useAccountCreateStyles();

  const [createAccountMutation] = useCreateAccountMutation()

  const { data: rolesData, loading: rolesLoading } = useCreateAccountModalRolesQuery()
  const roles = rolesData?.roles.results;

  const [selectedRoles, setSelectedRoles] = React.useState<string[]>([]);

  const createAccount = async () => {
    setSaving(true);
    const newAccount = await createAccountMutation({
      variables: {
        name: newAccountName,
        roles: selectedRoles
      },
    });

    if (newAccount.data?.createAccountAsAdmin.id) {
      props.onSave(newAccount.data.createAccountAsAdmin.id);
    }

    setSaving(false);
  };

  const close = () => {
    setNewAccountName("");
    props.onClose();
  };

  const createAccountDisabled = !newAccountName || saving;

  const dialogProps: DialogWithCloseButtonProps = {
    open: props.shown,
    primaryActionDisabled: createAccountDisabled,
    onClose: close,
    onPrimaryAction: createAccount,
    title: "Create new account",
  };

  return (
    <DialogWithCloseButton {...dialogProps}>
      <Box mb={2}>
        <TextField
          value={newAccountName}
          onChange={(e) => setNewAccountName(e.target.value)}
          label={"New account name"}
          required
        />
      </Box>
      <div>
        <InputLabel>Roles</InputLabel>
        { roles &&  <DualListBox
          canFilter
          style={{height: "100%"}}
          icons={{
            moveLeft: <ChevronLeft/>,
            moveRight: <ChevronRight/>,
            moveDown: <ArrowDownwardSharp/>,
            moveUp: <ArrowUpwardSharp/>,
          }}
          options={roles.map((role) => ({
            value: role.id,
            label: role.name,
          }))}
          selected={selectedRoles}
          onChange={(values: string[]) => {
            setSelectedRoles(values);
          }}
        /> }
      </div>
    </DialogWithCloseButton>
  );
};
