import * as React from 'react';
import { ApolloProvider, ApolloClient, InMemoryCache } from '@apollo/client';
import { useAuth } from "../auth";
import fragmentMatcher from './generated_fragments';
import {CircularProgress} from "@material-ui/core";

export const API_BASE_URL = process.env.REACT_APP_API_URL

const cache = new InMemoryCache({
  possibleTypes: fragmentMatcher.possibleTypes,
  typePolicies: {
    Mix: {
      fields: {
        content: {
          merge: false
        }
      }
    },
    RoleFeatureFlag: {
      keyFields: ["key"]
    }
  },
})

export const GraphQLProvider: React.FC = (props) => {
  const [initialized, setInitialized] = React.useState(false);
  const { accessToken, isAuthenticated, isLoading, loginWithRedirect, getAccessTokenSilently } = useAuth();

  React.useEffect(
    () => {
      if (!isLoading) {
        (async () => {
          // initialize (get access token silently if authenticated or (if required) login)
          if (!isAuthenticated) {
            await loginWithRedirect(window.location.pathname + window.location.search);
          } else if (isAuthenticated) {
            await getAccessTokenSilently();
          }

          setInitialized(true);
        })()
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoading]
  )

  if (!initialized || isLoading || (accessToken === null)) return (
    <div className={"flex h-75 justify-center items-center"}>
      <span className={"vocalid-primary-text"}>
        <CircularProgress color={"inherit"}/>
      </span>
    </div>
  )

  const client = new ApolloClient({
    uri: `${API_BASE_URL}/graphql`,
    cache,
    headers: {
      ...(accessToken ? {
        "X-Vocalid-Parrot-Access-Token": accessToken
      } : {})
    }
  });

  return <ApolloProvider client={client}>{props.children}</ApolloProvider>;
};
