import React, {ReactElement} from "react";
import ConnectedList, {ConnectedListRef} from "../../atoms/connected-list";
import {TypedDocumentNode} from "@graphql-typed-document-node/core";
import {UserListEntryFragment, UserMultiWhereInput} from "../../../graphql/generated";
import {copyTextToClipboard} from "../../../utils/clipboard";
import {FileCopyOutlined} from "@material-ui/icons";
import {Box, Button} from "@material-ui/core";
import {Link} from "react-router-dom";
import {useSnackbar} from "notistack";
import {AddTalentToUserModal} from "../add-talent-to-user-modal";

type UserRow = UserListEntryFragment;

interface UserListQueryParams<Query, Variables> {
  query: TypedDocumentNode<Query, Variables>;
  results(query: Query): { results: UserRow[]; totalResults: number; };
  variables(opts: { pagination: { page: number; perPage: number; }; where: UserMultiWhereInput; }): Variables;
}

interface SmartUserListProps<Query, Variables> extends UserListQueryParams<Query, Variables> {
  onSelect?(account: UserRow): void;
  onRemove?(account: UserRow): void;
  className?: string;
  style?: React.CSSProperties;
}

const SmartUserList = <Q,V>(props: SmartUserListProps<Q,V>, ref: React.Ref<ConnectedListRef>) => {
  const listRef = React.useRef<ConnectedListRef>(null)
  React.useImperativeHandle(ref, () => ({
    async refetch(): Promise<void> {
      await listRef.current?.refetch()
    }
  }))

  const { enqueueSnackbar } = useSnackbar();
  const [addingTalentToUserId, setAddingTalentToUserId] = React.useState<string | false>(false);

  const addTalentToUserModal = (
    <AddTalentToUserModal
      addingToUserId={addingTalentToUserId}
      onCancel={() => setAddingTalentToUserId(false)}
      onAdded={async () => {
        await listRef.current?.refetch();
      }}
    />
  );

  return (
    <>
      {addTalentToUserModal}
      <ConnectedList
        ref={listRef}
        {...props}
        query={props.query}
        searchKeys={['name', 'firstName', 'lastName']}
        variables={({ pagination, search }) => props.variables({
          pagination,
          where: {
            name: search && search.key === "name" ? { search: search.query } : null,
            firstName: search && search.key === "firstName" ? { search: search.query } : null,
            lastName: search && search.key === "lastName" ? { search: search.query } : null
          } })
        }
        results={query => {
          const wrappedResults = props.results(query)
          return ({
            results: wrappedResults.results.map(u => ({...u, link: `/users/${u.id}`})),
            totalResults: wrappedResults.totalResults
          });
        }}
        renderCell={{
          created: (value) => <div>{(new Date(value)).toLocaleDateString()}</div>,
          talent: (value, row) => <>{value ? <Box display={"flex"}>
              <span style={{cursor: 'copy'}} onClick={e => {
                copyTextToClipboard("" + value.talent.id)
                enqueueSnackbar(`Talent ID "${("" + value.talent.id).substring(0, 9) + "..."}" copied to clipboard`)
                e.preventDefault()
                e.stopPropagation()
              }}><FileCopyOutlined/></span>
            <Box marginLeft={1}><Link to={`/talent/${value.talent.id}`}>{value.talent.id}</Link></Box>
          </Box> : <Button
            variant={"contained"}
            size={"small"}
            onClick={() => {
              setAddingTalentToUserId(row.id);
            }}
          >
            Add Talent
          </Button>}</>
        }}
      />
    </>
  );
};

const SmartUserListWithRef = React.forwardRef(SmartUserList) as <Q,V>(p: SmartUserListProps<Q,V> & { ref?: React.Ref<ConnectedListRef> }) => ReactElement;
export default SmartUserListWithRef
