import { Styles } from "../../../types/styles";

export const mediaStyles: Styles = {
  control: {
    paddingBottom: "18px",
  },
  buttonGroup: {
    display: "flex",
    justifyContent: "flex-end",
  },
  selectZone: {
    marginTop: "8px",
    padding: "16px",
    border: ".5px solid lightgray",
    borderRadius: "4px",
  },
};
