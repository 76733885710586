import {
  Box,
  Button,
  ButtonGroup,
  CircularProgress,
  Grid,
  Typography,
} from "@material-ui/core";
import { Backup, Delete, Label, LabelOff } from "@material-ui/icons";
import React, { useEffect, useRef, useState } from "react";
import Select from "react-select";
import { MediaGrid } from "../../molecules/media-grid";
import { MediaItem, MediaTag } from "./image-card";
import { mediaStyles } from "./styles";
import { TagEditModal } from "./tag-edit-modal";
import {
  useContentTagListQuery,
  useCreateMediaItemMutation,
  useDeleteMediaItemMutation, useMediaItemsQuery,
  useToggleTagOnMediaMutation
} from "../../../graphql/generated";

interface IMediaUploadProps {}

export const MediaUpload: React.FC<IMediaUploadProps> = () => {
  const [isSelecting, setSelecting] = useState(false);
  const [isShiftPressed, setIsPressed] = useState(false);
  const [selectedImages, setSelectedImages] = useState<string[]>([]);
  const [displayedTags, setDisplayed] = useState<MediaTag[]>([]);
  const [media, setMedia] = useState<MediaItem[]>([]);
  const [allTags, setAllTags] = useState<MediaTag[]>([]);
  const [tagAction, setTagAction] = useState<"add" | "delete" | undefined>(
    undefined
  );
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [uploadInProgress, setUpload] = useState(false);

  const [createMediaItem, { loading: createItemLoading }] = useCreateMediaItemMutation()

  const [deleteMediaItem, { loading: deleteItemLoading }] = useDeleteMediaItemMutation()

  const [toggleMediaTags, { loading: toggleLoading }] = useToggleTagOnMediaMutation()

  const {
    data: mediaData,
    loading: mediaLoading,
    refetch: refetchMedia,
  } = useMediaItemsQuery()

  const {
    data: tagData,
    loading: tagsLoading,
    refetch: refetchTags,
  } = useContentTagListQuery()

  useEffect(() => {
    if (mediaData) {
      setMedia(
        mediaData.mediaItems.results.map((mi) => ({
          id: mi.id,
          url: mi.mediaUrl,
          tags: mi.contentTags.map((ct) => ({ id: ct.id, label: ct.tag })),
          mimeType: mi.mimeType,
        }))
      );
    }
  }, [mediaData]);

  useEffect(() => {
    if (tagData) {
      setAllTags(
        tagData.contentTags.results.map((ct) => ({ id: ct.id, label: ct.tag }))
      );
    }
  }, [tagData]);

  useEffect(() => {
    const keyDown = (e: KeyboardEvent) => {
      if (e.key === "Shift") {
        setIsPressed(true);
      }
    };

    const keyUp = (e: KeyboardEvent) => {
      if (e.key === "Shift") {
        setIsPressed(false);
      }
    };

    document.addEventListener("keydown", keyDown);
    document.addEventListener("keyup", keyUp);

    return () => {
      document.removeEventListener("keydown", keyDown);
      document.removeEventListener("keyup", keyUp);
    };
  }, []);

  const replace = <T,>(item: T, list: T[]) => {
    if (list.includes(item)) {
      return list.filter((i) => i !== item);
    } else {
      return [...list, item];
    }
  };

  const removeTag = async (tagId: string, mediaItemId: string) => {
    await toggleMediaTags({
      variables: { contentTagId: tagId, mediaItemId: mediaItemId },
    });
    await refetchMedia();
  };

  const onSelected = (id: string) => {
    if (isSelecting) {
      setSelectedImages((prev) => replace(id, prev));
    }
  };

  const onImageClicked = (id: string) => {
    if (isShiftPressed) {
      setSelectedImages((prev) => replace(id, prev));
    }
  };

  const deleteSelectedMedia = async () => {
    setSelectedImages([]);
    await Promise.all(
      media
        .filter((m) => selectedImages.some((id) => id === m.id))
        .map((mi) => deleteMediaItem({ variables: { mediaItemId: mi.id } }))
    );
    await refetchMedia();
  };

  const getFilteredImages = () => {
    if (displayedTags.length === 0) {
      return media;
    }

    return media.filter((img) =>
      displayedTags.every((dt) => img.tags.some((mt) => mt.id === dt.id))
    );
  };

  const commitTagChange = async (selectedTags: MediaTag[]) => {
    await Promise.all(
      media.map((mi) => {
        if (selectedImages.includes(mi.id)) {
          if (tagAction === "add") {
            return updateItemTags(
              selectedTags.filter((t) => !mi.tags.some((mt) => mt.id === t.id)),
              mi
            );
          }
          if (tagAction === "delete") {
            return updateItemTags(
              selectedTags.filter((t) => mi.tags.some((mt) => mt.id === t.id)),
              mi
            );
          }
        }

        return Promise.resolve();
      })
    );
    await refetchMedia();
    setTagAction(undefined);
  };

  const updateItemTags = async (
    selectedTags: MediaTag[],
    mediaItem: MediaItem
  ) => {
    await Promise.all(
      selectedTags.map((t) =>
        toggleMediaTags({
          variables: { mediaItemId: mediaItem.id, contentTagId: t.id },
        })
      )
    );
  };

  const handleFileUpload = async () => {
    const { current } = fileInputRef;
    setUpload(true);
    if (current && current.files) {
      const uploadedMedia = await Promise.all(
        Array.from(current.files).map((file) =>
          createMediaItem({ variables: { mimeType: file.type } })
        )
      );

      await Promise.all(
        uploadedMedia.map((uploaded, i) => {
          return fetch(uploaded.data?.createMediaItem.uploadUrl ?? "", {
            method: "PUT",
            body: current.files!![i],
          });
        })
      );

      await refetchMedia();
      setUpload(false);
    }
  };

  if (tagsLoading || mediaLoading || uploadInProgress || deleteItemLoading) {
    return <CircularProgress />;
  }

  return (
    <Box style={{ marginTop: 40 }}>
      <Grid
        style={mediaStyles.control}
        container
        alignItems="flex-end"
        justify="space-between"
      >
        <Grid item xs={3}>
          <Typography>Filter by tag</Typography>
          <Select
            onChange={(v) => {
              setSelectedImages([]);
              setDisplayed(
                v.map((val) => ({ id: val.value, label: val.label }))
              );
            }}
            options={allTags.map((t) => ({ value: t.id, label: t.label }))}
            isMulti
          />
        </Grid>

        <Grid style={mediaStyles.buttonGroup} item xs={6}>
          <input
            ref={fileInputRef}
            style={{ position: "fixed", right: -9000 }}
            type="file"
            id="input"
            accept=".png,.jpg"
            onChange={handleFileUpload}
            multiple
          />

          <ButtonGroup color="primary">
            <Button
              onClick={() => fileInputRef.current?.click()}
              startIcon={<Backup />}
            >
              Upload Images
            </Button>
            <Button
              onClick={deleteSelectedMedia}
              startIcon={<Delete />}
              disabled={selectedImages.length === 0}
            >
              Delete Images
            </Button>
            <Button
              onClick={() => setTagAction("add")}
              startIcon={<Label />}
              disabled={selectedImages.length === 0}
            >
              Add Tags
            </Button>
            <Button
              onClick={() => setTagAction("delete")}
              startIcon={<LabelOff />}
              disabled={selectedImages.length === 0}
            >
              Delete Tags
            </Button>
          </ButtonGroup>
        </Grid>
      </Grid>
      <Typography variant="caption">
        ✏ Hold Shift and click to select an image. <br />
        Click and drag within the gray border to select multiple images.
      </Typography>
      <div
        style={mediaStyles.selectZone}
        onMouseDown={() => setSelecting(true)}
        onMouseUp={() => setSelecting(false)}
      >
        <TagEditModal
          show={tagAction !== undefined}
          title={tagAction === "add" ? "Add Tags" : "Remove Tags"}
          helpText={`Tags you select here will be added/deleted from all selected images.`}
          allTags={allTags}
          onConfirm={commitTagChange}
          onCancel={() => setTagAction(undefined)}
        />
        <MediaGrid
          items={getFilteredImages().map((i) => ({
            ...i,
            isSelected: selectedImages.includes(i.id),
          }))}
          onTagClicked={removeTag}
          onItemSelected={(media) => onSelected(media.id)}
          onImageClicked={(media) => onImageClicked(media.id)}
        />
      </div>
    </Box>
  );
};
