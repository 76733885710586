import * as React from "react";
import {
  Checkbox,
  CircularProgress, FormControlLabel, MenuItem, OutlinedTextFieldProps, Select, Table, TableCell, TableRow, TextField
} from "@material-ui/core";
import {
  DialogWithCloseButton,

} from "../../atoms/dialog-with-close-button";
import {ArrowDownwardSharp, ArrowUpwardSharp, ChevronLeft, ChevronRight} from "@material-ui/icons";
import DualListBox from "react-dual-listbox";
import {useDeleteApiTokenMutation} from "../../../graphql/generated";

interface DeleteApiTokenModalProps {
  tokenId: string | null;
  onCancel(): any;
  onDelete?(): Promise<any>;
}

export const DeleteApiTokenModal: React.FC<DeleteApiTokenModalProps> = (props) => {
  const [deleteApiTokenMutation] = useDeleteApiTokenMutation()

  return (
    <DialogWithCloseButton
      open={Boolean(props.tokenId)}
      title={"Delete API Token?"}
      onPrimaryAction={async () => {
        await deleteApiTokenMutation({ variables: {
          input: {
            token: props.tokenId!
          }
        }})
        props.onDelete?.()
      }}
      primaryActionDisabled={false}
      onClose={props.onCancel}
      primaryActionText={"Delete"}
    >
    </DialogWithCloseButton>
  );
}
