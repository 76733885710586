import * as React from "react";
import {gql, useMutation, useQuery} from "@apollo/client";
import {
  AppBar,
  Box,
  Button,
  Card,
  Checkbox,
  CircularProgress,
  Fab,
  FormControlLabel,
  MenuItem,
  Paper,
  Select,
  Switch,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Typography,
} from "@material-ui/core";
import DualListBox from "react-dual-listbox";
import {useParams} from "react-router-dom";
import moment from "moment";
import {TabContext, TabPanel} from "@material-ui/lab";
import {AddIcon} from "@material-ui/data-grid";
import EditIcon from "@material-ui/icons/Edit";
import {ArrowDownwardSharp, ArrowUpwardSharp, ChevronLeft, ChevronRight,} from "@material-ui/icons";
import {DialogWithCloseButton, DialogWithCloseButtonProps,} from "../../atoms/dialog-with-close-button";
import {RoleFeatureFlagKey, useRoleDetailQuery, useUpdateRoleMutation} from "../../../graphql/generated";

type RoleDetailProps = IRoleDetailProps;

interface IRoleDetailProps {}

enum RoleDetailTab {
  FeatureFlags = "Feature Flags",
  Voices = "Voices",
  Accounts = "Accounts"
}

export const RoleDetail: React.FC<RoleDetailProps> = (props) => {
  const { id } = useParams<{ id: string }>();

  const [tab, setTab] = React.useState(RoleDetailTab.FeatureFlags);

  const { data, loading, refetch } = useRoleDetailQuery({
    variables: { id },
  });

  const [roleName, setRoleName] = React.useState("");
  const [synthesisMaxRequests, setSynthesisMaxRequests] = React.useState("");
  const [synthesisRateLimit, setSynthesisRateLimit] = React.useState("");
  const [synthesisMaxCharacters, setSynthesisMaxCharacters] = React.useState("");
  const [synthesisMaxCharactersPerRequest, setSynthesisMaxCharactersPerRequest] = React.useState("");
  const [maxProjects, setMaxProjects] = React.useState("");
  const [maxPlaylists, setMaxPlaylists] = React.useState("");
  const [synthesisApiAccess, setSynthesisApiAccess] = React.useState(false);
  React.useEffect(() => {
    if (data && !loading) {
      setRoleName(data.role.name);
      setSynthesisMaxRequests(data.role.synthesisMaxRequests?.toString() ?? "");
      setSynthesisRateLimit(data.role.synthesisRateLimit?.toString() ?? "");
      setSynthesisMaxCharacters(data.role.synthesisMaxCharacters?.toString() ?? "");
      setSynthesisMaxCharactersPerRequest(data.role.synthesisMaxCharactersPerRequest?.toString() ?? "");
      setMaxProjects(data.role.maxProjects?.toString() ?? "");
      setMaxPlaylists(data.role.maxPlaylists?.toString() ?? "");
      setSynthesisApiAccess(data.role.synthesisApiAccess ?? false);
    }
  }, [data, loading]);

  const [editVoicesOpen, setEditVoicesOpen] = React.useState(false);
  const [editAccountsOpen, setEditAccountsOpen] = React.useState(false);
  const [addingFeatureFlag, setAddingFeatureFlag] = React.useState<{
    key: RoleFeatureFlagKey;
    locked: boolean;
    hidden: boolean;
    lockedReason: string | null;
  } | null>(null);
  const [keepRemovedVoicesOnAccounts, setKeepRemovedVoicesOnAccounts] = React.useState(false);
  const [selectedVoices, setSelectedVoices] = React.useState<string[]>([]);
  const [selectedFeatureFlags, setSelectedFeatureFlags] = React.useState<Array<{
    key: RoleFeatureFlagKey;
    locked: boolean;
    hidden: boolean;
    lockedReason: string | null;
  }>>([]);
  const [selectedAccounts, setSelectedAccounts] = React.useState<string[]>([]);

  function setSelectedFeatureFlag(key: RoleFeatureFlagKey, flag: Omit<(typeof selectedFeatureFlags)[number], "key"> | null) {
    setSelectedFeatureFlags(o => {
      const newArr = [...o];
      const existingIndex = newArr.findIndex(f => f.key === key);

      if (!flag) {
        if (existingIndex !== -1) {
          // delete
          newArr.splice(existingIndex, 1);
        }
      } else {
        if (existingIndex !== -1) {
          // replace
          newArr.splice(existingIndex, 1, {
            key,
            ...flag
          });
        } else {
          // insert
          newArr.push({
            key,
            ...flag
          });
        }
      }

      return newArr;
    })
  }

  React.useEffect(() => {
    if (data && data.role) {
      setSelectedFeatureFlags(data.role.featureFlags.map(f => ({
        // to remove _typename
        key: f.key,
        locked: f.locked,
        hidden: f.hidden,
        lockedReason: f.lockedReason
      })));
      setSelectedVoices(
        data.role.voices.map((v) => v.id)
      );
      setSelectedAccounts(
        data.role.accounts.map((a) => a.id)
      );
    }
  }, [data]);

  const [updateRoleDetailMutation] = useUpdateRoleMutation();

  const [saving, setSaving] = React.useState(false);

  const save = async () => {
    setSaving(true);
    await updateRoleDetailMutation({
      variables: {
        input: {
          id: data?.role.id ?? "",
          featureFlags: selectedFeatureFlags,
          voices: selectedVoices,
          keepRemovedVoicesOnAccounts,
          accounts: selectedAccounts,
          name: roleName,
          synthesisMaxRequests: { value: parseInt(synthesisMaxRequests) },
          synthesisRateLimit: { value: parseInt(synthesisRateLimit) },
          synthesisMaxCharacters: { value: parseInt(synthesisMaxCharacters) },
          synthesisMaxCharactersPerRequest: { value: parseInt(synthesisMaxCharactersPerRequest) },
          maxProjects: { value: parseInt(maxProjects) },
          maxPlaylists: { value: parseInt(maxPlaylists) },
          synthesisApiAccess: { value: synthesisApiAccess }
        }
      },
    });
    await refetch();
    setSaving(false);
    setEditVoicesOpen(false);
    setEditAccountsOpen(false);
    setEdited(false);
  };

  const [edited, setEdited] = React.useState(false);

  if (!data || loading) return <CircularProgress />;

  const infoBox = (
    <Card style={{ marginBottom: 20 }}>
      <Table>
        <TableRow>
          <TableCell>
            <strong>Role ID</strong>
          </TableCell>
          <TableCell>{data.role.id}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <strong>Created</strong>
          </TableCell>
          <TableCell>{moment(data.role.created).format()}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <strong>Name</strong>
          </TableCell>
          <TableCell>
            <TextField
              variant="outlined"
              value={roleName}
              size="small"
              onChange={(e) => {
                setRoleName(e.target.value);
                setEdited(true);
              }}
            />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <strong>Synthesis Max Requests</strong>
          </TableCell>
          <TableCell>
            <TextField
              type={"number"}
              variant="outlined"
              value={synthesisMaxRequests}
              onChange={e => {
                setSynthesisMaxRequests(e.target.value);
                setEdited(true);
              }}
              size="small"
            />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <strong>Synthesis Rate Limit</strong>
          </TableCell>
          <TableCell>
            <TextField
              type={"number"}
              variant="outlined"
              value={synthesisRateLimit}
              onChange={e => {
                setSynthesisRateLimit(e.target.value);
                setEdited(true);
              }}
              size="small"
            />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <strong>Synthesis Max Characters</strong>
          </TableCell>
          <TableCell>
            <TextField
              type={"number"}
              variant="outlined"
              value={synthesisMaxCharacters}
              onChange={e => {
                setSynthesisMaxCharacters(e.target.value);
                setEdited(true);
              }}
              size="small"
            />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <strong>Synthesis Max Characters Per Request</strong>
          </TableCell>
          <TableCell>
            <TextField
              type={"number"}
              variant="outlined"
              value={synthesisMaxCharactersPerRequest}
              onChange={e => {
                setSynthesisMaxCharactersPerRequest(e.target.value);
                setEdited(true);
              }}
              size="small"
            />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <strong>Max Projects</strong>
          </TableCell>
          <TableCell>
            <TextField
              type={"number"}
              variant="outlined"
              value={maxProjects}
              onChange={e => {
                setMaxProjects(e.target.value);
                setEdited(true);
              }}
              size="small"
            />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <strong>Max Mixes</strong>
          </TableCell>
          <TableCell>
            <TextField
              type={"number"}
              variant="outlined"
              value={maxPlaylists}
              onChange={e => {
                setMaxPlaylists(e.target.value);
                setEdited(true);
              }}
              size="small"
            />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <strong>Synthesis Api Access</strong>
          </TableCell>
          <TableCell>
            <Switch checked={synthesisApiAccess} onChange={(_, checked) => {
              setSynthesisApiAccess(checked);
              setEdited(true);
            }} />
          </TableCell>
        </TableRow>
      </Table>
    </Card>
  );


  const featureFlags = (
    <TableContainer style={{margin: 0, padding: 0}} component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            {/*<TableCell><strong>ID</strong></TableCell>*/}
            <TableCell><strong>Key</strong></TableCell>
            <TableCell><strong>Locked</strong></TableCell>
            <TableCell><strong>Hidden</strong></TableCell>
            <TableCell><strong>Locked Reason</strong></TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {selectedFeatureFlags.map(flag => (
            <TableRow key={flag.key}>
              <TableCell>{flag.key}</TableCell>
              <TableCell><Checkbox checked={flag.locked} onChange={(_, checked) => {
                setSelectedFeatureFlag(flag.key, {...flag, locked: checked});
                setEdited(true);
              }} /></TableCell>
              <TableCell><Checkbox checked={flag.hidden} onChange={(_, checked) => {
                setSelectedFeatureFlag(flag.key, {...flag, hidden: checked});
                setEdited(true);
              }} /></TableCell>
              <TableCell><TextField value={flag.lockedReason ?? ""} onChange={({ target: { value } }) => {
                setSelectedFeatureFlag(flag.key, {...flag, lockedReason: value === "" ? null : value});
                setEdited(true);
              }} />
              </TableCell>
              <TableCell><Button onClick={() => {
                setSelectedFeatureFlag(flag.key, null);
                setEdited(true);
              }}>Delete</Button></TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )

  const voices = (
    <TableContainer style={{ margin: 0, padding: 0 }} component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <strong>ID</strong>
            </TableCell>
            <TableCell>
              <strong>Voice</strong>
            </TableCell>
            <TableCell>
              <strong>Talent</strong>
            </TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.role.voices
            .map((voice) => (
              <TableRow key={voice.id}>
                <TableCell>{voice.id}</TableCell>
                <TableCell>{voice.title}</TableCell>
                <TableCell>
                  {voice.talent?.firstName} {voice.talent?.lastName}
                </TableCell>
                <TableCell></TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

  const accounts = (
    <TableContainer style={{ margin: 0, padding: 0 }} component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <strong>ID</strong>
            </TableCell>
            <TableCell>
              <strong>Name</strong>
            </TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.role.accounts
            .map((account) => (
              <TableRow key={account.id}>
                <TableCell>{account.id}</TableCell>
                <TableCell>{account.name}</TableCell>
                <TableCell></TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

  const editVoicesDialogProps: DialogWithCloseButtonProps = {
    title: "Edit vocals",
    open: editVoicesOpen,
    async onPrimaryAction() {
      await save();
      setKeepRemovedVoicesOnAccounts(false);
    },
    primaryActionDisabled: false,
    onClose: () => {
      setEditVoicesOpen(false);
      setKeepRemovedVoicesOnAccounts(false);
    },
  };

  const editAccountsDialogProps: DialogWithCloseButtonProps = {
    title: "Edit accounts",
    open: editAccountsOpen,
    onPrimaryAction: () => save(),
    primaryActionDisabled: false,
    onClose: () => setEditAccountsOpen(false),
  };

  const addFeatureFlagDialogProps: DialogWithCloseButtonProps = {
    title: "Add feature flag",
    open: addingFeatureFlag !== null,
    onPrimaryAction: async () => {
      setSelectedFeatureFlag(addingFeatureFlag!.key, addingFeatureFlag);
      setAddingFeatureFlag(null);
      setEdited(true);
    },
    primaryActionText: 'Add',
    onClose: () => setAddingFeatureFlag(null),
    primaryActionDisabled: false
  };

  const addFeatureFlagModal = (
    <DialogWithCloseButton {...addFeatureFlagDialogProps}>
      {addingFeatureFlag && <Table>
          <TableRow><TableCell>Key</TableCell><TableCell>
              <Select
                  value={addingFeatureFlag.key}
                  onChange={({ target: { value } }) => {
                    setAddingFeatureFlag(o => ({
                      ...o!,
                      key: value as RoleFeatureFlagKey
                    }));
                  }}
                  required
              >
                { Object.keys(RoleFeatureFlagKey).map(enumValue => (
                  <MenuItem key={enumValue} value={enumValue}>{enumValue}</MenuItem>
                ))}
              </Select>
          </TableCell></TableRow>
          <TableRow><TableCell>Locked</TableCell><TableCell><Checkbox checked={addingFeatureFlag.locked} onChange={(_, checked) => setAddingFeatureFlag({ ...addingFeatureFlag, locked: checked })} /></TableCell></TableRow>
          <TableRow><TableCell>Hidden</TableCell><TableCell><Checkbox checked={addingFeatureFlag.hidden} onChange={(_, checked) => setAddingFeatureFlag({ ...addingFeatureFlag, hidden: checked })} /></TableCell></TableRow>
          <TableRow><TableCell>Locked Reason</TableCell><TableCell><TextField value={addingFeatureFlag.lockedReason ?? ""} onChange={({ target: { value } }) =>
            setAddingFeatureFlag({ ...addingFeatureFlag, lockedReason: value === "" ? null : value })} />
          </TableCell></TableRow>
      </Table>}
    </DialogWithCloseButton>
  );

  const editVoicesModal = (
    <DialogWithCloseButton {...editVoicesDialogProps}>
      <DualListBox
        canFilter
        style={{ height: "100%", marginBottom: 20 }}
        icons={{
          moveLeft: <ChevronLeft />,
          moveRight: <ChevronRight />,
          moveDown: <ArrowDownwardSharp />,
          moveUp: <ArrowUpwardSharp />,
        }}
        options={data.voices.results.map((voice) => ({
          value: voice.id,
          label: voice.title
        }))}
        selected={selectedVoices}
        onChange={(values: string[]) => {
          setSelectedVoices(values);
        }}
      />
      { data.role.voices.some(v => !selectedVoices.includes(v.id)) && <FormControlLabel control={<Checkbox checked={keepRemovedVoicesOnAccounts} onChange={({ target: { checked } }) => setKeepRemovedVoicesOnAccounts(checked)} />} label="Keep Removed Voices on Accounts with role? (removal only affects new role applications)" /> }
    </DialogWithCloseButton>
  );

  const editAccountsModal = (
    <DialogWithCloseButton {...editAccountsDialogProps}>
      <DualListBox
        canFilter
        style={{ height: "100%" }}
        icons={{
          moveLeft: <ChevronLeft />,
          moveRight: <ChevronRight />,
          moveDown: <ArrowDownwardSharp />,
          moveUp: <ArrowUpwardSharp />,
        }}
        options={data.accounts.results.map((account) => ({
          value: account.id,
          label: account.name,
        }))}
        selected={selectedAccounts}
        onChange={(values: string[]) => {
          setSelectedAccounts(values);
        }}
      />
    </DialogWithCloseButton>
  );

  return (
    <>
      <Typography variant="h4">{data.role.name}</Typography>
      <Box style={{ width: 500, marginTop: 20 }}>{infoBox}</Box>
      <Button variant={"outlined"} color={"primary"} size={"large"} disabled={!edited} style={{ marginBottom: 20 }} onClick={() => save()}>Save</Button>
      <TabContext value={tab}>
        <AppBar position="static">
          <Tabs value={tab} onChange={(e, v) => setTab(v as RoleDetailTab)}>
            <Tab value={RoleDetailTab.FeatureFlags} label="Feature Flags" />
            <Tab value={RoleDetailTab.Voices} label="Voices" />
            <Tab value={RoleDetailTab.Accounts} label="Accounts" />
          </Tabs>
        </AppBar>
        <TabPanel
          style={{ margin: 0, padding: 0, marginTop: 20 }}
          value={RoleDetailTab.FeatureFlags}
        >
          <Typography>The list of features that are affected by this role.</Typography>
          {featureFlags}
          <Fab color="primary" onClick={() => setAddingFeatureFlag({
            key: RoleFeatureFlagKey.AllAppFeatures,
            locked: true,
            hidden: false,
            lockedReason: null
          })}>
            <AddIcon />
          </Fab>
        </TabPanel>
        <TabPanel
          style={{ margin: 0, padding: 0, marginTop: 20 }}
          value={RoleDetailTab.Voices}
        >
          <Typography>The default list of Vocals that the users of accounts created with this role can select when synthesizing</Typography>
          <Fab color="primary" onClick={() => setEditVoicesOpen(true)}>
            <EditIcon />
          </Fab>
          {voices}
        </TabPanel>
        <TabPanel
          style={{ margin: 0, padding: 0, marginTop: 20 }}
          value={RoleDetailTab.Accounts}
        >
          <Typography>The accounts that have this role assigned.</Typography>
          <Fab color="primary" onClick={() => setEditAccountsOpen(true)}>
            <EditIcon />
          </Fab>
          {accounts}
        </TabPanel>
      </TabContext>
      {editVoicesModal}
      {editAccountsModal}
      {addFeatureFlagModal}
    </>
  );
};
