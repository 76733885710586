import * as React from "react";
import {
  Checkbox,
  CircularProgress, FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  OutlinedTextFieldProps,
  Select,
  Table,
  TableCell,
  TableRow,
  TextField
} from "@material-ui/core";
import {
  DialogWithCloseButton,

} from "../../atoms/dialog-with-close-button";
import {ArrowDownwardSharp, ArrowUpwardSharp, ChevronLeft, ChevronRight} from "@material-ui/icons";
import DualListBox from "react-dual-listbox";
import {
  useDeleteApiTokenMutation,
  useDeleteProjectTypeModalQuery,
  useDeleteProjectTypeMutation
} from "../../../graphql/generated";

interface DeleteProjectTypeModalProps {
  projectTypeId: string | null;
  onCancel(): any;
  onDelete?(): Promise<any>;
}

export const DeleteProjectTypeModal: React.FC<DeleteProjectTypeModalProps> = (props) => {
  const { data, loading } = useDeleteProjectTypeModalQuery()
  const [deleteProjectType] = useDeleteProjectTypeMutation()

  const [replaceWith, setReplaceWith] = React.useState<string | null>(null);

  const isDefault = props.projectTypeId ? data?.projectTypes.find(p => p.id === props.projectTypeId)?.isDefault ?? false : false

  return (
    <DialogWithCloseButton
      open={Boolean(props.projectTypeId)}
      title={"Delete Project Type?"}
      onPrimaryAction={async () => {
        await deleteProjectType({ variables: {
          input: {
            id: props.projectTypeId!,
            replaceAllInstancesWith: replaceWith!
          }
        }})
        props.onDelete?.()
      }}
      primaryActionDisabled={!props.projectTypeId || !replaceWith || isDefault}
      onClose={props.onCancel}
      primaryActionText={"Delete"}
    >
      {!data || loading ? <CircularProgress /> : <>
        <div>
          {isDefault ? <>
              <div><strong>Deletion is not supported for default project type.</strong></div>
            </> :
            <FormControl fullWidth>
              <InputLabel id="project-type-replace-with-select-label">Replace With</InputLabel>
              <Select
                labelId="project-type-replace-with-select-label"
                id="project-type-replace-with-select"
                value={replaceWith}
                label="Replace With"
                onChange={(e) => setReplaceWith(e.target.value as string)}
              >
                {data.projectTypes.filter(p => p.id !== props.projectTypeId).map(projectType => (
                  <MenuItem key={projectType.id} value={projectType.id}>{projectType.title}{projectType.isDefault && " (Default)"}</MenuItem>
                ))}
              </Select>
            </FormControl>
          }
        </div>
      </>}
    </DialogWithCloseButton>
  );
}
