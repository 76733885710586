import * as React from "react";
import { gql, useMutation, useQuery } from "@apollo/client";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Input,
  MenuItem,
  OutlinedTextFieldProps,
  Table,
  TableCell,
  TableRow,
  TextField,
  Typography,
  Select,
  Box,
  FormLabel,
} from "@material-ui/core";
import DualListBox from "react-dual-listbox";
import {
  ArrowDownwardSharp,
  ArrowUpwardSharp,
  ChevronLeft,
  ChevronRight,
  Label,
} from "@material-ui/icons";
import {
  DialogWithCloseButton,
  DialogWithCloseButtonProps,
} from "../../atoms/dialog-with-close-button";
import {useCreateProjectTypeMutation, useCreateUserModalQuery, useCreateUserMutation} from "../../../graphql/generated";

interface ProjectTypeCreateModalProps {
  open: boolean;
  onCancel: () => any;
  onCreate: () => Promise<any>;
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export const ProjectTypeCreateModal: React.FC<ProjectTypeCreateModalProps> = (props) => {
  const [creating, setCreating] = React.useState(false);

  const [title, setTitle] = React.useState("");
  const [description, setDescription] = React.useState("");

  const clearForm = () => {
    setTitle("");
    setDescription("");
  };

  const [createProjectTypeMutation] = useCreateProjectTypeMutation();

  const create = async () => {
    setCreating(true);
    const projectType = await createProjectTypeMutation({
      variables: {
        input: {
          title,
          description
        }
      },
    });

    setCreating(false);
    setTitle("");
    setDescription("");
    await props.onCreate();
  };

  const close = () => {
    clearForm();
    props.onCancel();
  };

  const field = (
    value: string,
    setValue: (v: string) => any,
    title: string
  ): OutlinedTextFieldProps => ({
    value,
    onChange: (e: any) => setValue(e.target.value),
    label: title,
    variant: "outlined",
    fullWidth: true,
  });

  const dialogProps: DialogWithCloseButtonProps = {
    title: "Create project type",
    onPrimaryAction: create,
    primaryActionText: "Create",
    onClose: close,
    primaryActionDisabled: !title || !description,
    open: props.open,
  };

  return (
    <DialogWithCloseButton wide {...dialogProps}>
      <Table size="small">
        <TableRow>
          <TableCell>Title</TableCell>
          <TableCell>
            <TextField {...field(title, setTitle, "Title")} />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Description</TableCell>
          <TableCell>
            <TextField {...field(description, setDescription, "Description")} multiline rows={4} />
          </TableCell>
        </TableRow>
      </Table>
    </DialogWithCloseButton>
  );
};
