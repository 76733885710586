import * as React from "react";
import { CircularProgress, TableRow, Table, TableCell, TextField, Button } from "@material-ui/core";
import {useAddContentTagMutation, useContentTagListQuery} from "../../../graphql/generated";

type TagListProps = ITagListProps;

interface ITagListProps {

}

export const TagList: React.FC<TagListProps> = (props) => {
  const { data, loading, refetch } = useContentTagListQuery()
  const [contentTagToAdd, setContentTagToAdd] = React.useState("")
  const [mutate] = useAddContentTagMutation()

  if (!data || loading) return <CircularProgress/>

  const addContentTag = async () => {
    if (contentTagToAdd) {
      setContentTagToAdd("")
      await mutate({
        variables: {
          tag: contentTagToAdd
        }
      })
      await refetch()
    }

  }

  return (
    <Table>
      <TableRow>
        <TableCell>Tag ID</TableCell>
        <TableCell>Tag</TableCell>
        <TableCell>Content Count</TableCell>
      </TableRow>
      <TableRow>
        <TableCell></TableCell>
        <TableCell>
          <TextField value={contentTagToAdd} onChange={(e) => setContentTagToAdd(e.target.value)}/>
          <Button onClick={addContentTag}>Add Tag</Button>
        </TableCell>
      </TableRow>
      { data.contentTags.results.map(contentTag => (
        <TableRow>
          <TableCell>{ contentTag.id }</TableCell>
          <TableCell>{ contentTag.tag }</TableCell>
          <TableCell>{ contentTag.count }</TableCell>
        </TableRow>
      )) }
    </Table>
  )
}
