import React from 'react';
import logo from './logo.svg';
import './App.css';
import { Main } from "./Main"
import {GraphQLProvider} from "./graphql";
import {MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import {DragDropContext} from "react-beautiful-dnd";
import {BrowserRouter as Router, Redirect, Route, Switch} from "react-router-dom";
import {AuthCallback, AuthProvider} from "./auth";
import {ContentList} from "./components/pages/content-list";
import {ContentDetail} from "./components/pages/content-detail";
import {AccountDetail} from "./components/pages/account-detail";
import {AccountListPage} from "./components/pages/account-list";
import {TalentList} from "./components/pages/talent-list";
import {TalentDetail} from "./components/pages/talent-detail";
import {RecordingsList} from "./components/pages/recordings-list";
import {UsersList} from "./components/pages/users-list";
import {UserDetail} from "./components/pages/user-detail";
import {TagList} from "./components/pages/tag-list";
import {MediaUpload} from "./components/pages/media-upload";
import {VoicesList} from "./components/pages/voices-list";
import {VoiceDetail} from "./components/pages/voice-detail";
import {RoleDetail} from "./components/pages/role-detail";
import {RoleList} from "./components/pages/role-list";
import {PricingPlanDetail} from "./components/pages/pricing-plan-detail";
import {PricingPlanList} from "./components/pages/pricing-plan-list";
import { SnackbarProvider } from 'notistack';
import {ProjectTypesList} from "./components/pages/project-types-list";
import {ProjectTypeDetail} from "./components/pages/project-type-detail";

const Providers: React.FC = props => {
  return (
    <Router>
      <AuthProvider>
        <GraphQLProvider>
            <SnackbarProvider maxSnack={3}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DragDropContext onDragEnd={() => {}}>
                  { props.children }
                </DragDropContext>
              </MuiPickersUtilsProvider>
            </SnackbarProvider>
        </GraphQLProvider>
      </AuthProvider>
    </Router>
  )
}

export const Routes: React.FC = () => {
  return (
    <Switch>
      <Route exact path="/content/" component={ContentList} />
      <Route exact path="/content/:id" component={ContentDetail} />
      <Route exact path="/accounts/:id" component={AccountDetail} />
      <Route exact path="/accounts" component={AccountListPage} />
      <Route exact path="/talent/" component={TalentList} />
      <Route exact path="/talent/:id/:assignmentId?" component={TalentDetail} />
      <Route exact path="/recordings" component={RecordingsList} />
      <Route exact path="/users" component={UsersList} />
      <Route exact path="/users/:id" component={UserDetail} />
      <Route exact path="/tags" component={TagList} />
      <Route exact path="/media" component={MediaUpload} />
      <Route exact path="/voices" component={VoicesList}/>
      <Route exact path="/voices/:id" component={VoiceDetail}/>
      <Route exact path="/roles/:id" component={RoleDetail} />
      <Route exact path="/roles" component={RoleList} />
      <Route exact path="/project-types/:id" component={ProjectTypeDetail} />
      <Route exact path="/project-types" component={ProjectTypesList} />
      <Route exact path="/pricing-plans/:id" component={PricingPlanDetail} />
      <Route exact path="/pricing-plans" component={PricingPlanList} />
      <Route exact path={"/cb"} component={AuthCallback} />
      <Redirect to="/accounts" />
    </Switch>
  );
};

function App() {
  return (
    <Providers>
      <Main>
        <Routes />
      </Main>
    </Providers>
  );
}

export default App;
